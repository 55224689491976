import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { classNames } from '../../utils'
import { SectionParagraphGrid } from '../../components/SectionParagraphGrid'
import { CareersPageContext } from './CareersPage'
import { CareersPage_entry_careers_careers_Entry_jobBenefitSectionFinancialPersonalWellness as BenefitsContentBlock } from '../../api-types/CareersPage'
import { Media } from '../../components/Media'
import treadSrc from '../../assets/patterns/Tread-Blue.svg'
import angleSrc from '../../assets/patterns/Angle-Orange.svg'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.treadBackground(treadSrc, {
      backgroundColor: theme.kitColors.grey100,
      transform: 'scale(-1, -1)',
      right: -80,
      bottom: -345,
    }),
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.angleBackground(angleSrc, {
        transform: 'scale(-1, -1)',
        right: 0,
        bottom: 0,
      }),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    width: 850,
    textAlign: 'center',
    marginBottom: theme.spacing.XXXXL,
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginBottom: theme.spacing.XXXL,
    },
  },
  h3: {
    ...theme.typography.h3,
    marginBottom: theme.spacing.M,
  },
  h4: {
    ...theme.typography.h4,
    marginBottom: theme.spacing.S,
  },
  itemHeading: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.M,
  },
  text: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
  },
  overline: {
    ...theme.typography.overline,
    color: theme.colors.blue,
    marginBottom: theme.spacing.M,
  },
  smallBodyEm: {
    ...theme.typography.smallBodyEm,
    color: theme.kitColors.grey800,
    marginBottom: theme.spacing.S + theme.spacing.XS,
  },
  splitLayoutContent: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: theme.spacing.XXXL + theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginTop: theme.spacing.XXXXL,
    },
  },
}))

export const CareersBenefitsSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CareersPageContext)
  const media = context?.entry?.jobBenefitSectionRideYourBikeMedia?.find(
    (item) => item != null,
  )
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const benefitsBlockContent = (entry: BenefitsContentBlock | null) => {
    switch (entry?.__typename) {
      case 'jobBenefitSectionFinancialPersonalWellness_benefitsFirstColumn_BlockType':
        return (
          <article key={entry.uid}>
            <h3 className={classes.itemHeading}>{entry.heading}</h3>
            <p className={classes.text}>{entry.subheading}</p>
          </article>
        )
      case 'jobBenefitSectionFinancialPersonalWellness_benefitsSecondColumn_BlockType': {
        const benefitList = entry.benefits?.split('\n') ?? []
        return (
          <article key={entry.uid}>
            {benefitList.map((benefit, i) => (
              <p className={classes.smallBodyEm} key={i}>
                {benefit}
              </p>
            ))}
          </article>
        )
      }
      case 'jobBenefitSectionFinancialPersonalWellness_benefitsThirdColumn_BlockType': {
        const benefitList = entry.benefits?.split('\n') ?? []
        return (
          <article key={entry.uid}>
            {benefitList.map((benefit, i) => (
              <p className={classes.smallBodyEm} key={i}>
                {benefit}
              </p>
            ))}
          </article>
        )
      }
      default:
        return null
    }
  }

  return (
    <LayoutContainer
      containerClass={[
        layoutClasses.paddedContainer,
        isNotMobile ? classes.container : '',
      ].join(' ')}
      contentClass={classes.content}
    >
      <div className={classes.headerContainer}>
        <p className={classes.overline}>
          {context?.entry?.jobBenefitSectionLabel}
        </p>
        <h2 className={classes.h3}>
          {context?.entry?.jobBenefitSectionHeading}
        </h2>
        <p className={classes.text}>
          {context?.entry?.jobBenefitSectionSubheading}
        </p>
      </div>
      <div
        className={classNames(
          layoutClasses.paddingBetweenItemsSpacingXXXL,
          layoutClasses.threeItemLayout,
        )}
      >
        {context?.entry?.jobBenefitSectionFinancialPersonalWellness?.map(
          benefitsBlockContent,
        )}
      </div>
      <LayoutContainer
        contentClass={classNames(
          classes.splitLayoutContent,
          layoutClasses.splitLayoutLeftContent,
        )}
      >
        <SectionParagraphGrid
          heading={context?.entry?.jobBenefitSectionRideYourBikeHeading}
          content={context?.entry?.jobBenefitSectionRideYourBike}
        />
        <Media
          src={media?.url}
          type={media?.mimeType}
          alt={media?.altText}
          credit={media?.credit}
        />
      </LayoutContainer>
    </LayoutContainer>
  )
}
