import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { CompanyPageContext } from './CompanyPage'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { classNames } from '../../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        backgroundColor: theme.colors.navy,
        top: -375,
        left: -105,
        opacity: 0.3,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.colors.navy,
    },
  },
  sectionHeading: {
    ...theme.typography.h4,
    color: theme.kitColors.white,
    gridColumn: 'span 4',
    gridRow: '1 / -1',
    marginRight: theme.spacing.XXL,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.XXL,
      marginRight: 0,
    },
  },
  topicsContainer: {
    gridColumn: 'span 8',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: theme.spacing.XXXL,
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
      columnGap: theme.spacing.XXL,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  topic: {
    borderTop: `2px solid ${theme.colors.orange}`,
    paddingTop: theme.spacing.M,
    display: 'flex',
    flexDirection: 'column',
    '& > h3': {
      ...theme.typography.h5,
      color: theme.kitColors.white,
      marginBottom: theme.spacing.S,
    },
    '& > p': {
      ...theme.typography.body,
      color: theme.kitColors.grey200,
      marginBottom: theme.spacing.L,
    },
    '& > button': {
      ...theme.traits.clickable,
      padding: 0,
      borderStyle: 'none',
      background: 'none',
      marginTop: theme.spacing.XS,
    },
    '& > a': {
      marginTop: 'auto',
    },
  },
  link: {
    color: 'white',
    '& > svg': {
      stroke: 'white',
    },
  },
}))

export const CompanyTopicsSection: React.FC = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()
  const context = useContext(CompanyPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={layoutClasses.gridLayout}
    >
      <h2 className={classes.sectionHeading}>
        {context?.entry?.companyTopicsHeading}
      </h2>
      <div className={classes.topicsContainer}>
        {context?.entry?.companyTopics?.map((topic) => (
          <div className={classes.topic} key={topic?.uid}>
            <h3>{topic?.topicName}</h3>
            <p>{topic?.topicDescription}</p>
            <ArrowLink
              to={`/company/${topic?.topicPath ?? ''}`}
              className={classes.link}
            >
              Learn More
            </ArrowLink>
          </div>
        ))}
      </div>
    </LayoutContainer>
  )
}
