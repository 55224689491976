import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../../components/LayoutContainer'
import { Media } from '../../../components/Media'
import { Theme } from '../../../theme'
import { classNames } from '../../../utils'
import { CompanySustainabilityPageContext } from './CompanySustainabilityPage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing.XXL,
  },
  imageContainer: {
    width: 120,
    margin: '0 auto',
    marginBottom: theme.spacing.L,
  },
  itemTitle: {
    ...theme.typography.h3,
    textAlign: 'center',
    marginBottom: theme.spacing.S,
  },
  itemDescription: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    textAlign: 'center',
  },
}))

export const SustainabilityMetricsSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CompanySustainabilityPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classNames(
        layoutClasses.paddingBetweenItemsSpacingXXXL,
        layoutClasses.indentRow28px,
        layoutClasses.threeItemLayout,
      )}
    >
      {context?.entry?.companySustainabilityMetrics?.map((metric) => {
        const image = metric?.metricImage?.find((item) => item != null)
        return (
          <div key={metric?.uid}>
            <div className={classes.imageContainer}>
              <Media
                src={image?.url}
                type={image?.mimeType}
                alt={image?.altText}
                credit={image?.credit}
              />
            </div>
            <p className={classes.itemTitle}>{metric?.metricTitle}</p>
            <p className={classes.itemDescription}>
              {metric?.metricDescription}
            </p>
          </div>
        )
      })}
    </LayoutContainer>
  )
}
