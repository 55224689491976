import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useRouteMatch } from 'react-router-dom'
import { BackButton } from '../../components/BackButton'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames, fetchApiData, VariablesFix } from '../../utils'
import { StoryPreview } from './StoryPreview'
import {
  StoryDetailPage as StoryQuery,
  StoryDetailPage_entry_stories_story_Entry as StoryEntry,
  StoryDetailPage_entry_stories_story_Entry_relatedContent_stories_story_Entry as RelatedStoryEntry,
  StoryDetailPageVariables,
} from '../../api-types/StoryDetailPage'
import { useQuery } from 'react-query'
import { Media } from '../../components/Media'
import { BodyContent } from '../../components/BodyContent'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { Facebook } from '../../components/icons/Facebook'
import { Twitter } from '../../components/icons/Twitter'
import { LinkedIn } from '../../components/icons/LinkedIn'
import { Seo } from '../../components/Seo'
import { Loading } from '../../components/Loading'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.treadBackground(treadSrc, {
      left: -189,
      top: -383,
    }),
  },
  header: {
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.XXL,
  },
  socialLinks: {
    '& ul': {
      float: 'left',
      listStyle: 'none',
      '& > *': {
        display: 'inline-block',
        marginLeft: theme.spacing.XXL,
      },
    },
  },
  categoryLabel: {
    ...theme.typography.overline,
    gridColumn: '1 / -1',
    textAlign: 'center',
    color: theme.colors.blue,
    marginBottom: theme.spacing.M,
  },
  title: {
    ...theme.typography.h1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  titleLayout: {
    gridColumn: '2 / -2',
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      marginBottom: theme.spacing.XXL,
    },
  },
  mediaContainer: {
    gridColumn: '1 / -1',
    marginBottom: theme.spacing.XXXXL,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.XXXL,
    },
  },
  articleContainer: {
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: -theme.spacing.M,
    '& > li': {
      ...theme.typography.boldCaption,
      color: theme.colors.blue,
      marginRight: theme.spacing.M,
      padding: `6px ${theme.spacing.M}px`,
      backgroundColor: theme.kitColors.grey200,
      borderRadius: 2,
    },
  },
  relatedContentHeading: {
    ...theme.typography.h3,
    gridColumn: '1 / -1',
    marginTop: theme.spacing.XXXL,
    marginBottom: theme.spacing.L,
  },
  relatedContentItem: {
    gridColumn: 'span 4',
  },
}))

interface EntryData extends StoryEntry {
  relatedContent: (RelatedStoryEntry | null)[] | null
}

interface StoryData extends StoryQuery {
  entry: EntryData | null
}

export const StoryDetailPage: React.FC = () => {
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>()

  const { data, isLoading } = useQuery(['story', slug], () =>
    fetchApiData<StoryData, VariablesFix<StoryDetailPageVariables>>(
      `stories/${slug}`,
    ),
  )

  const theme = useTheme<Theme>()
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const media = data?.entry?.mainMedia?.find((m) => m != null)

  if (isLoading) return <Loading />
  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainer,
        classes.container,
      )}
      contentClass={layoutClasses.gridLayout}
    >
      <Seo pageTitle={data?.entry?.heading} image={media} />
      <div className={classes.header}>
        <BackButton />
        <div className={classes.socialLinks}>
          <ul>
            <Facebook color={theme.colors.blue} />
            <Twitter color={theme.colors.blue} />
            <LinkedIn color={theme.colors.blue} />
          </ul>
        </div>
      </div>
      <p className={classes.categoryLabel}>
        {data?.entry?.category?.find((c) => c != null)?.title}
      </p>
      <h1 className={classNames(classes.titleLayout, classes.title)}>
        {data?.entry?.heading}
      </h1>
      <div className={classes.mediaContainer}>
        <Media
          src={media?.url}
          type={media?.mimeType}
          srcSet={media?.srcset}
          alt={media?.altText}
          credit={media?.credit}
          aspectRatio={3 / 2}
        />
      </div>
      <div className={classes.articleContainer}>
        {data?.entry?.bodyContent && (
          <BodyContent html={data.entry.bodyContent} />
        )}

        <ul className={classes.tagsList}>
          {data?.entry?.tags?.map((tag) => (
            <li key={tag?.uid}>{tag?.title}</li>
          ))}
        </ul>
      </div>
      {(data?.entry?.relatedContent?.length ?? 0) > 0 && (
        <h3 className={classes.relatedContentHeading}>Related Content</h3>
      )}{' '}
      {data?.entry?.relatedContent?.map((item) => (
        <StoryPreview
          slug={item?.slug}
          title={item?.heading}
          summary={item?.summary}
          category={item?.category?.find((c) => c != null)?.title}
          className={classes.relatedContentItem}
        />
      ))}
    </LayoutContainer>
  )
}
