import { SVGProps } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import spriteSheet from 'feather-icons/dist/feather-sprite.svg'
import { classNames } from '../../utils'
interface Props {
  name: string
  color?: string
  className?: string
  size?: number
}

const useStyles = createUseStyles((theme: Theme) => ({
  icon: (props: Props) => ({
    width: props.size ?? 24,
    height: props.size ?? 24,
    stroke: props.color ?? theme.kitColors.grey700,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  }),
}))

export const FeatherIcon: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const iconName = props.name.replace(/-/g, ' ')

  const spriteProps: SVGProps<SVGUseElement> = {
    xlinkHref: `${spriteSheet}#${props.name}`,
  }

  return (
    <svg
      className={classNames(classes.icon, props.className)}
      role="img"
      aria-hidden="true"
      aria-label={iconName}
    >
      <use {...spriteProps} />
    </svg>
  )
}
