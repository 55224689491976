import React, { useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { Theme } from '../../theme'
import { classNames } from '../../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  none: {
    display: 'none',
  },
  form: {
    background: theme.kitColors.grey50,
    position: 'relative',
    display: 'flex',
    boxShadow:
      'inset 0px 0px 1px rgba(47, 47, 47, 0.05), inset 0px 2px 4px rgba(47, 47, 47, 0.1)',
    // padding: '4px 0 4px 16px',
    borderRadius: 2,
  },
  input: {
    ...theme.typography.smallBody,
    background: 'none',
    height: '100%',
    flex: 1,
    width: 0,
    borderStyle: 'none',
    paddingLeft: theme.spacing.XXL + theme.spacing.M,
    paddingRight: theme.spacing.XXL,
  },
  searchIcon: {
    position: 'absolute',
    left: theme.spacing.M,
    top: theme.spacing.S,
  },
  closeButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    right: theme.spacing.S,
    top: theme.spacing.S,
    padding: 0,
    background: 'none',
    borderStyle: 'none',
  },
}))

interface Props {
  onSubmit: (query?: string) => void
  className?: string
  placeholder?: string
}

export const SearchBar = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const [value, setValue] = useState('')
    const classes = useStyles()
    const theme = useTheme<Theme>()

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      props.onSubmit(value.trim())
    }

    const handleClear = () => {
      setValue('')
      props.onSubmit(undefined)
    }

    return (
      <form
        onSubmit={handleSubmit}
        className={classNames(props.className, classes.form)}
      >
        <FeatherIcon
          name="search"
          className={classes.searchIcon}
          color={theme.kitColors.grey900}
        />
        <label htmlFor={classes.input} className={classes.srOnly}>
          Search:
        </label>
        <input
          ref={ref}
          className={classes.input}
          type="text"
          placeholder={props.placeholder ?? 'Search Stories'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={classes.input}
        />
        <div className={[classes.none, classes.srOnly].join(' ')}>
          Type a search and press Enter.
        </div>
        <button
          className={classes.closeButton}
          onClick={handleClear}
          type="button"
        >
          <FeatherIcon name="x" color={theme.kitColors.grey900} />
        </button>
      </form>
    )
  },
)
