import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'

const useStyles = createUseStyles((theme: Theme) => ({
  link: {
    ...theme.typography.menuLink,
    ...theme.traits.clickable,
    textDecoration: 'none',
    color: theme.colors.blue,
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
  icon: {
    marginLeft: 12,
    stroke: theme.colors.blue,
  },
}))

export const ArrowLink: React.FC<React.ComponentProps<typeof Link>> = ({
  children,
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Link className={classNames(classes.link, className)} {...props}>
      {children}
      <FeatherIcon name={'arrow-right'} className={classes.icon} />
    </Link>
  )
}
