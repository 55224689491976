import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing.XXXXL,
    paddingBottom: theme.spacing.XXXXL,
  },
  text: {
    ...theme.typography.h5,
    color: theme.kitColors.grey800,
    paddingTop: theme.spacing.L,
  },
}))

export const Loading: React.FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles()

  const numberOfCirles = 8
  const rotationSpeed = 250 // in mS
  const [colorIndex, setColorIndex] = useState(numberOfCirles - 1)

  useEffect(() => {
    const interval = setInterval(() => {
      setColorIndex((c) => (c === 0 ? numberOfCirles - 1 : c - 1))
    }, rotationSpeed)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={classNames(className, classes.content)}>
      <svg
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="79.998"
          cy="12"
          r="12"
          fill={colorIndex === 0 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="31.9139"
          cy="31.9219"
          r="12"
          transform="rotate(-45 31.9139 31.9219)"
          fill={colorIndex === 1 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="12"
          cy="80.0029"
          r="12"
          transform="rotate(-90 12 80.0029)"
          fill={colorIndex === 2 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="31.918"
          cy="128.086"
          r="12"
          transform="rotate(-135 31.918 128.086)"
          fill={colorIndex === 3 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="80"
          cy="148.001"
          r="12"
          transform="rotate(-180 80 148.001)"
          fill={colorIndex === 4 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="128.084"
          cy="128.085"
          r="12"
          transform="rotate(135 128.084 128.085)"
          fill={colorIndex === 5 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="148"
          cy="80.002"
          r="12"
          transform="rotate(90 148 80.002)"
          fill={colorIndex === 6 ? '#DC5034' : '#6D2D41'}
        />
        <circle
          cx="128.084"
          cy="31.9198"
          r="12"
          transform="rotate(45 128.084 31.9198)"
          fill={colorIndex === 7 ? '#DC5034' : '#6D2D41'}
        />
      </svg>
      <h5 className={classes.text}>Loading...</h5>
    </div>
  )
}
