import React, { createContext } from 'react'
import { BrandsListSection } from './BrandsListSection'
import { BrandsShowcaseSection } from './BrandsShowcaseSection'
import {
  BrandsPage as IBrandsPageQuery,
  BrandsPage_entry_brands_brands_Entry as BrandsPageEntry,
} from '../../api-types/BrandsPage'
import { fetchApiData } from '../../utils'
import { useQuery } from 'react-query'
import { MediaHeader } from '../../components/MediaHeader'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'

interface BrandsPageQuery extends IBrandsPageQuery {
  entry: BrandsPageEntry | null
}

export const BrandsPageContext = createContext<BrandsPageQuery | undefined>(
  undefined,
)

export const BrandsPage: React.FC = () => {
  const { data, isLoading } = useQuery('brands', () =>
    fetchApiData<BrandsPageQuery>('brands'),
  )

  const headerMedia = data?.entry?.heroImage?.find((item) => item != null)

  if (isLoading) return <Loading />
  return (
    <BrandsPageContext.Provider value={data}>
      <Breadcrumbs />
      <MediaHeader
        heading={data?.entry?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <BrandsShowcaseSection />
      <BrandsListSection />
    </BrandsPageContext.Provider>
  )
}
