import React from 'react'
import { createUseStyles } from 'react-jss'
import { NavLink } from 'react-router-dom'
import { Theme } from '../theme'
import { LayoutContainer } from './LayoutContainer'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    // backgroundColor: theme.kitColors.grey300,
    // borderBottomStyle: 'solid',
    // borderBottomWidth: 1,
    // borderBottomColor: theme.kitColors.grey400,
    boxShadow: `0px 0px 1px rgba(47, 47, 47, 0.05), 0px 2px 4px rgba(47, 47, 47, 0.1)`,
  },
  content: {
    '& ul': {
      display: 'inline-flex',
      marginBottom: -1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& > li': {
        marginRight: theme.spacing.XL,
      },
    },
  },
  route: {
    ...theme.traits.clickable,
    ...theme.typography.menuLink,
    display: 'block',
    color: theme.colors.navy,
    paddingTop: theme.spacing.M,
    paddingBottom: theme.spacing.M,
    textDecoration: 'none',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: 'transparent',
    '&:not(:last-child)': {
      marginRight: theme.spacing.XXL,
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing.L,
      },
    },
  },
  activeRoute: {
    color: theme.colors.blue,
    borderBottomColor: theme.colors.blue,
    borderBottomWidth: 4,
    paddingBottom: theme.spacing.M - 4,
  },
}))

export const TabNavigation: React.FC<{
  routes: Array<{ name: string; path: string }>
}> = ({ routes }) => {
  const classes = useStyles()

  return (
    <LayoutContainer
      containerClass={classes.container}
      contentClass={classes.content}
      ContentElement={'nav'}
    >
      <ul aria-label="u of q top bar">
        {routes.map((route, i) => (
          <li>
            <NavLink
              to={{ pathname: route.path, state: { scrollToTop: false } }}
              className={classes.route}
              activeClassName={classes.activeRoute}
              key={i}
            >
              {route.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </LayoutContainer>
  )
}
