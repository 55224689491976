import React, { useCallback, useContext, useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { CompanyPageContext } from './CompanyPage'
import { parseISO, format } from 'date-fns'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    backgroundColor: theme.colors.raisin,
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
    overflow: 'visible',
    maxWidth: 'none',
  },
  timeline: {
    transition: 'transform 500ms ease',
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  timelineItem: {
    display: 'inline-block',

    width: 286,
    '&:not(:last-child)': {
      marginRight: theme.spacing.XXL,
    },
  },
  timelineImage: {
    height: 246,
    width: 246,
    marginBottom: theme.spacing.L,
    borderRadius: 246 / 2,
    overflow: 'hidden',
    backgroundColor: theme.kitColors.grey300,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  timelineHeading: {
    ...theme.typography.h3,
    textAlign: 'center',
    color: theme.kitColors.white,
    marginBottom: theme.spacing.S,
  },
  timelineParagraph: {
    ...theme.typography.body,
    textAlign: 'center',
    color: theme.kitColors.grey200,
  },
  button: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 32px)',

    height: 64,
    width: 64,
    borderRadius: 32,
    backgroundColor: theme.kitColors.white,
    borderStyle: 'none',
    boxShadow:
      '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1)',
    '& > svg': {
      position: 'absolute',
      top: 20,
      left: 20,
    },
  },
  prevButton: {
    left: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      left: theme.spacing.XXL,
    },
  },
  nextButton: {
    right: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      right: theme.spacing.XXL,
    },
  },
}))

const pageLeftMargin = window.innerWidth < 991 ? 40 : 80
const itemWidth = 246 + pageLeftMargin

const calculatePageItems = () =>
  Math.floor((window.innerWidth - pageLeftMargin) / itemWidth)

export const CompanyTimelineSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const theme = useTheme<Theme>()
  const context = useContext(CompanyPageContext)

  const timelineItems = context?.entry?.timeline ?? []

  const [offsetIndex, setOffsetIndex] = useState(0)
  const [pageItems, setPageItems] = useState(calculatePageItems())

  const updatePageItems = useCallback(() => {
    setPageItems(calculatePageItems())
  }, [])

  useEffect(() => {
    updatePageItems()
    window.addEventListener('resize', updatePageItems)

    return () => {
      window.removeEventListener('resize', updatePageItems)
    }
  }, [updatePageItems])

  const canGoForward = offsetIndex < timelineItems.length - pageItems
  const canGoBack = offsetIndex > 0

  const handleNext = () => {
    if (canGoForward) {
      setOffsetIndex((index) => index + 1)
    }
  }

  const handlePrev = () => {
    if (canGoBack) {
      setOffsetIndex((index) => index - 1)
    }
  }

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classes.content}
    >
      <h2 className={classes.srOnly}>Timeline</h2>
      <ul
        className={classes.timeline}
        style={{
          width: timelineItems.length * itemWidth - pageLeftMargin,
          transform: `translateX(${-itemWidth * offsetIndex}px)`,
        }}
      >
        {timelineItems.map((event, i) => {
          const year = format(parseISO(event?.date), 'Y')
          const image = event?.image?.find((img) => img != null)
          return (
            <li
              key={i}
              className={classes.timelineItem}
              role="group"
              aria-label="company timeline"
            >
              <img
                className={classes.timelineImage}
                src={image?.url ?? undefined}
                alt={image?.altText ?? 'timeline image'}
              />
              <h3 className={classes.timelineHeading}>{year}</h3>
              <p className={classes.timelineParagraph}>{event?.summary}</p>
            </li>
          )
        })}
      </ul>
      {canGoBack && (
        <button
          className={classNames(classes.button, classes.prevButton)}
          onClick={handlePrev}
        >
          <FeatherIcon name="arrow-left" color={theme.colors.blue} />
        </button>
      )}
      {canGoForward && (
        <button
          className={classNames(classes.button, classes.nextButton)}
          onClick={handleNext}
        >
          <FeatherIcon name="arrow-right" color={theme.colors.blue} />
        </button>
      )}
    </LayoutContainer>
  )
}
