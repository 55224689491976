import React from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import { Button } from '../../components/Button'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import {
  classNames,
  fetchApiData,
  useQueryState,
  VariablesFix,
} from '../../utils'
import { StoriesCategoryNav } from '../stories/StoriesCategoryNav'
import {
  NewsPage as INewsPageQuery,
  NewsPageVariables as INewsPageVariables,
  NewsPage_news_news_news_Entry as NewsPreviewEntry,
  NewsPage_sideBar_news_sideBarInfo_Entry as SideBarEntry,
} from '../../api-types/NewsPage'
import { NewsPreview } from '../stories/NewsPreview'
import { useInfiniteQuery } from 'react-query'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import treadSrc2 from '../../assets/patterns/Tread-Raisin.svg'
import angleSrc from '../../assets/patterns/Angle-Orange.svg'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    ...theme.traits.treadBackground(treadSrc, {
      left: -92,
      top: -383,
    }),
  },
  secondaryTread: theme.traits.treadBackground(treadSrc2, {
    transform: 'scale(-1, -1)',
    right: 0,
    bottom: -303,
  }),
  secondaryAngle: theme.traits.angleBackground(angleSrc, {
    transform: 'scale(-1, -1)',
    right: 0,
    bottom: 0,
  }),
  content: {
    marginBottom: theme.spacing.XXXL,
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
    },
    '& > *:first-child': {
      gridColumn: 'span 8',
      [theme.breakpoints.down('md')]: {
        gridColumn: 'span 5',
      },
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 4',
      },
    },
    '& > *:nth-child(2)': {
      gridColumn: 'span 4',
      paddingLeft: theme.spacing.XXXL - theme.spacing.L,
      [theme.breakpoints.down('md')]: {
        gridColumn: 'span 3',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingTop: theme.spacing.XXL,
      },
    },
    '& > *:last-child': {
      gridColumn: 'span 4',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        gridRow: 2,
      },
    },
  },
  heading: {
    ...theme.typography.h1,
    color: theme.colors.navy,
    textTransform: 'uppercase',
    cursor: 'auto',
    '&:active': {
      opacity: 1,
    },
  },
  nav: {
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.XXL,
    },
  },
  sidebarTitle: {
    ...theme.typography.h4,
    marginBottom: theme.spacing.S,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing.XXXL,
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing.XXL,
      },
    },
  },
  sidebarSubtitle: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.S,
  },
  sidebarParagraph: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    marginBottom: theme.spacing.L,
  },
  sidebarLink: {
    ...theme.typography.menuLink,
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.blue,
    marginTop: theme.spacing.XS,
  },
}))

interface NewsPageQuery extends INewsPageQuery {
  news: Array<NewsPreviewEntry | null> | null
  sideBar: SideBarEntry | null
}

type NewsPageVariables = VariablesFix<INewsPageVariables>

const newsItemsPerPage = 10

export const NewsPage: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const [searchQuery, setSearchQuery] = useQueryState('search')

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['news', searchQuery],
    (params) =>
      fetchApiData<NewsPageQuery, NewsPageVariables>('news', {
        query: params.pageParam ?? {
          offset: 0,
          limit: newsItemsPerPage,
          search: searchQuery,
        },
      }),
    {
      getNextPageParam: (lastPage, pages): NewsPageVariables | undefined => {
        const totalLoaded = pages.reduce(
          (total, page) => total + (page.news?.length ?? 0),
          0,
        )

        if (totalLoaded >= lastPage.total) return

        return {
          offset: totalLoaded,
          limit: newsItemsPerPage,
          search: searchQuery,
        }
      },
      keepPreviousData: !searchQuery,
    },
  )
  const mediaEmail = data?.pages[0].sideBar?.mediaContactEmail
  const mediaPhone = data?.pages[0].sideBar?.mediaContactPhoneNumber
  const mediaKitLink = data?.pages[0].sideBar?.mediaKitInternalLink

  return (
    <>
      <Breadcrumbs />
      <div className={isNotMobile ? classes.secondaryTread : ''}>
        <div className={isNotMobile ? classes.secondaryAngle : ''}>
          <LayoutContainer
            containerClass={[
              layoutClasses.paddedContainerLarge,
              isNotMobile ? classes.container : '',
            ].join(' ')}
          >
            <h1 className={classes.heading}>NEWS</h1>
            <h2 className={classes.srOnly}>List of Top News</h2>
            <StoriesCategoryNav
              className={classes.nav}
              onSearch={setSearchQuery}
              categories={[{ label: 'News', path: '#' }]}
              searchPlaceholder={'Search News'}
            />
            <div
              className={classNames(layoutClasses.gridLayout, classes.content)}
            >
              <ul>
                {data?.pages.map((page) =>
                  page.news?.map((item) => {
                    if (!item) return null
                    const category = item.newsCategory?.find((c) => c != null)

                    return (
                      <NewsPreview
                        key={item.uid}
                        as={'li'}
                        category={category?.title}
                        date={item.postDate}
                        headline={item.heading}
                        slug={item.slug}
                      />
                    )
                  }),
                )}
              </ul>
              <div>
                <h4 className={classes.sidebarTitle}>
                  {data?.pages[0].sideBar?.sideBarTitle}
                </h4>
                <p className={classes.sidebarParagraph}>
                  {data?.pages[0].sideBar?.sideBarDescription}
                </p>
                <h5 className={classes.sidebarSubtitle}>
                  {data?.pages[0].sideBar?.mediaContactSubheading}
                </h5>
                {mediaEmail && (
                  <a
                    className={classes.sidebarLink}
                    href={`mailto:${mediaEmail}`}
                  >
                    {mediaEmail}
                  </a>
                )}
                {mediaPhone && (
                  <a className={classes.sidebarLink} href={`tel:${mediaPhone}`}>
                    {mediaPhone}
                  </a>
                )}
                <h4 className={classes.sidebarTitle}>
                  {data?.pages[0].sideBar?.mediaKitTitle}
                </h4>
                <p className={classes.sidebarParagraph}>
                  {data?.pages[0].sideBar?.mediaKitDescription}
                </p>
                {mediaKitLink && (
                  <ArrowLink to={mediaKitLink}>View Now</ArrowLink>
                )}
              </div>
              {hasNextPage && (
                <Button variant="solid" onClick={() => fetchNextPage()}>
                  View More
                </Button>
              )}
            </div>
          </LayoutContainer>
        </div>
      </div>
    </>
  )
}
