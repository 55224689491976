import { CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'

const maxWidthMedia = (size: number) =>
  `@media screen and (max-width: ${size}px)`
const minWidthMedia = (size: number) =>
  `@media screen and (min-width: ${size}px)`

type Breakpoint = 'sm' | 'md'

const mdMinWidth = 768
const lgMinWidth = 991

const colors = {
  orange: '#DC5034',
  raisin: '#6D2D41',
  blue: '#2526A9',
  lightGray: '#EBEBEA',
  navy: '#28284C',
  teal: '#279496',
  mauve: '#884848',
  marigold: '#F89E53',
  pink: '#F59494',
  green: '#1F7678',
  white: '#FFFFFF',
}

/** non-brand colors for wireframe usage */
const kitColors = {
  blue: '#1E90FF',
  white: '#FFFFFF',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white90: 'rgba(255, 255, 255, 0.9)',
  grey50: '#FBFBFB',
  grey100: '#F3F3F2',
  grey200: '#EBEBEA',
  grey300: '#D4D4D3',
  grey400: '#BCBCBB',
  grey500: '#9E9E9E',
  grey600: '#767675',
  grey700: '#5E5E5E',
  grey800: '#464646',
  grey900: '#2F2F2F',
}

const breakpoints = {
  lgMinWidth,
  mdMinWidth,
  down: (size: Breakpoint | number): string => {
    switch (size) {
      case 'sm':
        return maxWidthMedia(mdMinWidth - 1)
      case 'md':
        return maxWidthMedia(lgMinWidth - 1)
      default:
        return maxWidthMedia(size)
    }
  },
  up: (size: Breakpoint | number): string => {
    switch (size) {
      case 'sm':
        return minWidthMedia(mdMinWidth)
      case 'md':
        return minWidthMedia(lgMinWidth)
      default:
        return minWidthMedia(size)
    }
  },
}

const slab = '"museo-slab", serif'
const sans = '"museo-sans", sans-serif'

const baseTypography = {
  fontFamily: sans,
  color: colors.navy,
}

const slab800 = {
  fontFamily: slab,
  fontWeight: 800,
}

const slab700 = {
  fontFamily: slab,
  fontWeight: 700,
}

const slab500 = {
  fontFamily: slab,
  fontWeight: 500,
}

const sans700 = {
  fontFamily: sans,
  fontWeight: 700,
}

const sans300 = {
  fontFamily: sans,
  fontWeight: 300,
}

/**
 * Common typography types. Note that the font weights from figma inspect are not accurate - refer to the style guide.
 *
 * Inspect -> actual:
 *
 * Slab:
 *
 * 700 -> 800 (called 900 in style guide)
 *
 * 600 -> 700
 *
 * 400 -> 500
 *
 * Sans:
 *
 * 600 -> 700
 *
 * 400 -> 300
 */
const typography = {
  /** Size 48px / Height 60px / Weight 800 */
  h1: {
    ...slab800,
    fontSize: 48,
    lineHeight: '60px',
    [breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '40px',
    },
  },
  /** Size 37px / Height 52px / Weight 700 */
  h2: {
    ...slab700,
    fontSize: 37,
    lineHeight: '52px',
    [breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '40px',
    },
  },
  /** Size 31px / Height 48px / Weight 700 */
  h3: {
    ...slab700,
    fontSize: 31,
    lineHeight: '48px',
    [breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '40px',
    },
  },
  /** Size 26px / Height 40px / Weight 700 */
  h4: {
    ...slab700,
    fontSize: 26,
    lineHeight: '40px',
  },
  /** Size 21px / Height 32px / Weight 700 */
  h5: {
    ...sans700,
    fontSize: 21,
    lineHeight: '32px',
  },
  /** Size 16px / Height 28px / Weight 700 */
  button: {
    ...sans700,
    fontSize: 16,
    lineHeight: '28px',
  },
  /** Size 14px / Height 20px / Weight 300 */
  buttonSmall: {
    ...sans300,
    fontSize: 14,
    lineHeight: '20px',
  },
  /** Size 16px / Height 28px / Weight 500 */
  menuLink: {
    ...slab500,
    fontSize: 16,
    lineHeight: 'auto',
  },
  /** Size 18px / Height 32px / Weight 300 */
  body: {
    ...sans300,
    fontSize: 18,
    lineHeight: '32px',
    [breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '28px',
    },
  },
  /** Size 18px / Height 32px / Weight 700 */
  bodyEm: {
    ...sans700,
    fontSize: 18,
    lineHeight: '32px',
    [breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: '28px',
    },
  },
  /** Size 16px / Height 28px / Weight 300 */
  smallBody: {
    ...sans300,
    fontSize: 16,
    lineHeight: '28px',
  },
  /** Size 16px / Height 28px / Weight 700 */
  smallBodyEm: {
    ...sans700,
    fontSize: 16,
    lineHeight: '28px',
  },
  /** Size 14px / Height 20px / Weight 700 / UPPERCASE */
  overline: {
    ...slab700,
    fontSize: 14,
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  /** Size 14px / Height 20px / Weight 700 */
  boldCaption: {
    ...sans700,
    fontSize: 14,
    lineHeight: '20px',
  },
}

const spacing = {
  /** 4px */
  XS: 4,
  /** 8px */
  S: 8,
  /** 16px */
  M: 16,
  /** 24px */
  L: 24,
  /** 32px */
  XL: 32,
  /** 40px */
  XXL: 40,
  /** 80px */
  XXXL: 80,
  /** 120px */
  XXXXL: 120,
}

const measurements = {
  headerHeight: 80,
  contentMaxWidth: 1440,
  contentPadding: spacing.XXXL,
  contentPaddingTablet: spacing.XXL,
  contentPaddingMobile: spacing.XL,
}

const grid = {
  columns: 12,
  columnsTablet: 8,
  columnsMobile: 4,
}

const traits = {
  clickable: {
    cursor: 'pointer',
    '&:active': {
      opacity: 0.2,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  treadBackground: (backgroundSrc: string, config: CSSProperties) => {
    const { backgroundColor, ...rest } = config
    return {
      overflow: 'hidden',
      position: 'relative',
      '&::before': {
        zIndex: -1,
        content: '""',
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor,
      },
      '&::after': {
        zIndex: -1,
        content: '""',
        width: 1440,
        height: 1005,
        backgroundImage: `url(${backgroundSrc})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        ...rest,
      },
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  angleBackground: (backgroundSrc: string, config?: CSSProperties) => {
    return {
      overflow: 'hidden',
      '&::after': {
        backgroundImage: `url(${backgroundSrc})`,
        content: '""',
        position: 'absolute',
        backgroundSize: 'contain',
        zIndex: -1,
        backgroundRepeat: 'no-repeat',
        width: 708,
        height: 414,
        ...config,
      },
    }
  },
}

/** z-index values */
const layers = {
  header: 2,
  mediaOverlay: 1,
}

/** ada styles */
const ada = {
  srOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    border: 0,
  },
  srOnlyFocusable: {
    '&:active, &:focus': {
      position: 'relative',
      display: 'block',
      width: '100% !important',
      height: 'auto',
      margin: '0 0 10px 0',
      overflow: 'visible',
      clip: 'auto',
      padding: '5px',
      zIndex: '1000',
      color: colors.white,
      background: colors.navy,
    },
  },
}

export const theme = {
  colors,
  typography,
  measurements,
  breakpoints,
  grid,
  traits,
  kitColors,
  layers,
  spacing,
  ada,
}

export type Theme = typeof theme

export const useGlobalStyles = createUseStyles({
  '@global': {
    body: {
      margin: 0,
      minHeight: '100vh',
      scrollBehavior: 'smooth',
      ...baseTypography,
    },
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    'ul, ol': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    'h1,h2,h3,h4,h5,p,li': {
      margin: 0,
    },
    'button:active': {
      outlineStyle: 'none',
    },
  },
})
