import React, { useEffect, useRef, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { FeatherIcon } from './icons/FeatherIcon'
import treadSrc from '../assets/patterns/Tread-White.svg'

const headerBreakpoint = 1312

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.treadBackground(treadSrc, {
      transform: 'scale(-1, -1)',
      left: theme.spacing.XXXL,
      bottom: 0,
      opacity: 0.3,
      backgroundColor: theme.colors.navy,
    }),
    zIndex: 10,
    position: 'absolute',
    height: 'auto',
    minHeight: 700,
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  closeButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: theme.spacing.XL,
    right: theme.spacing.XL,
    padding: 0,
    background: 'none',
    borderStyle: 'none',
    '&:focus': {
      outline: '2px dotted white',
    },
  },
  content: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing.XXL,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing.XL,
      },
    },
    '& > li a[class^="logoLink-"]': {
      [theme.breakpoints.down(headerBreakpoint)]: {
        display: 'none',
      },
    },
    '& > li a': {
      ...theme.typography.h3,
      ...theme.traits.clickable,
      color: theme.kitColors.white,
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.menuLink,
      },
      '&:focus': {
        outline: '2px dotted white',
      },
    },
    '& > li button': {
      backgroundColor: theme.kitColors.white,
      borderColor: theme.kitColors.white,
      color: theme.colors.blue,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    '& > li[class^="logIn-"] button': {
      color: '#FFFFFF',
      borderColor: '#FFFFFF',
      backgroundColor: '#28284C',
    },
  },
}))

type Props = {
  isOpen: boolean
  active: boolean
  onClose: () => void
}

export const MobileMenu: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { active } = props
  const [focusTrapped, setFocusTrapped] = useState(false)
  const buttonCloseRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (props.isOpen) {
      setFocusTrapped(active)
    } else {
      setFocusTrapped(false)
      buttonCloseRef.current?.blur()
    }
  }, [props.isOpen, active])

  return (
    <FocusTrap active={focusTrapped}>
      <div
        className={classes.container}
        style={{ display: props.isOpen ? 'block' : 'none' }}
        aria-label="menu"
        role="dialog"
        aria-modal="true"
      >
        <button
          onClick={props.onClose}
          className={classes.closeButton}
          ref={buttonCloseRef}
          aria-label="Close dialog"
        >
          <FeatherIcon name="x" color="white" />
        </button>
        <ul className={classes.content} onClick={props.onClose}>
          {props.children}
        </ul>
      </div>
    </FocusTrap>
  )
}
