import { format, getDayOfYear, parseISO } from 'date-fns'
import { useCallback, useLayoutEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'

/** Helper for combining class names */
export function classNames(
  ...args: Array<string | boolean | undefined>
): string {
  return args.filter((item) => typeof item === 'string').join(' ')
}

/** Workaround for index signaure error when using query variable types in `fetchApiData`  */
export type VariablesFix<T> = {
  [K in keyof T]: T[K]
}

export function fetchApiData<
  Res,
  Params extends Record<
    string,
    string | number | boolean | undefined | null
  > = Record<string, never>
>(
  path: string,
  params?: Partial<{
    query: Params
    rawData: boolean
  }>,
): Promise<Res> {
  const query = new URLSearchParams()
  if (params?.query !== undefined) {
    Object.entries(params.query).forEach(([key, value]) => {
      if (value != null) {
        query.set(key, value.toString())
      }
    })
  }

  const baseUrl = process.env.REACT_APP_API_URL
  const previewToken = new URLSearchParams(window.location.search).get('token')
  const queryString = query.toString()

  return fetch(`${baseUrl}/${path}${queryString ? '?' : ''}${queryString}`, {
    headers: previewToken
      ? {
          'X-Craft-Token': previewToken,
        }
      : undefined,
  })
    .then((res) => {
      if (res.status >= 400) {
        return Promise.reject(res.status)
      }
      return res.json()
    })
    .then((res) => {
      if (res.data !== undefined && !params?.rawData) {
        return res.data
      }

      return res
    })
}

/** Returns the value corresponding to the key from the url query string */
export function useQueryParam(key: string): string | null {
  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search), [search])

  return query.get(key)
}

/** Acts like useState but uses the url query string as the source of truth */
export function useQueryState(
  paramName: string,
): [string | undefined, (v?: string) => void] {
  const { search } = useLocation()
  const history = useHistory()
  const query = useMemo(() => new URLSearchParams(search), [search])

  const value = query.get(paramName) ?? undefined

  const setValue = useCallback(
    (nextValue?: string) => {
      const nextQuery = new URLSearchParams(search)
      if (nextValue) {
        nextQuery.set(paramName, nextValue)
      } else {
        nextQuery.delete(paramName)
      }
      history.replace({
        search: nextQuery.toString(),
      })
    },
    [history, search, paramName],
  )

  return [value, setValue]
}

export function formatDateRange(
  start: string,
  end?: string,
  dateFormat = 'M/d',
): string {
  const startDate = parseISO(start)

  if (end) {
    const endDate = parseISO(end)
    return getDayOfYear(startDate) === getDayOfYear(endDate)
      ? format(startDate, dateFormat)
      : `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`
  }

  return format(startDate, dateFormat)
}

export function formatTimeRange(
  start: string,
  end: string,
  dateFormat = 'h:mma',
): string {
  const startDate = parseISO(start)
  const endDate = parseISO(end)

  return `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`
}

export function isDefined<T>(item: T | undefined): item is T {
  return item !== undefined
}

const useLockStyles = createUseStyles({
  scrollLock: {
    margin: 0,
    height: '100%',
    overflow: 'hidden',
  },
})

export function useScrollLock(locked = false): void {
  const { scrollLock } = useLockStyles()

  useLayoutEffect(() => {
    const html = document.getElementsByTagName('html').item(0)
    const body = document.getElementsByTagName('body').item(0)

    if (html && body) {
      if (locked) {
        html.classList.add(scrollLock)
        body.classList.add(scrollLock)
      } else {
        html.classList.remove(scrollLock)
        body.classList.remove(scrollLock)
      }
    }

    return () => {
      if (html && body) {
        html.classList.remove(scrollLock)
        body.classList.remove(scrollLock)
      }
    }
  }, [locked, scrollLock])
}
