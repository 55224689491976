import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { ArrowLink } from '../../components/ArrowLink'
import { classNames } from '../../utils'
import { CareersPageContext } from './CareersPage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.navy,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.XXL,
    },
  },
  article: {
    borderTop: [2, 'solid', theme.colors.orange],
    paddingTop: theme.spacing.M,
  },
  h3: {
    ...theme.typography.h3,
    color: theme.kitColors.white,
    marginRight: 'auto',
  },
  itemHeading: {
    ...theme.typography.h5,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.S,
  },
  overline: {
    ...theme.typography.overline,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.S,
  },
  text: {
    ...theme.typography.smallBody,
    color: theme.kitColors.white,
    opacity: '70%',
    marginBottom: theme.spacing.M,
  },
  link: {
    color: theme.kitColors.white,
    marginTop: 'auto',
    '& > svg': {
      stroke: theme.kitColors.white,
    },
  },
  topLink: {
    color: theme.kitColors.white,
    '& > svg': {
      stroke: theme.kitColors.white,
    },
  },
}))

export const CareersJobOpeningsSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CareersPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainer,
        classes.container,
      )}
    >
      <div className={classes.headerContainer}>
        <h2 className={classes.h3}>{context?.entry?.jobOpeningsHeading}</h2>
        <ArrowLink
          className={classes.topLink}
          to={{ pathname: context?.entry?.externalJobSiteLink ?? '' }}
          target="_blank"
        >
          Explore All Opportunities
        </ArrowLink>
      </div>
      <div
        className={classNames(
          layoutClasses.paddingBetweenItemsSpacingXXXL,
          layoutClasses.indentRow28px,
          layoutClasses.threeItemLayout,
        )}
      >
        {context?.entry?.jobOpeningsFeaturedJobs?.map((job, i) => (
          <article key={job?.uid} className={classes.article}>
            <p className={classes.overline}>{job?.label}</p>
            <h3 className={classes.itemHeading}>{job?.jobTitle}</h3>
            <p className={classes.text}>{job?.jobDescription}</p>
            <ArrowLink
              className={classes.link}
              to={{ pathname: job?.externalLink ?? '' }}
              target="_blank"
            >
              See Opportunities
            </ArrowLink>
          </article>
        ))}
      </div>
    </LayoutContainer>
  )
}
