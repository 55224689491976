import React from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import { Media } from '../../components/Media'
import { PlaceholderImage } from '../../components/placeholders/PlaceholderImage'
import { storyDetail } from '../../routes'
import { Theme } from '../../theme'
import { classNames } from '../../utils'

const useItemStyles = createUseStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  categoryLabel: {
    ...theme.typography.overline,
    color: theme.colors.blue,
    marginTop: theme.spacing.L,
    marginBottom: theme.spacing.S,
  },
  title: (props: StoryPreviewProps) => ({
    ...(props.isLarge ? theme.typography.h3 : theme.typography.h5),
    marginBottom: theme.spacing.M,
  }),
  summary: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    marginBottom: theme.spacing.M,
  },
  link: {
    marginTop: 'auto',
  },
}))

type StoryPreviewProps = Partial<{
  slug: string | null // TODO: use slug for nicer urls
  title: string | null
  summary: string | null
  category: string | null
  isLarge: boolean
  className: string
  media?: React.ComponentProps<typeof Media>
}>

export const StoryPreview: React.FC<StoryPreviewProps> = (props) => {
  const classes = useItemStyles(props)
  const { category, title, summary, slug } = props

  if (!slug) return null

  const linkTo = storyDetail.replace(':slug', slug)

  return (
    <article className={classNames(classes.content, props.className)}>
      {props.media ? (
        <Media aspectRatio={3 / 2} {...props.media} />
      ) : (
        <PlaceholderImage aspectRatio={3 / 2} />
      )}
      <p className={classes.categoryLabel}>{category}</p>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.summary}>{summary}</p>

      <ArrowLink to={linkTo} className={classes.link}>
        Read More
      </ArrowLink>
    </article>
  )
}
