import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { ArrowLink } from '../../components/ArrowLink'
import { classNames } from '../../utils'
import { DoingBusinessWithQPageContext } from './DoingBusinessWithQPage'
import { Media } from '../../components/Media'

const useStyles = createUseStyles((theme: Theme) => ({
  headerContainer: {
    gridColumn: '3 / span 8',
    textAlign: 'center',
    marginBottom: theme.spacing.XXXXL,
    [theme.breakpoints.down('md')]: {
      gridColumn: '2 / span 6',
      marginBottom: theme.spacing.L,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
  },
  h3: {
    ...theme.typography.h3,
    marginBottom: theme.spacing.M,
  },
  itemHeading: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.M,
  },
  itemDescription: {
    ...theme.typography.smallBody,
    color: theme.kitColors.grey700,
  },
  text: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
  },
  benefitsContainer: {
    gridColumn: 'span 12',
    '& > *': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 8',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
  },
  image: {
    width: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing.L,
  },
  link: {
    justifyContent: 'center',
    marginTop: 'auto',
  },
}))

export const DoingBusinessWithQPartnersSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(DoingBusinessWithQPageContext)

  return (
    <LayoutContainer
      containerClass={layoutClasses.paddedContainerLarge}
      contentClass={layoutClasses.gridLayout}
    >
      <div className={classes.headerContainer}>
        <h2 className={classes.h3}>{context?.entry?.partnersSectionHeading}</h2>
        <p className={classes.text}>
          {context?.entry?.partnersSectionDescription}
        </p>
      </div>
      <div
        className={classNames(
          layoutClasses.paddingBetweenItemsSpacingXXXL,
          layoutClasses.indentRow28px,
          layoutClasses.threeItemLayout,
          classes.benefitsContainer,
        )}
      >
        {context?.entry?.partnersSectionItems?.map((item) => {
          const media = item?.media?.find((img) => img != null)
          return (
            <article key={item?.uid}>
              <div className={classes.image}>
                <Media
                  src={media?.url}
                  type={media?.mimeType}
                  alt={media?.altText}
                  credit={media?.credit}
                />
              </div>
              <h3 className={classes.itemHeading}>{item?.heading}</h3>
              <p className={classes.itemDescription}>{item?.description}</p>
              <ArrowLink className={classes.link} to={item?.internalLink ?? ''}>
                Learn More
              </ArrowLink>
            </article>
          )
        })}
      </div>
    </LayoutContainer>
  )
}
