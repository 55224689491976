import React, { Fragment, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../../components/LayoutContainer'
import { Media } from '../../../components/Media'
import { Theme } from '../../../theme'
import { classNames } from '../../../utils'
import { CompanySustainabilityPageContext } from './CompanySustainabilityPage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.raisin,
  },
  h3: {
    ...theme.typography.h3,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.M,
  },
  itemTitle: {
    ...theme.typography.h5,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.XS,
  },
  itemDescription: {
    ...theme.typography.body,
    color: theme.kitColors.grey200,
    marginBottom: theme.spacing.L,
  },
}))

export const CompanySustainabilityFootprintSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CompanySustainabilityPageContext)
  const image = context?.entry?.companySustainabilityFootprintMedia?.find(
    (item) => item != null,
  )

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={layoutClasses.splitLayoutRightContent}
    >
      <Media
        src={image?.url}
        type={image?.mimeType}
        alt={image?.altText}
        credit={image?.credit}
      />
      <div>
        <h2 className={classes.h3}>
          {context?.entry?.companySustainabilityFootprintHeading}
        </h2>
        <p className={classes.itemDescription}>
          {context?.entry?.companySustainabilityFootprintSubheading}
        </p>
        {context?.entry?.companySustainabilityFootprintItems?.map((item) => (
          <Fragment key={item?.uid}>
            <h3 className={classes.itemTitle}>{item?.itemTitle}</h3>
            <p className={classes.itemDescription}>{item?.itemDescription}</p>
          </Fragment>
        ))}
      </div>
    </LayoutContainer>
  )
}
