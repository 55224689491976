import React, { createContext } from 'react'
import { MediaHeader } from '../../components/MediaHeader'
import { SplitContentSection } from '../common-sections/SplitContentSection'
import { StoriesAndNewsSection } from '../common-sections/StoriesAndNewsSection'
import { CompanyTimelineSection } from './CompanyTimelineSection'
import { CompanyTopicsSection } from './CompanyTopicsSection'
import {
  CompanyPage as ICompanyPageQuery,
  CompanyPage_entry_company_company_Entry as CompanyPageEntry,
  CompanyPage_stories_stories_story_Entry as StoryPreviewEntry,
  CompanyPage_news_news_news_Entry as NewsPreviewEntry,
} from '../../api-types/CompanyPage'
import { classNames, fetchApiData } from '../../utils'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { useQuery } from 'react-query'
import treadSrc from '../../assets/patterns/Tread-Blue.svg'
import angleSrc from '../../assets/patterns/Angle-Orange.svg'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scale(-1, -1)',
    right: -80,
    bottom: -293,
  }),
  angle: theme.traits.angleBackground(angleSrc, {
    transform: 'scale(-1, -1)',
    right: 0,
    bottom: 0,
  }),
  splitContentContainer: {
    paddingTop: theme.spacing.XXXL,
    paddingBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing.XXL,
      paddingBottom: theme.spacing.XXL,
    },
  },
  firstContainer: {
    paddingTop: theme.spacing.XXXXL,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing.XXXL,
    },
  },
  lastContainer: {
    paddingBottom: theme.spacing.XXXXL,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing.XXXL,
    },
  },
}))

interface CompanyPageQuery extends ICompanyPageQuery {
  entry: CompanyPageEntry | null
  stories: Array<StoryPreviewEntry | null> | null
  news: Array<NewsPreviewEntry | null> | null
}

export const CompanyPageContext = createContext<CompanyPageQuery | undefined>(
  undefined,
)

export const CompanyPage: React.FC = () => {
  const classes = useStyles()

  const { data, isLoading } = useQuery('company', () =>
    fetchApiData<CompanyPageQuery>('company'),
  )

  const headerMedia = data?.entry?.heroImage?.find((img) => img != null)

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  if (isLoading) return <Loading />
  return (
    <CompanyPageContext.Provider value={data}>
      <Breadcrumbs />
      <MediaHeader
        heading={data?.entry?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <CompanyTopicsSection />
      <div className={isNotMobile ? classes.tread : ''}>
        <div className={isNotMobile ? classes.angle : ''}>
          {data?.entry?.contentWithMedia?.map((content, index, arr) => {
            return (
              <SplitContentSection
                contentWithMedia={content}
                key={content?.uid}
                contentSide={index % 2 === 0 ? 'left' : 'right'}
                className={classNames(
                  classes.splitContentContainer,
                  index === 0 && classes.firstContainer,
                  index === arr.length - 1 && classes.lastContainer,
                )}
              />
            )
          })}
        </div>
      </div>
      <CompanyTimelineSection />
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
    </CompanyPageContext.Provider>
  )
}
