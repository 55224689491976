import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { ThemeProvider } from 'react-jss'
import { theme, useGlobalStyles } from './theme'
import { HeaderNav } from './components/HeaderNav'
import { Footer } from './components/Footer'
/* TEMPORARILY DISABLED (UofQ)
import { UofQPage } from './pages/u-of-q/UofQPage'
import { UofQEventsPage } from './pages/u-of-q/events/UofQEventsPage'
import { UofQTrainingLibraryBrowsePage } from './pages/u-of-q/training-library/UofQTrainingLibraryBrowsePage'
import { UofQTrainingLibraryHowItWorksPage } from './pages/u-of-q/training-library/UofQTrainingLibraryHowItWorksPage'
import { UofQTechInstitutePage } from './pages/u-of-q/tech-institute/UofQTechInstitutePage'
import { UofQSearchResultsPage } from './pages/u-of-q/search/UofQSearchResultsPage'
import { UofQCourseDetailPage } from './pages/u-of-q/tech-institute/UofQCourseDetailPage'
*/
import { BrandsPage } from './pages/brands/BrandsPage'
import { BrandsDirectoryPage } from './pages/brands/BrandsDirectoryPage'
import { StoriesPage } from './pages/stories/StoriesPage'
import { StoryDetailPage } from './pages/stories/StoryDetailPage'
import { CareersPage } from './pages/careers/CareersPage'
import { NewsPage } from './pages/news/NewsPage'
import { CompanyPage } from './pages/company/CompanyPage'
import { CompanySustainabilityPage } from './pages/company/sustainability/CompanySustainabilityPage'
import { CompanyAdvocacyPage } from './pages/company/advocacy/CompanyAdvocacyPage'
import { CompanyDIPage } from './pages/company/diversity-inclusion/CompanyDIPage'
import { CompanyCulturePage } from './pages/company/culture/CompanyCulturePage'
import { DoingBusinessWithQPage } from './pages/doing-business-with-q/DoingBusinessWithQPage'
import { HomePage } from './pages/home/HomePage'
import { ContactPage } from './pages/contact/ContactPage'
import { BCorpPage } from './pages/company/BCorpPage'
import * as routes from './routes'
import { NewsDetailPage } from './pages/news/NewsDetailPage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import { ScholarshipsPage } from './pages/scholarships-grants/ScholarshipsPage'
import { CommunityGrantPage } from './pages/scholarships-grants/CommunityGrantPage'
import { CommunityGrantRecipientsPage } from './pages/scholarships-grants/CommunityGrantRecipientsPage'
import { HelmetProvider } from 'react-helmet-async'
import { Seo } from './components/Seo'
import { QueryClientErrorHandler } from './components/QueryClientErrorHandler'
import { ErrorPage } from './pages/ErrorPage'
import { InnerPage } from './pages/InnerPage'

const App: React.FC = () => {
  useGlobalStyles()

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientErrorHandler>
            {(error) => (
              <>
                <PageChangeHandler />
                <HeaderNav />
                <Seo />
                <main id="main">
                  {error ? (
                    <ErrorPage error={error as number} />
                  ) : (
                    <Switch>
                      <Route
                        path={routes.brandsDirectory}
                        component={BrandsDirectoryPage}
                      />
                      <Route path={routes.brands} component={BrandsPage} />
                      <Route
                        path={routes.companySustainabilityInner}
                        component={InnerPage}
                      />
                      <Route
                        path={routes.companySustainability}
                        component={CompanySustainabilityPage}
                      />
                      <Route
                        path={routes.companyAdvocacyInner}
                        component={InnerPage}
                      />
                      <Route
                        path={routes.companyAdvocacy}
                        component={CompanyAdvocacyPage}
                      />
                      <Route
                        path={routes.companyDiversityInclusionInner}
                        component={InnerPage}
                      />
                      <Route
                        path={routes.companyDiversityInclusion}
                        component={CompanyDIPage}
                      />
                      <Route
                        path={routes.companyCultureInner}
                        component={InnerPage}
                      />
                      <Route
                        path={routes.companyCulture}
                        component={CompanyCulturePage}
                      />
                      <Route path={routes.company} component={CompanyPage} />

                      <Route
                        path={routes.scholarships}
                        component={ScholarshipsPage}
                      />
                      <Route
                        path={routes.communityGrantRecipient}
                        component={CommunityGrantRecipientsPage}
                      />
                      <Route
                        path={routes.communityGrant}
                        component={CommunityGrantPage}
                      />
                      <Route
                        path={routes.communityGrantFAQ}
                        component={CommunityGrantPage}
                      />
                      {/* TEMPORARILY DISABLED (UofQ)
                      <Route
                        path={routes.uOfQEvents}
                        component={UofQEventsPage}
                      />
                      <Route
                        path={routes.uOfQTrainingLibrary}
                        component={UofQTrainingLibraryBrowsePage}
                      />
                      <Route
                        path={routes.uOfQTrainingLibraryHowItWorks}
                        component={UofQTrainingLibraryHowItWorksPage}
                      />
                      <Route
                        path={routes.uOfQInstitute}
                        component={UofQTechInstitutePage}
                      />
                      <Route
                        path={routes.uOfQCourseDetail}
                        component={UofQCourseDetailPage}
                      />
                      <Route
                        path={routes.uOfQSearch}
                        component={UofQSearchResultsPage}
                      />
                      <Route path={routes.uOfQ} component={UofQPage} />
                      */}
                      <Route
                        path={routes.storyCategory}
                        component={StoriesPage}
                      />
                      <Route
                        path={routes.storyDetail}
                        component={StoryDetailPage}
                      />
                      <Route path={routes.stories} component={StoriesPage} />
                      <Route
                        path={routes.newsDetail}
                        component={NewsDetailPage}
                      />
                      <Route path={routes.news} component={NewsPage} />
                      <Route path={routes.careers} component={CareersPage} />
                      <Route
                        path={routes.doingBusinessWithQ}
                        component={DoingBusinessWithQPage}
                      />
                      <Route path={routes.contact} component={ContactPage} />
                      <Route path={routes.bCorp} component={BCorpPage} />
                      <Route
                        path={routes.privacyPolicy}
                        component={PrivacyPolicyPage}
                      />
                      <Route path={routes.home} component={HomePage} />
                    </Switch>
                  )}
                </main>
                <Footer />
              </>
            )}
          </QueryClientErrorHandler>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  )
}

interface ScrollState {
  scrollToTop: boolean
}

/** Performs per-page actions */
const PageChangeHandler: React.FC = () => {
  const { pathname, state } = useLocation<ScrollState>()
  const { action } = useHistory()

  // start at the top of each page
  useEffect(() => {
    if (action === 'PUSH' && state?.scrollToTop !== false) {
      window.scrollTo({ top: 0 })
    }
  }, [pathname, action, state])

  return null
}

export default App
