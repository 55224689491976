import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  bodyContent: {
    ...theme.typography.body,
    marginBottom: theme.spacing.XXL,
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing.M,
    },
    '& h1': {
      ...theme.typography.h3,
    },
    '& h2': {
      ...theme.typography.h4,
    },
    '& h3': {
      ...theme.typography.h5,
    },
    '& h4': {
      ...theme.typography.bodyEm,
    },
    '& h5': {
      ...theme.typography.overline,
      color: theme.colors.blue,
    },
    '& p': {
      color: theme.kitColors.grey700,
    },
    '& a': {
      ...theme.typography.menuLink,
      ...theme.traits.clickable,
      textDecoration: 'none',
      color: theme.colors.blue,
    },
    '& ul, ol': {
      color: theme.kitColors.grey700,
      marginLeft: theme.spacing.XL,
    },
    '& ul': {
      listStyleType: 'disc',
    },
    '& ol': {
      listStyleType: 'decimal',
    },
    '& figure': {
      margin: 0,
    },
    '& img': {
      maxWidth: '100%',
    },
    '& blockquote': {
      ...theme.typography.h5,
      margin: 0,
      padding: `${theme.spacing.M}px ${theme.spacing.L}px`,
      color: theme.kitColors.white,
      '&:nth-of-type(6n - 5)': {
        backgroundColor: theme.colors.raisin,
      },
      '&:nth-of-type(6n - 4)': {
        backgroundColor: theme.colors.orange,
      },
      '&:nth-of-type(6n - 3)': {
        backgroundColor: theme.colors.navy,
      },
      '&:nth-of-type(6n - 2)': {
        backgroundColor: theme.colors.pink,
        color: theme.colors.navy,
      },
      '&:nth-of-type(6n - 1)': {
        backgroundColor: theme.colors.teal,
      },
      '&:nth-of-type(6n)': {
        backgroundColor: theme.colors.marigold,
        color: theme.colors.navy,
      },
    },
  },
}))

type Props = { html: string; className?: string; contentId?: string }

export const BodyContent: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.bodyContent, props.className)}
      dangerouslySetInnerHTML={{ __html: props.html }}
      id={props.contentId}
    />
  )
}
