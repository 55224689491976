import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../../components/LayoutContainer'
import { Theme } from '../../../theme'
import { classNames } from '../../../utils'
import { CompanyAdvocacyPageContext } from './CompanyAdvocacyPage'
import { Media } from '../../../components/Media'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.kitColors.grey100,
  },
  item: {
    alignItems: 'center',
    '& > a': {
      marginTop: 'auto',
    },
  },
  itemImage: {
    '& > * img': {
      height: 80,
      objectFit: 'contain',
    },
    width: '100%',
    marginBottom: theme.spacing.XXL,
  },
  itemTitle: {
    ...theme.typography.h3,
    textAlign: 'center',
    marginBottom: theme.spacing.S,
  },
  itemDescription: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    textAlign: 'center',
    marginBottom: theme.spacing.XXL,
  },
}))

export const CompanyAdvocacyPartnersSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CompanyAdvocacyPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classNames(
        layoutClasses.paddingBetweenItemsSpacingXXXL,
        layoutClasses.indentRow28px,
        layoutClasses.threeItemLayout,
      )}
    >
      {context?.entry?.companyAdvocacyPartners?.map((item) => {
        const media = item?.partnerMedia?.find((item) => item != null)
        return (
          <div className={classes.item} key={item?.uid}>
            <div className={classes.itemImage}>
              <Media
                src={media?.url}
                type={media?.mimeType}
                alt={media?.altText}
                credit={media?.credit}
              />
            </div>
            <h2 className={classes.itemTitle}>{item?.partnerTitle}</h2>
            <p className={classes.itemDescription}>{item?.description}</p>
            <ArrowLink
              to={{ pathname: item?.externalLink ?? '' }}
              target="_blank"
            >
              Go To Website
            </ArrowLink>
          </div>
        )
      })}
    </LayoutContainer>
  )
}
