import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../../components/LayoutContainer'
import { Theme } from '../../../theme'
import { classNames } from '../../../utils'
import { CompanyDIPageContext } from './CompanyDIPage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.navy,
  },
  content: {
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  headerContainer: {
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
  },
  contentItemsContainer: {
    gridColumn: '1 / -1',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:not(:last-child)': {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing.XXXL,
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing.XXL,
      },
    },
  },
  heading: {
    ...theme.typography.h3,
    color: theme.colors.teal,
    textAlign: 'center',
    marginBottom: theme.spacing.M,
  },
  subHeading: {
    ...theme.typography.body,
    textAlign: 'center',
    color: theme.kitColors.grey200,
  },
  contentItemHeading: {
    ...theme.typography.h5,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.S,
  },
  contentItemParagraph: {
    ...theme.typography.smallBody,
    color: theme.kitColors.grey200,
    marginBottom: theme.spacing.XXL,
  },
  link: {
    marginTop: 'auto',
    color: theme.kitColors.white,
    '& > svg': {
      stroke: theme.kitColors.white,
    },
  },
}))

export const CompanyDIPledgingChangeSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CompanyDIPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classNames(layoutClasses.gridLayout, classes.content)}
    >
      <div className={classes.headerContainer}>
        <h2 className={classes.heading}>
          {context?.entry?.companyDiPledgeHeading}
        </h2>
        <p className={classes.subHeading}>
          {context?.entry?.companyDiPledgeSubheading}
        </p>
      </div>
      <div className={classes.contentItemsContainer}>
        {context?.entry?.companyDiPledges?.map((pledge) => (
          <div className={classes.contentItem} key={pledge?.uid}>
            <h3 className={classes.contentItemHeading}>
              {pledge?.pledgeTitle}
            </h3>
            <p className={classes.contentItemParagraph}>
              {pledge?.pledgeDescription}
            </p>
            <ArrowLink
              className={classes.link}
              to={{ pathname: pledge?.externalLink ?? '' }}
              target="_blank"
            >
              Learn More
            </ArrowLink>
          </div>
        ))}
      </div>
    </LayoutContainer>
  )
}
