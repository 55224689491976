import React from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import { Theme } from '../../theme'
import { format, parseJSON } from 'date-fns'
import { classNames } from '../../utils'
import { newsDetail } from '../../routes'

const useStyles = createUseStyles((theme: Theme) => ({
  newsItem: {
    borderTop: `2px solid ${theme.colors.orange}`,
    paddingTop: theme.spacing.M,

    '&:not(:last-child)': {
      marginBottom: theme.spacing.XXL,
    },
  },
  newsItemOverline: {
    ...theme.typography.overline,
    marginBottom: theme.spacing.S,
    color: theme.kitColors.grey600,
    '& > span:first-child': {
      color: theme.colors.blue,
      marginRight: theme.spacing.S,
    },
  },
  newsItemTitle: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.M,
  },
}))

export const NewsPreview: React.FC<{
  category?: string | null
  date?: string | null
  headline?: string | null
  slug?: string | null
  className?: string
  as?: keyof JSX.IntrinsicElements
}> = (props) => {
  const classes = useStyles()

  const dateString = props.date
    ? format(parseJSON(props.date), 'LLLL d')
    : undefined

  const Container = props.as ?? 'div'

  if (!props.slug) return null

  return (
    <Container
      className={classNames(classes.newsItem, props.className)}
      role="group"
      aria-label="press release"
    >
      <p className={classes.newsItemOverline}>
        <span>{props.category}</span>
        <span>{dateString}</span>
      </p>
      <h3 className={classes.newsItemTitle}>{props.headline}</h3>
      <ArrowLink to={newsDetail.replace(':slug', props.slug)}>
        Read More
      </ArrowLink>
    </Container>
  )
}
