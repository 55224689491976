import React, { createContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Theme } from '../../../theme'
import { SplitContentSection } from '../../common-sections/SplitContentSection'
import { StoriesAndNewsSection } from '../../common-sections/StoriesAndNewsSection'
import { SubPageHeader } from '../../common-sections/SubPageHeader'
import { CompanyDIPledgingChangeSection } from './CompanyDIPledgingChangeSection'
import {
  CompanyDiversityAndInclusionPage as ICompanyDIPageQuery,
  CompanyDiversityAndInclusionPage_entry_companyDiversityAndInclusion_companyDiversityAndInclusion_Entry as CompanyDIPageEntry,
  CompanyDiversityAndInclusionPage_stories_stories_story_Entry as StoryPreviewEntry,
  CompanyDiversityAndInclusionPage_news_news_news_Entry as NewsPreviewEntry,
} from '../../../api-types/CompanyDiversityAndInclusionPage'
import { fetchApiData } from '../../../utils'
import { useQuery } from 'react-query'
import treadSrc from '../../../assets/patterns/Tread-Raisin.svg'
import angleSrc from '../../../assets/patterns/Angle-Orange.svg'
import { Breadcrumbs } from '../../../components/Breadcrumbs'
import { Loading } from '../../../components/Loading'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scaleY(-1)',
    left: -80,
    bottom: -328,
  }),
  angle: theme.traits.angleBackground(angleSrc, {
    transform: 'scaleY(-1)',
    left: 0,
    bottom: 0,
  }),
}))

interface CompanyDIPageQuery extends ICompanyDIPageQuery {
  entry: CompanyDIPageEntry | null
  stories: Array<StoryPreviewEntry | null> | null
  news: Array<NewsPreviewEntry | null> | null
}

export const CompanyDIPageContext = createContext<
  CompanyDIPageQuery | undefined
>(undefined)

export const CompanyDIPage: React.FC = () => {
  const theme = useTheme<Theme>()
  const classes = useStyles()

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  const { data, isLoading } = useQuery('company/diversityandinclusion', () =>
    fetchApiData<CompanyDIPageQuery>('companydiversityandinclusion'),
  )

  if (isLoading) return <Loading />
  return (
    <CompanyDIPageContext.Provider value={data}>
      <Breadcrumbs />
      <SubPageHeader
        heading={data?.entry?.pageHeading}
        subheading={data?.entry?.subheading}
        headingBackgroundColor={theme.colors.teal}
      />
      <div className={isNotMobile ? classes.tread : ''}>
        <div className={isNotMobile ? classes.angle : ''}>
          {data?.entry?.contentWithMedia?.map((content, index, arr) => {
            const isEvenIndex = index % 2 === 0

            return (
              <SplitContentSection
                key={content?.uid}
                contentWithMedia={content}
                darkMode={index === 1}
                contentSide={isEvenIndex ? 'left' : 'right'}
                backgroundColor={
                  index === 1
                    ? theme.colors.green
                    : isEvenIndex && index > 0
                    ? theme.kitColors.grey100
                    : undefined
                }
              />
            )
          })}
        </div>
      </div>
      <CompanyDIPledgingChangeSection />
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
    </CompanyDIPageContext.Provider>
  )
}
