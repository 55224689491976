import React, { useContext } from 'react'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { SectionParagraphGrid } from '../../components/SectionParagraphGrid'
import { Media } from '../../components/Media'
import { DoingBusinessWithQPageContext } from './DoingBusinessWithQPage'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  image: {
    height: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

export const DoingBusinessWithQDistributionSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(DoingBusinessWithQPageContext)
  const media = context?.entry?.distributionSectionMedia?.find(
    (img) => img != null,
  )

  return (
    <LayoutContainer contentClass={layoutClasses.splitLayoutLeftContent}>
      <SectionParagraphGrid
        heading={context?.entry?.distributionSectionHeading}
        description={context?.entry?.distributionSectionDescription}
        content={context?.entry?.distributionSectionItems}
      />
      <Media
        src={media?.url}
        type={media?.mimeType}
        alt={media?.altText}
        credit={media?.credit}
        className={classes.image}
      />
    </LayoutContainer>
  )
}
