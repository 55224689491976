import React, { createContext } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Theme } from '../../../theme'
import { SplitContentSection } from '../../common-sections/SplitContentSection'
import { StoriesAndNewsSection } from '../../common-sections/StoriesAndNewsSection'
import { SubPageHeader } from '../../common-sections/SubPageHeader'
import { CompanyCultureValuesSection } from './CompanyCultureValuesSection'
import {
  CompanyCulturePage as CompanyCulturePageQuery,
  CompanyCulturePage_entry_companyCulture_companyCulture_Entry as CompanyCulturePageEntry,
  CompanyCulturePage_stories_stories_story_Entry as StoryPreviewEntry,
  CompanyCulturePage_news_news_news_Entry as NewsPreviewEntry,
} from '../../../api-types/CompanyCulturePage'
import { fetchApiData } from '../../../utils'
import { useQuery } from 'react-query'
import treadSrc from '../../../assets/patterns/Tread-Raisin.svg'
import angleSrc from '../../../assets/patterns/Angle-Orange.svg'
import { Breadcrumbs } from '../../../components/Breadcrumbs'
import { Loading } from '../../../components/Loading'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scaleY(-1)',
    left: -80,
    bottom: -328,
  }),
  content: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.angleBackground(angleSrc, {
        transform: 'scaleY(-1)',
        left: 0,
        bottom: 0,
      }),
    },
    '& > section:not(:last-child)': {
      paddingBottom: theme.spacing.XXL,
    },
  },
}))
interface CompanyCulturePageData extends CompanyCulturePageQuery {
  entry: CompanyCulturePageEntry | null
  stories: Array<StoryPreviewEntry | null> | null
  news: Array<NewsPreviewEntry | null> | null
}

export const CompanyCulturePageContext = createContext<
  CompanyCulturePageData | undefined
>(undefined)

export const CompanyCulturePage: React.FC = () => {
  const theme = useTheme<Theme>()
  const classes = useStyles()
  const { data, isLoading } = useQuery('company/culture', () =>
    fetchApiData<CompanyCulturePageData>('companyculture'),
  )

  const arr = data?.entry?.contentWithMedia ?? []
  const topMedia = data?.entry?.contentWithMedia?.slice(0, 2) ?? []
  const lastBlock = arr.length > 0 ? arr[arr.length - 1] : undefined
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  if (isLoading) return <Loading />
  return (
    <CompanyCulturePageContext.Provider value={data}>
      <Breadcrumbs />
      <SubPageHeader
        heading={data?.entry?.pageHeading}
        subheading={data?.entry?.subheading}
        headingBackgroundColor={theme.colors.orange}
      />
      <div className={isNotMobile ? classes.tread : ''}>
        <div className={classes.content}>
          {topMedia.map((content, index) => (
            <SplitContentSection
              key={content?.uid}
              contentWithMedia={content}
              contentSide={index % 2 === 0 ? 'left' : 'right'}
            />
          ))}
        </div>
      </div>
      <CompanyCultureValuesSection />
      <SplitContentSection
        contentWithMedia={lastBlock}
        contentSide={'right'}
        darkMode
        backgroundColor={theme.colors.green}
      />
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
    </CompanyCulturePageContext.Provider>
  )
}
