import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { theme, Theme } from '../../theme'
import { classNames } from '../../utils'
import { BrandsPageContext } from './BrandsPage'
import Slider from 'react-slick'
import * as routes from '../../routes'
import treadSrc from '../../assets/patterns/Tread-Gray.svg'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  none: {
    display: 'none',
  },
  heading: {
    ...theme.typography.h4,
    textAlign: 'center',
  },
  subheading: {
    ...theme.typography.body,
    textAlign: 'center',
    color: theme.kitColors.grey700,
    marginTop: theme.spacing.M,
  },
  gridHeading: {
    gridColumn: '3 / span 8',
    [theme.breakpoints.down('md')]: {
      gridColumn: '2 / -2',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
    },
  },
  sliderContainer: {
    marginTop: theme.spacing.XXXL,
    '&& .slick-list': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80%',
        margin: '0px auto',
      },
    },
    '&& .slick-next, .slick-prev': {
      width: '100px',
      height: '100px',
      zIndex: '1',
      top: 'calc(50% - 3px)',
    },
    '&& .slick-next:before, .slick-prev:before': {
      display: 'none',
    },
    '&& .slick-next:focus, .slick-next:active, .slick-prev:focus, .slick-prev:active': {
      '& svg': {
        outline: '2px dotted black',
      },
    },
    '&& .slick-dots': {
      bottom: '-50px',
    },
    '&& .slick-dots li': {
      margin: '0 16px',
    },
    '&& .slick-dots li button': {
      border: 'none',
    },
    '&& .slick-dots li button:before': {
      fontSize: '20px',
      lineHeight: 'normal',
      border: 'none',
      color: '#A8A8DD',
      opacity: '100',
    },
    '&& .slick-dots li button:before:focus, .slick-dots li button:before:active': {
      outline: '2px dotted black',
      borderRadius: '10px',
      border: 'none',
    },
    '&& .slick-dots li.slick-active button:before': {
      color: theme.colors.blue,
      borderRadius: '10px',
      opacity: '100',
    },
    '&& .slick-dots li button:focus': {
      outline: 'none',
      border: 'none',
      '&:before': {
        borderRadius: '10px',
        outline: '2px dotted black',
      },
    },
  },
  logoSlide: {
    width: 120,
    height: 104,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      height: '100%',
      margin: '0 auto',
      objectFit: 'contain',
    },
  },
  paddedContainer: {
    marginTop: 70,
    paddingTop: theme.spacing.XXL,
    paddingBottom: theme.spacing.XXL,
  },
  statsContainer: {
    paddingTop: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing.XXL,
    },
  },
  linkContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  statsContent: {
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  statsItem: {
    gridColumn: 'span 4',
    '& > div': {
      ...theme.typography.h1,
      textAlign: 'center',
    },
    '& > p': {
      ...theme.typography.bodyEm,
      color: theme.kitColors.grey800,
      textAlign: 'center',
    },
  },
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scale(-1, -1)',
    right: -80,
    bottom: -421,
  }),
}))

export const BrandsShowcaseSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(BrandsPageContext)
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  return (
    <section
      className={[
        layoutClasses.paddedContainerLarge,
        isNotMobile ? classes.tread : '',
      ].join(' ')}
    >
      <LayoutContainer
        ContainerElement="div"
        contentClass={layoutClasses.gridLayout}
      >
        <div className={classes.gridHeading}>
          <h2 className={classes.heading}>
            {context?.entry?.brandCarouselHeading}
          </h2>
          {context?.entry?.brandCarouselSubheading && (
            <p className={classes.subheading}>
              {context?.entry?.brandCarouselSubheading}
            </p>
          )}
        </div>
      </LayoutContainer>
      <div role="region" aria-label="carousel">
        <div className={classes.sliderContainer}>
          <div className={[classes.none, classes.srOnly].join(' ')}>
            This is a carousel. Click Next/Previous buttons or a slide dot
            button to jump to a slide.
          </div>
          <Slider
            infinite
            accessibility={false}
            arrows={true}
            nextArrow={
              <button>
                <span className={classes.srOnly}>Next</span>
                <FeatherIcon name={'chevron-right'} color={theme.colors.blue} />
              </button>
            }
            prevArrow={
              <button>
                <span className={classes.srOnly}>Previous</span>
                <FeatherIcon name={'chevron-left'} color={theme.colors.blue} />
              </button>
            }
            swipe
            customPaging={(i) => <button>Go to slide {i + 1}</button>}
            slidesToShow={5}
            autoplay={false}
            centerMode={true}
            dots={true}
            adaptiveHeight={true}
            responsive={[
              {
                breakpoint: 1125,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {context?.entry?.brandLogoCarousel?.map((logo, index) => (
              <div
                className={classes.logoSlide}
                key={index}
                role="group"
                aria-label={`slide ${index + 1} of 6`}
              >
                <img src={logo?.url ?? ''} alt={logo?.altText ?? 'logo'} />
                <span className={classes.srOnly}>{logo?.altText}</span>
              </div>
            ))}
          </Slider>
        </div>
        <LayoutContainer
          ContainerElement="div"
          containerClass={classes.paddedContainer}
          contentClass={classes.linkContent}
        >
          <ArrowLink to={routes.brandsDirectory}>View All Partners</ArrowLink>
        </LayoutContainer>
      </div>
      <LayoutContainer
        ContainerElement="div"
        containerClass={classes.statsContainer}
        contentClass={classNames(
          layoutClasses.gridLayout,
          classes.statsContent,
        )}
      >
        {context?.entry?.statsList?.map((stat) => (
          <div className={classes.statsItem} key={stat?.uid}>
            <div>{stat?.statValue}</div>
            <p>{stat?.statUnit}</p>
          </div>
        ))}
      </LayoutContainer>
    </section>
  )
}
