import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

export const LayoutContainer: React.FC<{
  containerClass?: string
  contentClass?: string
  ContainerElement?: keyof JSX.IntrinsicElements
  ContentElement?: keyof JSX.IntrinsicElements
}> = (props) => {
  const { ContainerElement = 'section', ContentElement = 'div' } = props
  const classes = useLayoutStyles()

  return (
    <ContainerElement
      className={classNames(classes.layoutContainerOuter, props.containerClass)}
    >
      <ContentElement
        className={classNames(classes.layoutContainerInner, props.contentClass)}
      >
        {props.children}
      </ContentElement>
    </ContainerElement>
  )
}

export const useLayoutStyles = createUseStyles((theme: Theme) => {
  const gridLayout = {
    display: 'grid',
    gridTemplateColumns: `repeat(${theme.grid.columns}, 1fr)`,
    columnGap: theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(${theme.grid.columnsTablet}, 1fr)`,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: `repeat(${theme.grid.columnsMobile}, 1fr)`,
      columnGap: theme.spacing.M,
    },
  }

  return {
    layoutContainerOuter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    layoutContainerInner: {
      width: '100%',
      maxWidth: theme.measurements.contentMaxWidth,
      paddingLeft: theme.measurements.contentPadding,
      paddingRight: theme.measurements.contentPadding,
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.measurements.contentPaddingTablet,
        paddingRight: theme.measurements.contentPaddingTablet,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.measurements.contentPaddingMobile,
        paddingRight: theme.measurements.contentPaddingMobile,
      },
    },
    /** Encodes the grid used by the design system */
    gridLayout,
    sidebarLayout: {
      ...gridLayout,
      zIndex: 1,
      '& > *:first-child': {
        gridColumn: 'span 3',
        [theme.breakpoints.down('md')]: {
          gridColumn: 'span 2',
        },
        [theme.breakpoints.down('sm')]: {
          gridColumn: 'span 4',
          paddingBottom: theme.spacing.XXL,
        },
      },
      '& > *:not(:first-child)': {
        gridColumn: 'span 9',
        [theme.breakpoints.down('md')]: {
          gridColumn: 'span 6',
        },
      },
    },
    // The responsive split layout variant with the content on left, media on right
    splitLayoutLeftContent: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: theme.spacing.L,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing.XXL,
      },
      [theme.breakpoints.down('sm')]: {
        rowGap: theme.spacing.XL,
      },
      '& > *:first-child': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          paddingRight: theme.spacing.XXXL - theme.spacing.L,
        },
      },
      '& > *:last-child': {
        [theme.breakpoints.down('md')]: {
          gridRow: 1,
        },
      },
    },
    splitLayoutRightContent: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: theme.spacing.L,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing.XXL,
      },
      [theme.breakpoints.down('sm')]: {
        rowGap: theme.spacing.XL,
      },
      '& > *:first-child': {
        [theme.breakpoints.down('md')]: {
          gridRow: 1,
        },
      },
      '& > *:last-child': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing.XXXL - theme.spacing.L,
        },
      },
    },
    thirdsLayoutLeftContent: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: theme.spacing.L,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing.XXL,
      },
      [theme.breakpoints.down('sm')]: {
        rowGap: theme.spacing.XL,
      },
      '& > *:first-child': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 2',
          paddingRight: theme.spacing.XXXL - theme.spacing.L,
        },
      },
    },

    thirdsLayoutRightContent: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: theme.spacing.L,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        rowGap: theme.spacing.XXL,
      },
      [theme.breakpoints.down('sm')]: {
        rowGap: theme.spacing.XL,
      },
      '& > *:first-child': {
        [theme.breakpoints.down('md')]: {
          gridRow: 1,
        },
      },
      '& > *:last-child': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          gridColumn: 'span 2',
          paddingLeft: theme.spacing.XXXL - theme.spacing.L,
        },
      },
    },
    paddedContainer: {
      paddingTop: theme.spacing.XXXL,
      paddingBottom: theme.spacing.XXXL,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing.XXL,
        paddingBottom: theme.spacing.XXL,
      },
    },
    paddedContainerLarge: {
      paddingTop: theme.spacing.XXXXL,
      paddingBottom: theme.spacing.XXXXL,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing.XXXL,
        paddingBottom: theme.spacing.XXXL,
      },
    },
    threeItemLayout: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      '& > *': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up('md')]: {
          '&:not(:last-child)': {
            marginRight: theme.spacing.L,
          },
        },
        [theme.breakpoints.down('md')]: {
          marginBottom: theme.spacing.XXL,
          '&:last-child': {
            marginBottom: theme.spacing.M,
          },
        },
      },
    },
    paddingBetweenItemsSpacingXXXL: {
      '& > *': {
        [theme.breakpoints.up('md')]: {
          '&:not(:first-child)': {
            marginLeft: theme.spacing.L + theme.spacing.XL,
          },
        },
      },
    },
    indentRow28px: {
      '& > *': {
        [theme.breakpoints.up('md')]: {
          '&:first-child': {
            marginLeft: (theme.spacing.XXXL - theme.spacing.L) / 2,
          },
          '&:last-child': {
            marginRight: (theme.spacing.XXXL - theme.spacing.L) / 2,
          },
        },
      },
    },
  }
})
