import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { ContactUsPageContext } from './ContactPage'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { useMediaQuery } from 'react-responsive'
const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scaleX(-1)',
    right: -136,
    bottom: -178,
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  h3: {
    ...theme.typography.h3,
  },
  contactsContainer: {
    columnGap: theme.spacing.XXXL,
    rowGap: theme.spacing.XXXL,
    marginTop: theme.spacing.XXL,
    marginLeft: (theme.spacing.XXXL - theme.spacing.L) / 2,
    marginRight: (theme.spacing.XXXL - theme.spacing.L) / 2,
    '& > *': {
      borderTop: [2, 'solid', theme.colors.orange],
      gridColumn: 'span 4',
    },
    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing.XXL,
      rowGap: theme.spacing.XXL,
    },
  },
  itemHeading: {
    ...theme.typography.h5,
    gridColumn: '1 / span 2',
    paddingTop: theme.spacing.M,
  },
  itemDescription: {
    ...theme.typography.menuLink,
    color: theme.kitColors.grey700,
    display: 'flex',
    flexDirection: 'column',
    '& > a': {
      ...theme.typography.menuLink,
      textDecoration: 'none',
      color: theme.kitColors.grey700,
    },
    '& > *:first-child': {
      color: theme.colors.blue,
    },
  },
  iconTextContainer: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    rowGap: theme.spacing.M,
    columnGap: theme.spacing.S,
    gridAutoRows: 'min-content',
  },
  detail: {
    paddingTop: theme.spacing.S,
    color: theme.kitColors.grey700,
  },
  icon: {
    marginTop: theme.spacing.XS / 2,
    stroke: theme.colors.blue,
  },
}))

export const ContactSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(ContactUsPageContext)
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  return (
    <LayoutContainer
      containerClass={[
        layoutClasses.paddedContainerLarge,
        isNotMobile ? classes.tread : '',
      ].join(' ')}
      contentClass={classes.content}
    >
      <h2 className={classes.h3}>{context?.entry?.contactHeading}</h2>
      <div
        className={classNames(
          layoutClasses.gridLayout,
          classes.contactsContainer,
        )}
      >
        {context?.entry?.contacts?.map((contact) => {
          const details = contact?.details?.split('\n') ?? []
          return (
            <article className={classes.iconTextContainer} key={contact?.uid}>
              <h3 className={classes.itemHeading}>{contact?.contactName}</h3>
              {contact?.phoneNumber && (
                <>
                  <a
                    href={
                      'tel:+' + (contact?.phoneNumber).replace(/-/g, '') ?? ''
                    }
                  >
                    <FeatherIcon name={'phone'} className={classes.icon} />
                  </a>
                  <p className={classes.itemDescription}>
                    <a
                      href={
                        'tel:+' + (contact?.phoneNumber).replace(/-/g, '') ?? ''
                      }
                    >
                      {contact?.phoneNumber}
                    </a>
                    {details.map((detail, i) => (
                      <span className={classes.detail} key={i}>
                        {detail}
                      </span>
                    ))}
                  </p>
                </>
              )}

              {contact?.email && (
                <>
                  <a href={'mailto:' + contact?.email ?? ''}>
                    <FeatherIcon name={'mail'} className={classes.icon} />
                  </a>
                  <p className={classes.itemDescription}>
                    <a href={'mailto:' + contact?.email ?? ''}>
                      {contact?.email}
                    </a>
                  </p>
                </>
              )}
            </article>
          )
        })}
      </div>
    </LayoutContainer>
  )
}
