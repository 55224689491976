import React from 'react'
import { createUseStyles } from 'react-jss'
import { useQuery } from 'react-query'
import { LayoutContainer, useLayoutStyles } from '../components/LayoutContainer'
import { Theme } from '../theme'
import { classNames, fetchApiData } from '../utils'
import {
  PrivacyPolicyPage as IPrivacyPolicyPageQuery,
  PrivacyPolicyPage_entry_privacyPolicy_privacyPolicy_Entry as PrivacyPolicyEntry,
} from '../api-types/PrivacyPolicyPage'
import treadSrc from '../assets/patterns/Tread-White.svg'
import { BodyContent } from '../components/BodyContent'
import { Breadcrumbs } from '../components/Breadcrumbs'
import { Loading } from '../components/Loading'

interface PrivacyPolicyPageQuery extends IPrivacyPolicyPageQuery {
  entry: PrivacyPolicyEntry | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        left: -92,
        top: -383,
      }),
    },
  },
  content: {
    gridColumn: '3 / span 8',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
    '& a': {
      fontSize: 18,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
  heading: {
    ...theme.typography.h1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  headingLayout: {
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.XXL,
    },
  },
}))

export const PrivacyPolicyPage: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const { data, isLoading } = useQuery('privacy-policy', () =>
    fetchApiData<PrivacyPolicyPageQuery>('privacy-policy'),
  )

  if (isLoading) return <Loading />
  return (
    <>
      <Breadcrumbs />
      <LayoutContainer
        containerClass={classNames(
          layoutClasses.paddedContainerLarge,
          classes.container,
        )}
      >
        {data && (
          <div className={classes.content}>
            <h1 className={classNames(classes.headingLayout, classes.heading)}>
              {data.entry?.title}
            </h1>
            <BodyContent html={data.entry?.bodyContent ?? ''} />
          </div>
        )}
      </LayoutContainer>
    </>
  )
}
