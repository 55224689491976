import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { HomePageContext } from './HomePage'
import { Media } from '../../components/Media'
import { brands } from '../../routes'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import angleSrc from '../../assets/patterns/Angle-White.svg'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        backgroundColor: theme.colors.navy,
        top: -320,
        left: -60,
        opacity: 0.3,
      }),
    },
  },
  containerNoBG: {
    backgroundColor: theme.colors.navy,
  },
  angle: theme.traits.angleBackground(angleSrc, {
    left: 0,
    top: 0,
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  heading: {
    ...theme.typography.h3,
    color: theme.kitColors.white,
    marginTop: theme.spacing.XXL,
    marginBottom: theme.spacing.M,
  },
  text: {
    ...theme.typography.body,
    color: theme.colors.lightGray,
    marginBottom: theme.spacing.XXL,
  },
  linkRow: {
    display: 'flex',
    '& > *:first-child': {
      marginRight: theme.spacing.XXL,
    },
  },
  link: {
    color: theme.kitColors.white,
    '& > svg': {
      stroke: theme.kitColors.white,
    },
  },
  logoImage: {
    width: 197,
    maxWidth: '100%',
  },
}))

export const HomeProductSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(HomePageContext)
  const media = context?.entry?.productSectionMedia?.find(
    (media) => media != null,
  )
  const productLogo = context?.entry?.productSectionBrandLogoImage?.find(
    (img) => img != null,
  )

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  return (
    <LayoutContainer
      containerClass={[
        layoutClasses.paddedContainerLarge,
        isNotMobile ? classes.container : classes.containerNoBG,
      ].join(' ')}
      contentClass={[
        layoutClasses.thirdsLayoutLeftContent,
        isNotMobile ? classes.angle : '',
      ].join(' ')}
    >
      <Media
        src={media?.url}
        type={media?.mimeType}
        alt={media?.altText}
        credit={media?.credit}
      />
      <div className={classes.content}>
        <img
          src={productLogo?.url ?? ''}
          alt={productLogo?.altText ?? ''}
          className={classes.logoImage}
        />
        <h2 className={classes.heading}>
          {context?.entry?.productSectionHeading}
        </h2>
        <p className={classes.text}>
          {context?.entry?.productSectionDescription}
        </p>
        <div className={classes.linkRow}>
          <ArrowLink
            to={{ pathname: context?.entry?.productSectionLink ?? '' }}
            target="_blank"
            className={classes.link}
          >
            Learn More
          </ArrowLink>
          <ArrowLink to={brands} className={classes.link}>
            Our Brands
          </ArrowLink>
        </div>
      </div>
    </LayoutContainer>
  )
}
