import React from 'react'
import { LayoutContainer } from '../components/LayoutContainer'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import errorIcon from '../components/icons/ErrorIcon@2x.png'
import { Button } from '../components/Button'

const useStyles = createUseStyles((theme: Theme) => ({
  content: {
    paddingTop: theme.spacing.XXXXL,
    paddingBottom: theme.spacing.XXXXL,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span': {
      ...theme.typography.smallBody,
      color: theme.kitColors.grey700,
    },
  },
  errorMessage: {
    ...theme.typography.h3,
    color: theme.colors.navy,
    paddingBottom: theme.spacing.S,
  },
  icon: {
    width: 120,
    height: 120,
    marginBottom: theme.spacing.L,
  },
  button: {
    marginTop: theme.spacing.XXL,
  },
}))

export const ErrorPage: React.FC<{ error: number }> = ({ error }) => {
  const classes = useStyles()
  const messages = new Array<string>()
  messages[500] = 'Oops, Something went wrong'
  messages[404] = 'Page not found'

  return (
    <LayoutContainer contentClass={classes.content}>
      <img
        className={classes.icon}
        src={errorIcon}
        alt={'Flat Tire Error Icon'}
      />
      <h1 className={classes.errorMessage}>
        {messages[error] ?? 'Oops, Something went wrong'}
      </h1>
      <div>
        {/* Insert Error message below */}
        <span></span>
      </div>
      <a href={'/'}>
        <Button className={classes.button}>Go Home</Button>
      </a>
    </LayoutContainer>
  )
}
