import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    ...theme.traits.clickable,
    ...theme.typography.button,
    padding: '4px 14px',
    borderStyle: 'solid',
    borderRadius: 2,
    borderWidth: 2,
    borderColor: theme.colors.blue,
  },
  solid: {
    backgroundColor: theme.colors.blue,
    color: theme.kitColors.white,
  },
  solidInverse: {
    backgroundColor: theme.kitColors.white,
    color: theme.colors.blue,
    borderColor: theme.kitColors.white,
  },
  outlined: {
    backgroundColor: theme.kitColors.white,
    color: theme.colors.blue,
  },
}))

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button: React.FC<
  { variant?: 'solid' | 'outlined'; inverted?: boolean } & ButtonProps
> = ({ variant = 'solid', inverted, children, ...props }) => {
  const classes = useStyles()

  const solidClass = inverted ? classes.solidInverse : classes.solid
  const outlinedClass = classes.outlined

  return (
    <button
      {...props}
      className={classNames(
        classes.button,
        variant === 'solid' && solidClass,
        variant === 'outlined' && outlinedClass,
        props.className,
      )}
    >
      {children}
    </button>
  )
}
