import React, { useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { theme, Theme } from '../../theme'
import { classNames } from '../../utils'
import { HomePageContext } from './HomePage'
import { Media } from '../../components/Media'
import { FeatherIcon } from '../../components/icons/FeatherIcon'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  none: {
    display: 'none',
  },
  heading: {
    ...theme.typography.h3,
    color: theme.colors.navy,
    marginBottom: theme.spacing.M,
  },
  text: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    marginBottom: theme.spacing.XXL,
  },
  label: {
    ...theme.typography.overline,
    color: theme.colors.blue,
    marginBottom: theme.spacing.M,
  },
  dotColumn: {
    '& ul': {
      transform: 'translateY(100%)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& > li': {
        paddingRight: theme.spacing.M,
      },
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing.M,
        marginRight: theme.spacing.M,
      },
      [theme.breakpoints.down('md')]: {
        top: 'initial',
        right: 0,
        left: 0,
        marginTop: 40,
        marginBottom: 40,
        transform: 'none',
        '& > *:not(:last-child)': {
          marginBottom: 0,
        },
      },
    },
  },
  roundIcon: {
    width: 16,
    height: 16,
    borderRadius: 8,
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#A8A8DD',
    '&:active, &:focus': {
      outline: '2px dotted black',
    },
  },
  activeRoundIcon: {
    backgroundColor: theme.colors.blue,
  },
  button: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 32px)',
    height: 26,
    backgroundColor: 'transparent',
    borderStyle: 'none',
    '&:focus': {
      '& svg': {
        outline: '2px dotted black',
      },
    },
    [theme.breakpoints.down('sm')]: {
      top: 'calc(50% - 60px)',
    },
  },
  prevButton: {
    left: '-50px',
    [theme.breakpoints.down('sm')]: {
      left: '-35px',
    },
  },
  nextButton: {
    right: '-50px',
    [theme.breakpoints.down('sm')]: {
      right: '-35px',
    },
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    height: 420,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 56,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  imageContainer: {
    position: 'relative',
    maxWidth: '650px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '450px',
      margin: '0px auto',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px auto',
      gridRow: 1,
      maxWidth: '550px',
    },
  },
  logoSlide: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      height: '100%',
      margin: '0 auto',
      objectFit: 'contain',
    },
  },
}))

export const HomeBCorpSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const [imageIndex, setImageIndex] = useState(0)
  const context = useContext(HomePageContext)
  const content = context?.entry?.contentWithMedia ?? []
  const media = content[imageIndex]?.media?.find((media) => media != null)
  const mediaLength = content.length
  const canGoForward = imageIndex + 1 < mediaLength
  const canGoBack = imageIndex > 0

  const handleNext = () => {
    if (canGoForward) {
      setImageIndex((index) => index + 1)
    }
  }

  const handlePrev = () => {
    if (canGoBack) {
      setImageIndex((index) => index - 1)
    }
  }

  return (
    <LayoutContainer containerClass={layoutClasses.paddedContainerLarge}>
      <div className={layoutClasses.splitLayoutLeftContent}>
        <div className={classes.textContainer}>
          {content[imageIndex]?.label && (
            <p className={classes.label}>{content[imageIndex]?.label}</p>
          )}
          <h2 className={classes.heading}>{content[imageIndex]?.heading}</h2>
          <p className={classes.text}>{content[imageIndex]?.body}</p>
          {content[imageIndex]?.internalLink && (
            <ArrowLink to={content[imageIndex]?.internalLink ?? ''}>
              Learn More
            </ArrowLink>
          )}
          {!content[imageIndex]?.internalLink &&
            content[imageIndex]?.externalLink && (
              <ArrowLink
                to={{ pathname: content[imageIndex]?.externalLink ?? '' }}
                target="_blank"
              >
                Learn More
              </ArrowLink>
            )}
        </div>
        <div
          className={classes.imageContainer}
          role="region"
          aria-label="carousel"
        >
          <div className={[classes.none, classes.srOnly].join(' ')}>
            This is a carousel. Click Next/Previous buttons or a slide dot
            button to jump to a slide.
          </div>
          <ul>
            <li
              role="group"
              aria-label={`slide ${imageIndex + 1} of ${mediaLength}`}
            >
              <Media
                src={media?.url}
                type={media?.mimeType}
                alt={media?.altText}
                credit={media?.credit}
                className={classes.logoSlide}
              />
            </li>
          </ul>
          <div className={classes.dotColumn}>
            <ul>
              {content.map((image, index) => (
                <li>
                  <button
                    key={index}
                    className={classNames(
                      classes.roundIcon,
                      index === imageIndex && classes.activeRoundIcon,
                    )}
                    onClick={() => setImageIndex(index)}
                    aria-label={`Go to slide ${index + 1} of ${mediaLength}`}
                    {...(index === imageIndex && { 'aria-current': 'true' })}
                  />
                  <span className={classes.srOnly}>
                    Go to slide {index + 1} of [image].length
                  </span>
                </li>
              ))}
            </ul>
            {canGoBack && (
              <button
                className={classNames(classes.button, classes.prevButton)}
                onClick={handlePrev}
              >
                <span className={classes.srOnly}>Previous</span>
                <FeatherIcon name={'chevron-left'} color={theme.colors.blue} />
              </button>
            )}
            {canGoForward && (
              <button
                className={classNames(classes.button, classes.nextButton)}
                onClick={handleNext}
              >
                <span className={classes.srOnly}>Next</span>
                <FeatherIcon name={'chevron-right'} color={theme.colors.blue} />
              </button>
            )}
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}
