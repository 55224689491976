import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useInfiniteQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  StoriesPage as IStoriesPageQuery,
  StoriesPageVariables as IStoriesPageVariables,
  StoriesPage_stories_stories_story_Entry as StoryEntry,
  StoriesPage_news_news_news_Entry as NewsEntry,
  StoriesPage_storyCategories_storyCategories_Category as StoryCategory,
} from '../../api-types/StoriesPage'
import { ArrowLink } from '../../components/ArrowLink'
import { Button } from '../../components/Button'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames, fetchApiData, useQueryState } from '../../utils'
import { NewsPreview } from './NewsPreview'
import { StoriesCategoryNav } from './StoriesCategoryNav'
import { StoryPreview } from './StoryPreview'
import * as routes from '../../routes'
import { SubPageHeader } from '../common-sections/SubPageHeader'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { Breadcrumbs } from '../../components/Breadcrumbs'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  pageContainer: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        top: -335,
        left: -189,
      }),
    },
  },
  headerContainer: {
    backgroundColor: theme.kitColors.grey800,
    marginBottom: theme.spacing.XXXL,
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    height: 400,
  },
  categoryTitle: {
    ...theme.typography.h1,
    display: 'inline',
    backgroundColor: theme.kitColors.white,
    paddingLeft: 12,
    paddingRight: 12,
    textTransform: 'uppercase',
  },
  container: {
    paddingBottom: theme.spacing.XXXXL,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing.XXXL,
    },
  },
  content: {
    rowGap: theme.spacing.XXXL,
    '& > article': {
      gridColumn: 'span 4',
    },
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  searchHeading: {
    ...theme.typography.h3,
    gridColumn: '1 / -1',
    marginBottom: -theme.spacing.XXL,
  },
  featuredStory: {
    gridColumn: ['1 / span 8', '!important'],
    [theme.breakpoints.down('md')]: {
      gridColumn: ['1 / span 5', '!important'],
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: ['1 / span 4', '!important'],
    },
  },
  categoryNav: {
    gridColumn: '1 / -1',
    marginBottom: -theme.spacing.XXL,
  },
  categoryHeader: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
    '& h1': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 26,
        lineHeight: '40px',
      },
    },
  },
  newsSection: {
    gridColumn: '9 / span 4',
    paddingLeft: theme.spacing.XXXL - theme.spacing.L,
    [theme.breakpoints.down('md')]: {
      gridColumn: '6 / span 3',
      paddingLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
  },
  newsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.L,
    '& > h2': {
      ...theme.typography.h3,
    },
  },
  footer: {
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface StoriesPageQuery extends IStoriesPageQuery {
  stories: Array<StoryEntry | null> | null
  storyCategories: Array<StoryCategory | null> | null
  news: Array<NewsEntry | null> | null
}

type StoriesPageVariables = Omit<IStoriesPageVariables, 'category'> & {
  category?: string
}

export const StoriesPage: React.FC = () => {
  const theme = useTheme<Theme>()
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const {
    params: { categorySlug },
  } = useRouteMatch<{ categorySlug?: string }>()
  const history = useHistory()

  const [searchQuery, setSearchQuery] = useQueryState('search')

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['stories', categorySlug, searchQuery],
    (params: { pageParam?: StoriesPageVariables }) =>
      fetchApiData<StoriesPageQuery, StoriesPageVariables>('stories', {
        query: params.pageParam ?? {
          offset: 0,
          limit: 10,
          category: categorySlug,
          search: searchQuery,
        },
      }),
    {
      getNextPageParam: (lastPage, pages): StoriesPageVariables | undefined => {
        const totalLoaded = pages.reduce(
          (total, page) => total + (page.stories?.length ?? 0),
          0,
        )

        if (totalLoaded >= lastPage.total) return

        return {
          offset: totalLoaded,
          limit: 9,
          category: categorySlug,
          search: searchQuery,
        }
      },
      keepPreviousData: !searchQuery,
    },
  )

  const handleSearch = (query?: string) => {
    history.replace({
      pathname: '/stories',
    })
    setSearchQuery(query)
  }

  const topStory = (data?.pages[0].stories ?? [])[0]
  const topStoryMedia = topStory?.mainMedia?.find((m) => m != null)

  const categories = data?.pages[0]?.storyCategories

  const category = data?.pages[0]?.storyCategories?.find(
    (category) => category?.slug === categorySlug,
  )

  const news = data?.pages[0]?.news

  return (
    <div
      className={
        !categorySlug && !searchQuery ? classes.pageContainer : undefined
      }
    >
      <Breadcrumbs />
      <LayoutContainer containerClass={layoutClasses.paddedContainer}>
        {!category && !searchQuery && (
          <h1 className={classes.srOnly}>Stories</h1>
        )}
        <StoriesCategoryNav
          onSearch={handleSearch}
          categories={[{ label: 'All', path: '/stories' }].concat(
            categories?.map((category) => ({
              label: category?.title ?? '',
              path: routes.storyCategory.replace(
                ':categorySlug',
                category?.slug ?? '',
              ),
            })) ?? [],
          )}
        />
      </LayoutContainer>
      {category && !searchQuery && (
        <SubPageHeader
          heading={category.title}
          subheading={category.subheading}
          headingBackgroundColor={theme.colors.orange}
          hideBackButton
          className={classes.categoryHeader}
        />
      )}
      <h2 className={classes.srOnly}>List of Top Stories</h2>
      <LayoutContainer
        containerClass={classes.container}
        contentClass={classNames(layoutClasses.gridLayout, classes.content)}
      >
        {!categorySlug && !searchQuery && (
          <>
            {topStory && (
              <StoryPreview
                isLarge
                slug={topStory.slug}
                category={(topStory.category ?? [])[0]?.title ?? null}
                title={topStory.heading}
                summary={topStory.summary}
                className={classes.featuredStory}
                media={
                  topStoryMedia
                    ? {
                        src: topStoryMedia.url,
                        type: topStoryMedia.mimeType,
                        srcSet: topStoryMedia.srcset,
                        alt: topStoryMedia.altText,
                        credit: topStoryMedia.credit,
                      }
                    : undefined
                }
              />
            )}

            <div className={classes.newsSection}>
              <div className={classes.newsHeader}>
                <h2>Latest News</h2>
                <ArrowLink to={routes.news}>All News</ArrowLink>
              </div>

              {news?.map((newsItem) => {
                if (!newsItem) return null

                const category = newsItem.newsCategory?.find(
                  (category) => category != null,
                )

                return (
                  <NewsPreview
                    key={newsItem.uid}
                    category={category?.title}
                    date={newsItem.postDate}
                    headline={newsItem.heading}
                    slug={newsItem.slug}
                  />
                )
              })}
            </div>
          </>
        )}

        {searchQuery && (
          <h1 className={classes.searchHeading}>
            Showing Results for: {decodeURIComponent(searchQuery)}
          </h1>
        )}

        {data?.pages.map((page, pageIndex) =>
          page.stories?.map((story, storyIndex) => {
            if (!story) return null

            if (
              pageIndex === 0 &&
              storyIndex === 0 &&
              !categorySlug &&
              !searchQuery
            ) {
              // this would be the top story
              return null
            }

            const media = story.mainMedia?.find((m) => m != null)

            return (
              <StoryPreview
                key={story.uid}
                slug={story.slug}
                category={(story.category ?? [])[0]?.title ?? null}
                title={story.heading}
                summary={story.summary}
                media={
                  media
                    ? {
                        src: media.url,
                        type: media.mimeType,
                        srcSet: media.srcset,
                        alt: media.altText,
                        credit: media.credit,
                      }
                    : undefined
                }
              />
            )
          }),
        )}
        {hasNextPage && (
          <div className={classes.footer}>
            <Button onClick={() => fetchNextPage()}>View More</Button>
          </div>
        )}
      </LayoutContainer>
    </div>
  )
}
