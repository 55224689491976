import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  nav: {
    '& ul': {
      display: 'inline-flex',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing.XL,
      },
      [theme.breakpoints.down('md')]: {
        '& > *:not(:last-child)': {
          marginRight: theme.spacing.M,
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
    },
  },
  navButton: {
    ...theme.traits.clickable,
    ...theme.typography.menuLink,
    color: 'inherit',
    display: 'block',
    borderColor: theme.colors.blue,
    borderWidth: 0,
    marginBottom: theme.spacing.M,
    padding: 0,
    paddingBottom: theme.spacing.M,
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
  },
  activeNavLink: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 4,
    paddingBottom: theme.spacing.XS + theme.spacing.S,
  },
}))

interface Props {
  className?: string
  tabs: string[]
  selected: number
  onChange: (tabIndex: number) => void
}

export const TabBar: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { className, tabs, selected, onChange } = props

  return (
    <nav
      className={classNames(classes.nav, className)}
      aria-label="retailer requirements"
    >
      <ul>
        {tabs.map((title, i) => (
          <li>
            <button
              key={i}
              className={classNames(
                classes.navButton,
                i === selected && classes.activeNavLink,
              )}
              onClick={() => onChange(i)}
            >
              {title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  )
}
