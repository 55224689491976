import React, { createContext } from 'react'
import { useTheme } from 'react-jss'
import { Theme } from '../../../theme'
import { SplitContentSection } from '../../common-sections/SplitContentSection'
import { StoriesAndNewsSection } from '../../common-sections/StoriesAndNewsSection'
import { SubPageHeader } from '../../common-sections/SubPageHeader'
import { CompanyAdvocacyExpandingRidershipSection } from './CompanyAdvocacyExpandingRidershipSection'
import { CompanyAdvocacyPartnersSection } from './CompanyAdvocacyPartnersSection'
import {
  CompanyAdvocacyPage as ICompanyAdvocacyPageQuery,
  CompanyAdvocacyPage_entry_companyAdvocacy_companyAdvocacy_Entry as CompanyAdvocacyPageEntry,
  CompanyAdvocacyPage_stories_stories_story_Entry as StoryPreviewEntry,
  CompanyAdvocacyPage_news_news_news_Entry as NewsPreviewEntry,
} from '../../../api-types/CompanyAdvocacyPage'
import { fetchApiData } from '../../../utils'
import { useQuery } from 'react-query'
import { Breadcrumbs } from '../../../components/Breadcrumbs'
import { Loading } from '../../../components/Loading'

interface CompanyAdvocacyPageQuery extends ICompanyAdvocacyPageQuery {
  entry: CompanyAdvocacyPageEntry | null
  stories: Array<StoryPreviewEntry | null> | null
  news: Array<NewsPreviewEntry | null> | null
}

export const CompanyAdvocacyPageContext = createContext<
  CompanyAdvocacyPageQuery | undefined
>(undefined)

export const CompanyAdvocacyPage: React.FC = () => {
  const theme = useTheme<Theme>()

  const { data, isLoading } = useQuery('company/advocacy', () =>
    fetchApiData<CompanyAdvocacyPageQuery>('companyadvocacy'),
  )
  const contentWithMedia = data?.entry?.contentWithMedia

  if (isLoading) return <Loading />
  return (
    <CompanyAdvocacyPageContext.Provider value={data}>
      <Breadcrumbs />
      <SubPageHeader
        heading={data?.entry?.pageHeading}
        subheading={data?.entry?.subheading}
        headingColor={theme.colors.navy}
        headingBackgroundColor={theme.colors.pink}
      />
      <SplitContentSection
        contentWithMedia={
          contentWithMedia?.length ? contentWithMedia[0] : undefined
        }
        contentSide="left"
      />
      <CompanyAdvocacyExpandingRidershipSection />
      <SplitContentSection
        contentWithMedia={
          contentWithMedia?.length && contentWithMedia.length >= 2
            ? contentWithMedia[1]
            : undefined
        }
        contentSide="right"
        darkMode
        backgroundColor={theme.colors.raisin}
        headingColor={theme.colors.pink}
      />
      <CompanyAdvocacyPartnersSection />
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
    </CompanyAdvocacyPageContext.Provider>
  )
}
