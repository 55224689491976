import React, { createContext } from 'react'
import { MediaHeader } from '../../components/MediaHeader'
import { ContactLocationSection } from './ContactLocationSection'
import {
  ContactUsPage as IContactUsPageQuery,
  ContactUsPage_entry_contactUs_contactUs_Entry as ContactUsPageEntry,
} from '../../api-types/ContactUsPage'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { ContactSection } from './ContactSection'
import { CTAFooterSection } from '../common-sections/CTAFooterSection'
import { doingBusinessWithQ } from '../../routes'
import { useTheme } from 'react-jss'
import { Theme } from '../../theme'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'

interface ContactUsPageQuery extends IContactUsPageQuery {
  entry: ContactUsPageEntry | null
}

export const ContactUsPageContext = createContext<
  ContactUsPageQuery | undefined
>(undefined)

export const ContactPage: React.FC = () => {
  const theme = useTheme<Theme>()
  const { data, isLoading } = useQuery('contact', () =>
    fetchApiData<ContactUsPageQuery>('contact'),
  )
  const headerMedia = data?.entry?.heroImage?.find((img) => img != null)

  if (isLoading) return <Loading />
  return (
    <ContactUsPageContext.Provider value={data}>
      <Breadcrumbs />
      <MediaHeader
        heading={data?.entry?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <ContactSection />
      <ContactLocationSection />
      <CTAFooterSection
        heading={data?.entry?.inquirySectionHeading}
        description={data?.entry?.inquirySectionDescription}
        internalLink={doingBusinessWithQ}
        buttonText={'Do Business With Us'}
        backgroundColor={theme.colors.green}
      />
    </ContactUsPageContext.Provider>
  )
}
