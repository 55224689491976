import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Link } from 'react-router-dom'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { CareersPageContext } from './CareersPage'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { classNames } from '../../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        backgroundColor: theme.colors.navy,
        top: -374,
        left: -105,
        opacity: 0.3,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.colors.navy,
    },
  },
  articles: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing.XXXL,
    marginBottom: theme.spacing.XXL,
    '& article:not(:first-child)': {
      borderTop: [2, 'solid', theme.colors.orange],
      paddingTop: theme.spacing.M,
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing.XXL,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      marginBottom: 0,
    },
  },
  h3: {
    ...theme.typography.h3,
    color: theme.kitColors.white,
    marginRight: 'auto',
  },
  itemHeading: {
    ...theme.typography.h5,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.S,
  },
  overline: {
    ...theme.typography.overline,
    color: theme.kitColors.white,
    opacity: '70%',
    marginBottom: theme.spacing.S,
  },
  text: {
    ...theme.typography.smallBody,
    color: theme.kitColors.white,
    opacity: '70%',
    marginBottom: theme.spacing.M,
  },
  link: {
    ...theme.typography.menuLink,
    ...theme.traits.clickable,
    textDecoration: 'none',
    color: theme.kitColors.white,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginTop: 'auto',
  },
  icon: {
    marginLeft: 12,
    stroke: theme.kitColors.white,
  },
}))

export const CareersExploreQBPSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(CareersPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainer,
        classes.container,
      )}
    >
      <div>
        <div className={classes.articles}>
          <article>
            <h2 className={classes.h3}>{context?.entry?.exploreQbpHeading}</h2>
          </article>
          {context?.entry?.exploreQbpItems?.map((item) => (
            <article key={item?.uid}>
              <h3 className={classes.itemHeading}>{item?.itemTitle}</h3>
              <p className={classes.text}>{item?.itemDescription}</p>
              <Link to={item?.internalLink ?? ''} className={classes.link}>
                Learn More
                <FeatherIcon name={'arrow-right'} className={classes.icon} />
              </Link>
            </article>
          ))}
        </div>
      </div>
    </LayoutContainer>
  )
}
