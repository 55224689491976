import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../../components/LayoutContainer'
import { Media } from '../../../components/Media'
import { Theme } from '../../../theme'
import { classNames } from '../../../utils'
import { CompanyAdvocacyPageContext } from './CompanyAdvocacyPage'
import treadBg from '../../../assets/patterns/Tread-Orange.svg'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    paddingTop: 0,
  },
  content: {
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  headingContainer: {
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
  },
  heading: {
    ...theme.typography.h3,
    textAlign: 'center',
    marginBottom: theme.spacing.M,
  },
  subHeading: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    textAlign: 'center',
  },
  contentItem: {
    gridColumn: 'span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 4',
    },
  },
  contentHeading: {
    ...theme.typography.bodyEm,
    marginBottom: theme.spacing.XS,
  },
  contentParagraph: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing.M,
    },
  },
  mediaContainer: {
    marginBottom: theme.spacing.XXL,
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadBg, {
        transform: 'scaleX(-1)',
        bottom: -200,
        right: 0,
      }),
    },
  },
  mediaContent: {
    '& > * img': {
      height: 420,
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
  },
}))

export const CompanyAdvocacyExpandingRidershipSection: React.FC = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()
  const context = useContext(CompanyAdvocacyPageContext)
  const media = context?.entry?.companyAdvocacyExpandingRidershipSectionMedia?.find(
    (item) => item != null,
  )

  return (
    <>
      <LayoutContainer
        containerClass={classNames(
          layoutClasses.paddedContainer,
          classes.container,
        )}
        contentClass={classNames(layoutClasses.gridLayout, classes.content)}
      >
        <div className={classes.headingContainer}>
          <h2 className={classes.heading}>
            {context?.entry?.companyAdvocacyExpandingRidershipHeading}
          </h2>
          <p className={classes.subHeading}>
            {context?.entry?.companyAdvocacyExpandingRidershipSubheading}
          </p>
        </div>
        {context?.entry?.companyAdvocacyExpandingRidershipItems?.map((item) => (
          <div className={classes.contentItem} key={item?.uid}>
            <h3 className={classes.contentHeading}>{item?.itemTitle}</h3>
            <p className={classes.contentParagraph}>{item?.itemDescription}</p>
          </div>
        ))}
      </LayoutContainer>
      <LayoutContainer
        containerClass={classNames(
          layoutClasses.paddedContainer,
          classes.mediaContainer,
        )}
        contentClass={classes.mediaContent}
      >
        <Media
          src={media?.url}
          type={media?.mimeType}
          alt={media?.altText}
          credit={media?.credit}
        />
      </LayoutContainer>
    </>
  )
}
