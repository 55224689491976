import React, { createContext } from 'react'
import { MediaHeader } from '../../components/MediaHeader'
import { Theme } from '../../theme'
import { SplitContentSection } from '../common-sections/SplitContentSection'
import { DoingBusinessWithQDistributionSection } from './DoingBusinessWithQDistributionSection'
import { DoingBusinessWithQPartnersSection } from './DoingBusinessWithQPartnersSection'
import { DoingBusinessWithQRetailerRequirementsSection } from './DoingBusinessWithQRetailerRequirementsSection'
import { DoingBusinessWithQSupplierRequirementsSection } from './DoingBusinessWithQSupplierRequirementsSection'
import {
  DoingBusinessWithQPage as IDoingBusinessWithQPageQuery,
  DoingBusinessWithQPage_entry_doingBusinessWithQ_doingBusinessWithQ_Entry as DoingBusinessWithQPageEntry,
} from '../../api-types/DoingBusinessWithQPage'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { createUseStyles } from 'react-jss'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scaleY(-1)',
    left: -80,
    bottom: -328,
  }),
}))

interface DoingBusinessWithQPageQuery extends IDoingBusinessWithQPageQuery {
  entry: DoingBusinessWithQPageEntry | null
}

export const DoingBusinessWithQPageContext = createContext<
  DoingBusinessWithQPageQuery | undefined
>(undefined)

export const DoingBusinessWithQPage: React.FC = () => {
  const classes = useStyles()
  const { data, isLoading } = useQuery('doingbusinesswithq', () =>
    fetchApiData<DoingBusinessWithQPageQuery>('doingbusinesswithq'),
  )
  const headerMedia = data?.entry?.heroImage?.find((img) => img != null)

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  if (isLoading) return <Loading />
  return (
    <DoingBusinessWithQPageContext.Provider value={data}>
      <Breadcrumbs />
      <MediaHeader
        heading={data?.entry?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <div className={isNotMobile ? classes.tread : ''}>
        <DoingBusinessWithQPartnersSection />
        <DoingBusinessWithQDistributionSection />
        {data?.entry?.contentWithMedia?.map((item) => (
          <SplitContentSection
            key={item?.uid}
            contentSide="right"
            contentWithMedia={item}
          />
        ))}
      </div>
      <DoingBusinessWithQRetailerRequirementsSection />
      <DoingBusinessWithQSupplierRequirementsSection />
    </DoingBusinessWithQPageContext.Provider>
  )
}
