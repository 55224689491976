import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../theme'
import { LayoutContainer, useLayoutStyles } from './LayoutContainer'
import { BCorpLogo } from './icons/BCorpLogo'
import { Facebook } from './icons/Facebook'
import { Instagram } from './icons/Instagram'
import { Twitter } from './icons/Twitter'
import { LinkedIn } from './icons/LinkedIn'
import { classNames } from '../utils'
import * as routes from '../routes'
import logoSrc from '../assets/TextLogoSquare.svg'
import a360Src from '../assets/A360-Badge-White-on-Black.svg'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.navy,
  },
  content: {
    marginTop: theme.spacing.XXL,
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  logoContainerQ: {
    gridColumn: '1 / span 3',
    gridRow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > a:not(:first-child)': {
      marginTop: theme.spacing.S,
    },
    '& svg': {
      marginBottom: theme.spacing.XXL,
    },
    [theme.breakpoints.down('md')]: {
      gridRow: '1 / span 2',
      gridColumn: '1 / span 4',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  logoContainerB: {
    gridColumn: '1 / span 3',
    gridRow: 2,
    display: 'flex',
    marginTop: 'auto',
    width: 240,
    [theme.breakpoints.down('md')]: {
      gridRow: 6,
      gridColumn: '1 / span 4',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.XXL,
      gridRow: 3,
      width: '100%',
      justifyContent: 'center',
    },
  },
  logoContainerA360: {
    gridColumn: '1 / span 3',
    gridRow: 3,
    display: 'flex',
    marginTop: 'auto',
    width: 140,
    [theme.breakpoints.down('md')]: {
      gridRow: 7,
      gridColumn: '1 / span 4',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.XXL,
      gridRow: 4,
      width: '100%',
      justifyContent: 'center',
    },
  },
  nav: {
    '& ul li': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        textAlign: 'left',
      },
    },
  },
  navColumn2: {
    gridColumn: '4 / span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '5 / span 4',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / span 4',
    },
  },
  navColumn3: {
    gridColumn: '7 / span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '5 / span 4',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / span 4',
    },
  },
  navColumn4: {
    gridColumn: '10 / span 3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '5 / span 4',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / span 4',
    },
  },
  title: {
    ...theme.traits.clickable,
    ...theme.typography.h5,
    color: theme.kitColors.white,
    textDecoration: 'none',
    marginBottom: theme.spacing.M,
    textAlign: 'end',
    '& a': {
      color: theme.kitColors.white,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
    },
  },
  text: {
    ...theme.typography.smallBody,
    color: theme.kitColors.grey200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  link: {
    ...theme.traits.clickable,
    ...theme.typography.menuLink,
    textDecoration: 'none',
    marginBottom: theme.spacing.M,
    color: theme.kitColors.white,
  },
  lastLink: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  bottomRow: {
    gridColumn: '5 / span 8',
    gridRow: 3,
    display: 'flex',
    marginTop: theme.spacing.XXL,
    marginBottom: theme.spacing.XXL,
    width: '100%',
    '& p': {
      marginRight: theme.spacing.M,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing.XXL,
      gridColumn: '3 / span 6',
      gridRow: 7,
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
      alignItems: 'center',
      marginTop: theme.spacing.XXL,
      '& p': {
        marginTop: theme.spacing.M,
        marginRight: 0,
      },
      gridColumn: '1 / span 4',
      gridRow: 10,
    },
  },
  socialMedia: {
    display: 'inline-flex',
    marginLeft: 'auto',
    '& ul': {
      display: 'inline-flex',
      '& > li:not(:last-child)': {
        marginRight: theme.spacing.XXL,
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      justifyContent: 'center',
      width: '100%',
      marginBottom: theme.spacing.XXL,
    },
  },
  logoLink: {
    display: 'block',
    width: 118,
    height: 120,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${logoSrc})`,
    marginBottom: theme.spacing.XXL,
  },
  a360logo: {
    paddingBottom: theme.spacing.XXL,
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  const loginAddr = 'https://www.qbp.com/qbponlinestorefront/login'
  const jobOpportunities =
    'https://recruiting.ultipro.com/QUA1003QBP/JobBoard/4b01c3ed-7e54-43da-954f-c74c4b3945a6'
  const canadaLabourReportPdf =
    'https://www.qbp.com/diagrams/QInfoPDF/Canada-Forced-and-Child-Labour-Report.pdf'

  return (
    <LayoutContainer
      containerClass={classes.container}
      contentClass={classNames(classes.content, layoutClasses.gridLayout)}
      ContainerElement={'footer'}
    >
      <div className={classes.logoContainerQ}>
        <Link
          to={routes.home}
          className={classes.logoLink}
          aria-label="QBP Home"
        />
        <p className={classes.text}>6400 W. 105th Street</p>
        <p className={classes.text}>Bloomington, MN 55438</p>
        <a href="tel:+9529419391" className={classes.link}>
          952-941-9391
        </a>
      </div>
      <Link
        to={routes.bCorp}
        className={classes.logoContainerB}
        aria-label="Certified B Corporation. This company meets the highest standards of social and environmental impact."
      >
        <BCorpLogo />
      </Link>
      <a
        target="_blank"
        href="https://accessible360.com"
        rel="noreferrer"
        className={classes.logoContainerA360}
      >
        <img
          src={a360Src}
          className={classes.a360logo}
          alt="Reviewed by Accessible360"
          width="120px"
        />
      </a>
      <nav
        className={classNames(classes.navColumn3, classes.nav)}
        aria-label="brands footer"
      >
        <h2 className={classes.title}>
          <a href={routes.brands}>Brands</a>
        </h2>
        <ul>
          <li>
            <Link to={routes.brandsDirectory} className={classes.link}>
              Brands Directory
            </Link>
          </li>
        </ul>
      </nav>
      {/* TEMPORARILY DISABLED (UofQ)
      <nav
        className={classNames(classes.navColumn3, classes.nav)}
        aria-label="u of q footer"
      >
        <h2 className={classes.title}>
          <a href={routes.uOfQ}>U of Q</a>
        </h2>
        <ul>
          <li>
            <Link to={routes.uOfQEvents} className={classes.link}>
              Events
            </Link>
          </li>
          <li>
            <Link to={routes.uOfQInstitute} className={classes.link}>
              Institute
            </Link>
          </li>
          <li>
            <Link to={routes.uOfQTrainingLibrary} className={classes.link}>
              Training Library
            </Link>
          </li>
        </ul>
      </nav>
      */}
      <nav
        className={classNames(classes.navColumn4, classes.nav)}
        aria-label="do business footer"
      >
        <h2 className={classes.title}>
          <a href={routes.doingBusinessWithQ}>Do Business With Us</a>
        </h2>
        <ul>
          <li>
            <Link
              to={{
                pathname: routes.doingBusinessWithQ,
                state: { scrollTo: 'retailer' },
              }}
              className={classes.link}
            >
              Retailers
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: routes.doingBusinessWithQ,
                state: { scrollTo: 'supplier' },
              }}
              className={classes.link}
            >
              Suppliers
            </Link>
          </li>
          <li>
            <Link
              to={{ pathname: loginAddr }}
              target="_blank"
              className={classes.link}
            >
              Log In
            </Link>
          </li>
        </ul>
      </nav>
      <nav
        className={classNames(classes.navColumn2, classes.nav)}
        aria-label="careers footer"
      >
        <h2 className={classes.title}>
          <a href={routes.careers}>Careers</a>
        </h2>
        <ul>
          <li>
            <Link
              to={{ pathname: jobOpportunities }}
              target="_blank"
              className={classes.link}
            >
              Current Openings
            </Link>
          </li>
          <li>
            <a
              target="_blank"
              href="https://transparency-in-coverage.uhc.com/"
              rel="noreferrer"
              className={classes.link}
            >
              Health Coverage Disclosure
            </a>
          </li>
          <li>
            <Link
              to={{ pathname: canadaLabourReportPdf }}
              target="_blank"
              className={classes.link}
            >
              Canada Forced and Child Labour Report
            </Link>
          </li>
        </ul>
      </nav>
      <nav
        className={classNames(classes.navColumn3, classes.nav)}
        aria-label="stories footer"
      >
        <h2 className={classes.title}>
          <a href={routes.stories}>Stories</a>
        </h2>
        <ul>
          <li>
            <Link to={routes.news} className={classes.link}>
              News
            </Link>
          </li>
        </ul>
      </nav>
      <nav
        className={classNames(classes.navColumn4, classes.nav)}
        aria-label="company footer"
      >
        <h2 className={classes.title}>
          <a href={routes.company}>Company</a>
        </h2>
        <ul>
          <li>
            <Link to={routes.companySustainability} className={classes.link}>
              Sustainability
            </Link>
          </li>
          <li>
            <Link to={routes.companyAdvocacy} className={classes.link}>
              Advocacy
            </Link>
          </li>
          <li>
            <Link
              to={routes.companyDiversityInclusion}
              className={classes.link}
            >
              Diversity & Inclusion
            </Link>
          </li>
          <li>
            <Link to={routes.companyCulture} className={classes.link}>
              Culture
            </Link>
          </li>
          <li>
            <Link to={routes.contact} className={classes.link}>
              Contact & Locations
            </Link>
          </li>
        </ul>
      </nav>
      <div className={classes.bottomRow}>
        <p className={classes.text}>
          &copy; {new Date().getFullYear()} Quality Bicycle Products
        </p>
        <p className={classes.text}>
          <Link
            to={routes.privacyPolicy}
            className={[classes.link, classes.lastLink].join(' ')}
          >
            Privacy Policy
          </Link>
        </p>
        <nav className={classes.socialMedia} aria-label="social">
          <ul>
            <Facebook />
            <Instagram />
            <Twitter />
            <LinkedIn />
          </ul>
        </nav>
      </div>
    </LayoutContainer>
  )
}
