import React from 'react'
import { createUseStyles } from 'react-jss'

import { MediaHeader } from '../../components/MediaHeader'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'

const useStyles = createUseStyles((theme: Theme) => ({
  contentGrid: {
    '& > *': {
      gridColumn: '3 / -3',
      [theme.breakpoints.down('md')]: {
        gridColumn: '1 / -1',
      },
    },
  },
}))

interface Props {
  children: React.ReactChild | React.ReactChild[] | undefined | null
  className?: string
  headerProps: React.ComponentProps<typeof MediaHeader>
}

export const InnerPageContent: React.FC<Props> = (props) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  return (
    <>
      <MediaHeader {...props.headerProps} showBackButton={true} />
      <LayoutContainer
        containerClass={layoutClasses.paddedContainerLarge}
        contentClass={classNames(
          layoutClasses.gridLayout,
          classes.contentGrid,
          props.className,
        )}
      >
        {props.children}
      </LayoutContainer>
    </>
  )
}
