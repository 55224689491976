import React, { useEffect, useState } from 'react'
import {
  QueryClientProvider as ReactQueryClientProvider,
  QueryClient,
} from 'react-query'
import { useLocation } from 'react-router-dom'

export const QueryClientErrorHandler: React.FC<{
  children: (error: unknown) => React.ReactElement
}> = ({ children }) => {
  const [error, setError] = useState<unknown>()
  const location = useLocation()

  useEffect(() => {
    setError(null)
  }, [location.pathname])

  return (
    <ReactQueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              retry: false,
              onError: (error) => setError(error),
            },
          },
        })
      }
    >
      {children(error)}
    </ReactQueryClientProvider>
  )
}
