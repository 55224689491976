import React from 'react'
import { createUseStyles } from 'react-jss'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import { Theme } from '../../theme'
import { fetchApiData } from '../../utils'
import { InnerPageContent } from '../common-sections/InnerPageContent'
import {
  CommunityGrantRecipientsPage as ICommunityGrantRecipientsPageQuery,
  CommunityGrantRecipientsPage_entry_communityGrantRecipients_communityGrantRecipients_Entry as CommunityGrantRecipientsEntry,
} from '../../api-types/CommunityGrantRecipientsPage'
import { Media } from '../../components/Media'
import { Loading } from '../../components/Loading'

interface CommunityGrantRecipientsPageQuery
  extends ICommunityGrantRecipientsPageQuery {
  entry: CommunityGrantRecipientsEntry | null
}

export const CommunityGrantRecipientsPage: React.FC = () => {
  const classes = useStyles()
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>()

  const { data, isLoading } = useQuery(['community-grant', slug], () =>
    fetchApiData<CommunityGrantRecipientsPageQuery>(`community-grant/${slug}`),
  )

  const headerMedia = data?.entry?.heroImage?.find((m) => m != null)

  if (isLoading) return <Loading />
  return (
    <InnerPageContent
      headerProps={{
        heading: data?.entry?.title,
        backgroundSrc: headerMedia?.url,
        backgroundType: headerMedia?.mimeType,
      }}
      className={classes.container}
    >
      <>
        <h2 className={classes.subheading}>{data?.entry?.subheading}</h2>
        {data?.entry?.communityGrantRecipientList?.map((recipient) => {
          const media = recipient?.recipientPhoto?.find((m) => m != null)

          return (
            <div key={recipient?.uid} className={classes.recipientItem}>
              <div className={classes.mediaContainer}>
                <Media
                  src={media?.url}
                  type={media?.mimeType}
                  srcSet={media?.srcset}
                  alt={media?.altText}
                  credit={media?.credit}
                />
              </div>
              <h3 className={classes.recipientName}>
                {recipient?.recipientName}
              </h3>
              <h4 className={classes.recipientLocation}>
                {recipient?.recipientLocation}
              </h4>
              <p className={classes.recipientDescription}>
                {recipient?.recipientDescription}
              </p>
            </div>
          )
        })}
      </>
    </InnerPageContent>
  )
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  subheading: {
    ...theme.typography.h5,
  },
  recipientItem: {
    gridColumn: 'span 4',
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(odd)': {
        gridColumn: '3 / span 4',
      },
      '&:nth-of-type(even)': {
        gridColumn: '7 / span 4',
      },
    },
  },
  mediaContainer: {
    '& > * img': {
      maxHeight: 275,
    },
  },
  recipientName: {
    ...theme.typography.h4,
    marginTop: theme.spacing.L,
    marginBottom: theme.spacing.S,
  },
  recipientLocation: {
    ...theme.typography.h5,
    color: theme.kitColors.grey800,
    marginBottom: theme.spacing.S,
  },
  recipientDescription: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
  },
}))
