import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'

const useStyles = createUseStyles((theme: Theme) => ({
  heading: {
    ...theme.typography.h3,
  },
  text: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    marginTop: theme.spacing.M,
  },
  contentGrid: {
    marginTop: theme.spacing.L,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing.XXL,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      marginTop: theme.spacing.XXL,
    },
  },
  contentItemHeading: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.XS,
  },
  contentItemDescription: {
    ...theme.typography.smallBody,
    color: theme.kitColors.grey700,
  },
}))

type ContentItem = {
  heading: string | null
  description: string | null
}

export const SectionParagraphGrid: React.FC<{
  heading: string | null | undefined
  description?: string | null | undefined
  content: (ContentItem | null)[] | null | undefined
}> = ({ heading, description, content }) => {
  const classes = useStyles()
  return (
    <div>
      <h2 className={classes.heading}>{heading}</h2>
      {description && <p className={classes.text}>{description}</p>}
      <div className={classes.contentGrid}>
        {content?.map((item, i) => (
          <div key={i}>
            <h3 className={classes.contentItemHeading}>{item?.heading}</h3>
            <p className={classes.contentItemDescription}>
              {item?.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}
