import React from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { NewsPreview } from '../stories/NewsPreview'
import { StoryPreview } from '../stories/StoryPreview'
import * as routes from '../../routes'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import {
  storiesAndNewsSection_stories_stories_story_Entry as StoryEntry,
  storiesAndNewsSection_news_news_news_Entry as NewsEntry,
} from '../../api-types/storiesAndNewsSection'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        top: -265,
        left: -189,
      }),
    },
  },
  content: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    rowGap: theme.spacing.XXL,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  heading: {
    ...theme.typography.h3,
    textAlign: 'center',
    gridColumn: '1 / -1',
  },
  storiesSection: {
    gridColumn: 'span 2',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 1',
    },
  },
  storiesContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& > article:last-child': {
        display: 'none',
      },
    },
  },
  storyPreview: {
    flex: 1,
    '&:not(:last-child)': {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing.L,
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing.XXL,
      },
    },
  },
  newsSection: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing.XXXL - theme.spacing.L,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing.M,
    },
  },
  storiesHeader: {
    marginBottom: theme.spacing.XXL,
  },
  newsHeader: {
    marginBottom: theme.spacing.XXL,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
}))

type Props = {
  heading?: string | null
  stories?: Array<StoryEntry | null> | null
  news?: Array<NewsEntry | null> | null
}

export const StoriesAndNewsSection: React.FC<Props> = ({
  heading,
  stories,
  news,
}) => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classNames(layoutClasses.gridLayout, classes.content)}
    >
      {heading && <h2 className={classes.heading}>{heading}</h2>}

      <div className={classes.storiesSection}>
        <div className={classes.storiesHeader}>
          <ArrowLink to={routes.stories}>All Stories</ArrowLink>
        </div>
        <div className={classes.storiesContainer}>
          {stories?.slice(0, 2).map((story) => {
            const media = story?.mainMedia?.find((m) => m != null)
            const category = story?.category?.find((c) => c != null)
            return (
              <StoryPreview
                key={story?.uid}
                slug={story?.slug}
                title={story?.heading}
                summary={story?.summary}
                category={category?.title}
                media={{
                  src: media?.url,
                  srcSet: media?.srcset,
                  type: media?.mimeType,
                  alt: media?.altText,
                  credit: media?.credit,
                }}
                className={classes.storyPreview}
              />
            )
          })}
        </div>
      </div>
      <div className={classes.newsSection}>
        <div className={classes.newsHeader}>
          <ArrowLink to={routes.news}>All News</ArrowLink>
        </div>
        {news?.map((news, index) => (
          <NewsPreview
            key={index}
            category={news?.newsCategory?.find((c) => c != null)?.title}
            date={news?.postDate}
            headline={news?.heading}
            slug={news?.slug}
          />
        ))}
      </div>
    </LayoutContainer>
  )
}
