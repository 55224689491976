import React from 'react'
import { createUseStyles } from 'react-jss'
import { BackButton } from '../../components/BackButton'
import { LayoutContainer } from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import treadSrc from '../../assets/patterns/Tread-White.svg'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing.XXXL,
    paddingBottom: theme.spacing.XXXXL + theme.spacing.M,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing.XXL,
      paddingBottom: theme.spacing.XXXL,
    },
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.treadBackground(treadSrc, {
        backgroundColor: theme.colors.navy,
        top: -340,
        right: -180,
        transform: 'scaleX(-1)',
        opacity: 0.3,
      }),
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.colors.navy,
    },
  },
  backButton: {
    marginBottom: theme.spacing.XXL,
    color: theme.kitColors.white,
    '& > svg': {
      stroke: theme.kitColors.white,
    },
  },
  heading: (props: Props) => ({
    ...theme.typography.h1,
    color: props.headingColor ?? theme.kitColors.white,
    backgroundColor: props.headingBackgroundColor,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    textTransform: 'uppercase',
    display: 'inline-block',
    marginBottom: theme.spacing.L,
  }),
  subHeading: {
    ...theme.typography.body,
    color: theme.kitColors.grey200,
    [theme.breakpoints.up('md')]: {
      maxWidth: '66%',
    },
  },
}))

type Props = {
  heading: string | null | undefined
  subheading: string | null | undefined
  headingColor?: string
  headingBackgroundColor?: string
  hideBackButton?: boolean
  className?: string
}

export const SubPageHeader: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <LayoutContainer
      containerClass={classNames(classes.container, props.className)}
    >
      {!props.hideBackButton && <BackButton className={classes.backButton} />}
      {props.heading && <h1 className={classes.heading}>{props.heading}</h1>}
      {props.subheading && (
        <p className={classes.subHeading}>{props.subheading}</p>
      )}
    </LayoutContainer>
  )
}
