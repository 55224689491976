import React, { createContext } from 'react'
import { CareersBenefitsSection } from './CareersBenefitsSection'
import { CareersExploreQBPSection } from './CareersExploreQBPSection'
import { CareersJobOpeningsSection } from './CareersJobOpeningsSection'
import { StoriesAndNewsSection } from '../common-sections/StoriesAndNewsSection'
import { SplitContentSection } from '../common-sections/SplitContentSection'
import {
  CareersPage as ICareersPageQuery,
  CareersPage_entry_careers_careers_Entry as CareersPageEntry,
  CareersPage_stories_stories_story_Entry as StoryEntry,
  CareersPage_news_news_news_Entry as NewsEntry,
} from '../../api-types/CareersPage'

import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { CTAFooterSection } from '../common-sections/CTAFooterSection'
import { MediaHeader } from '../../components/MediaHeader'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import treadSrc from '../../assets/patterns/Tread-Raisin.svg'
import angleSrc from '../../assets/patterns/Angle-Orange.svg'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scaleX(-1)',
    right: -68,
    top: -286,
  }),
  angle: theme.traits.angleBackground(angleSrc, {
    transform: 'scaleX(-1)',
    top: 0,
    right: 0,
  }),
}))

interface CareersPageQuery extends ICareersPageQuery {
  entry: CareersPageEntry | null
  stories: Array<StoryEntry | null> | null
  news: Array<NewsEntry | null> | null
}

export const CareersPageContext = createContext<CareersPageQuery | undefined>(
  undefined,
)

export const CareersPage: React.FC = () => {
  const classes = useStyles()
  const { data, isLoading } = useQuery('careers', () =>
    fetchApiData<CareersPageQuery>('careers'),
  )

  const headerMedia = data?.entry?.heroImage?.find((m) => m != null)
  const contentWithMedia = data?.entry?.contentWithMedia

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  if (isLoading) return <Loading />
  return (
    <CareersPageContext.Provider value={data}>
      <Breadcrumbs />
      <MediaHeader
        heading={data?.entry?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <CareersJobOpeningsSection />
      <div className={isNotMobile ? classes.tread : ''}>
        <div className={isNotMobile ? classes.angle : ''}>
          <SplitContentSection
            contentWithMedia={
              contentWithMedia?.length ? contentWithMedia[0] : undefined
            }
            contentSide="left"
          />
        </div>
      </div>
      <CareersBenefitsSection />
      <SplitContentSection
        contentWithMedia={
          contentWithMedia?.length && contentWithMedia.length >= 2
            ? contentWithMedia[1]
            : undefined
        }
        contentSide="right"
      />
      <CareersExploreQBPSection />
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
      <CTAFooterSection
        heading={data?.entry?.exploreOpportunitiesHeading}
        description={data?.entry?.exploreOpportunitiesSubheading}
        externalLink={data?.entry?.externalJobSiteLink}
        buttonText={'Explore Opportunities'}
      />
    </CareersPageContext.Provider>
  )
}
