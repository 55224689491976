import React, { createContext } from 'react'
import { SplitContentSection } from '../../common-sections/SplitContentSection'
import { StoriesAndNewsSection } from '../../common-sections/StoriesAndNewsSection'
import { SubPageHeader } from '../../common-sections/SubPageHeader'
import { CompanySustainabilityFootprintSection } from './CompanySustainabilityFootprintSection'
import { SustainabilityMetricsSection } from './SustainabilityMetricsSection'
import {
  CompanySustainabilityPage as CompanySustainabilityPageQuery,
  CompanySustainabilityPage_entry_companySustainability_companySustainability_Entry as CompanySustainabilityPageEntry,
  CompanySustainabilityPage_stories_stories_story_Entry as StoryPreviewEntry,
  CompanySustainabilityPage_news_news_news_Entry as NewsPreviewEntry,
} from '../../../api-types/CompanySustainabilityPage'
import { fetchApiData } from '../../../utils'
import { useQuery } from 'react-query'
import { createUseStyles, useTheme } from 'react-jss'
import { Theme } from '../../../theme'
import treadSrc from '../../../assets/patterns/Tread-Blue.svg'
import angleSrc from '../../../assets/patterns/Angle-Orange.svg'
import { Breadcrumbs } from '../../../components/Breadcrumbs'
import { Loading } from '../../../components/Loading'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  tread: theme.traits.treadBackground(treadSrc, {
    transform: 'scale(-1, -1)',
    right: -68,
    bottom: -283,
  }),
  angle: theme.traits.angleBackground(angleSrc, {
    transform: 'scale(-1, -1)',
    right: 0,
    bottom: 0,
  }),
}))

interface CompanySustainabilityPageData extends CompanySustainabilityPageQuery {
  entry: CompanySustainabilityPageEntry | null
  stories: Array<StoryPreviewEntry | null> | null
  news: Array<NewsPreviewEntry | null> | null
}

export const CompanySustainabilityPageContext = createContext<
  CompanySustainabilityPageData | undefined
>(undefined)

export const CompanySustainabilityPage: React.FC = () => {
  const theme = useTheme<Theme>()
  const classes = useStyles()
  const { data, isLoading } = useQuery('company/sustainability', () =>
    fetchApiData<CompanySustainabilityPageData>('companysustainability'),
  )
  const contentWithMedia = data?.entry?.contentWithMedia

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  if (isLoading) return <Loading />
  return (
    <CompanySustainabilityPageContext.Provider value={data}>
      <Breadcrumbs />
      <SubPageHeader
        heading={data?.entry?.pageHeading ?? ''}
        subheading={data?.entry?.subheading ?? ''}
        headingColor={theme.colors.navy}
        headingBackgroundColor={theme.colors.marigold}
      />
      <div className={isNotMobile ? classes.tread : ''}>
        <div className={isNotMobile ? classes.angle : ''}>
          <SustainabilityMetricsSection />
          <SplitContentSection
            contentWithMedia={
              contentWithMedia?.length ? contentWithMedia[0] : undefined
            }
            contentSide="left"
          />
        </div>
      </div>
      <CompanySustainabilityFootprintSection />
      <SplitContentSection
        darkMode
        headingColor={theme.colors.marigold}
        contentWithMedia={
          contentWithMedia?.length && contentWithMedia.length >= 2
            ? contentWithMedia[1]
            : undefined
        }
        contentSide="left"
      />
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
    </CompanySustainabilityPageContext.Provider>
  )
}
