import React from 'react'
import { createUseStyles } from 'react-jss'
import { FeatherIcon } from '../icons/FeatherIcon'

const useStyles = createUseStyles({
  image: {
    backgroundColor: '#E0E0E0',
    paddingTop: ({ aspectRatio }: Props) =>
      `${(1 / (aspectRatio ?? 3 / 2)) * 100}%`,
    position: 'relative',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
})

interface Props {
  aspectRatio?: number
}

export const PlaceholderImage: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.image}>
      <div className={classes.content}>
        <FeatherIcon name="image" color="white" />
      </div>
    </div>
  )
}
