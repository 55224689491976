import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../../components/LayoutContainer'
import { Theme } from '../../../theme'
import { classNames } from '../../../utils'
import { CompanyCulturePageContext } from './CompanyCulturePage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.navy,
  },
  content: {
    rowGap: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing.XXL,
    },
  },
  headerContainer: {
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
  },
  contentItemsContainer: {
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: theme.spacing.XXXL,
    rowGap: theme.spacing.XXL,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  heading: {
    ...theme.typography.h3,
    color: theme.colors.orange,
    textAlign: 'center',
    marginBottom: theme.spacing.M,
  },
  subHeading: {
    ...theme.typography.body,
    textAlign: 'center',
    color: theme.kitColors.grey200,
  },
  contentItemHeading: {
    ...theme.typography.h5,
    color: theme.kitColors.white,
    marginBottom: theme.spacing.S,
  },
  contentItemParagraph: {
    ...theme.typography.smallBody,
    color: theme.kitColors.grey200,
  },
}))

export const CompanyCultureValuesSection: React.FC = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()
  const context = useContext(CompanyCulturePageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classNames(layoutClasses.gridLayout, classes.content)}
    >
      <div className={classes.headerContainer}>
        <h2 className={classes.heading}>
          {context?.entry?.companyCultureValuesHeading}
        </h2>
        <p className={classes.subHeading}>
          {context?.entry?.companyCultureValuesSubheading}
        </p>
      </div>
      <div className={classes.contentItemsContainer}>
        {context?.entry?.companyCultureValues?.map((item, index) => (
          <div key={item?.uid}>
            <h3 className={classes.contentItemHeading}>{item?.valueTitle}</h3>
            <p className={classes.contentItemParagraph}>
              {item?.valueDescription}
            </p>
          </div>
        ))}
      </div>
    </LayoutContainer>
  )
}
