import React from 'react'
import { BodyContent } from '../../components/BodyContent'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { MediaHeader } from '../../components/MediaHeader'
import { TabNavigation } from '../../components/TabNavigation'
import {
  CommunityGrantPage as ICommunityGrantPageQuery,
  CommunityGrantPage_entry_communityGrant_communityGrant_Entry as CommunityGrantEntry,
  CommunityGrantPage_recipients,
} from '../../api-types/CommunityGrantPage'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { communityGrant, communityGrantFAQ } from '../../routes'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { SidebarModule } from './SidebarModule'
import { useLocation } from 'react-router-dom'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'

interface CommunityGrantPageQuery extends ICommunityGrantPageQuery {
  entry: CommunityGrantEntry | null
  recipients: Array<CommunityGrantPage_recipients | null> | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  bodyContent: {
    gridColumn: '1 / span 7',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 5',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
    },
  },
  sidebar: {
    gridColumn: '9 / span 4',
    [theme.breakpoints.down('md')]: {
      gridColumn: '6 / span 3',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
    },
  },
  faqItem: {
    marginBottom: theme.spacing.M,
  },
  question: {
    ...theme.typography.button,
    backgroundColor: theme.colors.blue,
    color: theme.kitColors.white,
    padding: [6, 16],
    borderRadius: 4,
    cursor: 'pointer',
    '&:focus, &:active': {
      outline: '2px dotted black',
    },
  },
  answer: {
    padding: theme.spacing.M,
    paddingBottom: 0,
  },
}))

export const CommunityGrantPage: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const location = useLocation()
  const isFAQ = location.pathname.includes(communityGrantFAQ)

  const { data, isLoading } = useQuery('community-grant', () =>
    fetchApiData<CommunityGrantPageQuery>('community-grant'),
  )

  const headerMedia = data?.entry?.heroImage?.find((m) => m != null)

  if (isLoading) return <Loading />
  return (
    <>
      <Breadcrumbs />
      <MediaHeader
        heading={data?.entry?.title}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <TabNavigation
        routes={[
          { name: 'About', path: communityGrant },
          { name: 'FAQ', path: communityGrantFAQ },
        ]}
      />
      <LayoutContainer
        containerClass={layoutClasses.paddedContainerLarge}
        contentClass={layoutClasses.gridLayout}
      >
        {isFAQ ? (
          <div className={classes.bodyContent}>
            {data?.entry?.faqCollection?.map((faqItem) => (
              <details key={faqItem?.uid} className={classes.faqItem}>
                <summary
                  className={classes.question}
                  aria-controls={[classes.answer, faqItem?.uid].join('_')}
                >
                  {faqItem?.question}
                </summary>
                <BodyContent
                  html={faqItem?.answer ?? ''}
                  className={classes.answer}
                  contentId={[classes.answer, faqItem?.uid].join('_')}
                />
              </details>
            ))}
          </div>
        ) : (
          <BodyContent
            html={data?.entry?.bodyContent ?? ''}
            className={classes.bodyContent}
            contentId=""
          />
        )}

        <div className={classes.sidebar}>
          {data?.entry && (
            <SidebarModule
              entry={data.entry}
              grantRecipients={data.recipients ?? []}
            />
          )}
        </div>
      </LayoutContainer>
    </>
  )
}
