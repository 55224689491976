import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Theme } from '../theme'
import { FeatherIcon } from './icons/FeatherIcon'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowButton: {
    ...theme.traits.clickable,
    padding: 0,
    borderStyle: 'none',
    background: 'none',
    '&:first-child': {
      marginRight: theme.spacing.M,
    },
    '&:last-child': {
      marginLeft: theme.spacing.M,
    },
  },
  pageButton: {
    ...theme.traits.clickable,
    ...theme.typography.button,
    width: 32,
    height: 32,
    padding: 0,
    borderStyle: 'none',
    borderRadius: 2,
    backgroundColor: 'transparent',
    '&:disabled': {
      backgroundColor: theme.colors.blue,
      color: theme.kitColors.white,
    },
    '&:not(:nth-last-child(2))': {
      marginRight: theme.spacing.S,
    },
  },
  spacer: {
    marginRight: theme.spacing.S,
  },
}))

export const Pagination: React.FC<{
  currentIndex: number
  totalPages: number
  onIndexChange: (newIndex: number) => void
}> = ({ currentIndex, totalPages, onIndexChange }) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const sliceStart = Math.max(0, currentIndex - 1)
  const sliceEnd = Math.min(totalPages, currentIndex + 2)
  const nearbyPages = Array(totalPages).fill(true, sliceStart, sliceEnd)

  return (
    <div className={classes.container}>
      <button
        className={classes.arrowButton}
        disabled={currentIndex === 0}
        onClick={() => onIndexChange(Math.max(currentIndex - 1, 0))}
      >
        <FeatherIcon
          name="arrow-left"
          color={
            currentIndex === 0 ? theme.kitColors.grey300 : theme.colors.navy
          }
        />
      </button>
      {currentIndex > 1 && (
        <>
          <button
            className={classes.pageButton}
            onClick={() => onIndexChange(0)}
          >
            1
          </button>
          {currentIndex > 2 && (
            <FeatherIcon
              name="more-horizontal"
              color={theme.kitColors.grey700}
              className={classes.spacer}
            />
          )}
        </>
      )}
      {nearbyPages.map((_, index) => (
        <button
          key={index}
          className={classes.pageButton}
          disabled={index === currentIndex}
          onClick={() => onIndexChange(index)}
        >
          {index === currentIndex && (
            <span className={classes.srOnly} aria-current="page">
              Current page is {index + 1}
            </span>
          )}
          {index + 1}
        </button>
      ))}

      {currentIndex < totalPages - 2 && (
        <>
          {currentIndex < totalPages - 3 && (
            <FeatherIcon
              name="more-horizontal"
              color={theme.colors.navy}
              className={classes.spacer}
            />
          )}

          <button
            className={classes.pageButton}
            onClick={() => onIndexChange(totalPages - 1)}
          >
            {totalPages}
          </button>
        </>
      )}

      <button
        className={classes.arrowButton}
        disabled={currentIndex === totalPages - 1}
        onClick={() =>
          onIndexChange(Math.min(currentIndex + 1, totalPages - 1))
        }
      >
        <FeatherIcon
          name="arrow-right"
          color={
            currentIndex === totalPages - 1
              ? theme.kitColors.grey300
              : theme.colors.navy
          }
        />
      </button>
    </div>
  )
}
