import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { Button } from '../../components/Button'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import angleSrc from '../../assets/patterns/Angle-White.svg'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  container: (props: Props) =>
    theme.traits.treadBackground(treadSrc, {
      backgroundColor: props.backgroundColor ?? theme.colors.raisin,
      transform: 'scale(-1, -1)',
      bottom: -430,
      right: -120,
      opacity: 0.3,
    }),
  containerNoBG: (props: Props) => ({
    backgroundColor: props.backgroundColor ?? theme.colors.raisin,
  }),
  content: {
    [theme.breakpoints.up('sm')]: {
      ...theme.traits.angleBackground(angleSrc, {
        transform: `scale(-1, -1)`,
        bottom: -120,
        right: -40,
      }),
    },
    '& > *': {
      gridColumn: '4 / -4',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        gridColumn: '2 / -2',
      },
      [theme.breakpoints.down('sm')]: {
        gridColumn: '1 / -1',
      },
    },
  },
  heading: {
    ...theme.typography.h3,
    marginBottom: theme.spacing.M,
    color: theme.kitColors.white,
  },
  text: {
    ...theme.typography.body,
    marginBottom: theme.spacing.XXL,
    color: theme.kitColors.grey200,
  },
}))

interface Props {
  heading: string | null | undefined
  description?: string | null
  internalLink?: string | null
  externalLink?: string | null
  buttonText?: string | null
  backgroundColor?: string
}

export const CTAFooterSection: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const layoutClasses = useLayoutStyles()
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  return (
    <LayoutContainer
      containerClass={[
        layoutClasses.paddedContainerLarge,
        isNotMobile ? classes.container : classes.containerNoBG,
      ].join(' ')}
      contentClass={classNames(layoutClasses.gridLayout, classes.content)}
    >
      <h2 className={classes.heading}>{props.heading}</h2>
      <p className={classes.text}>{props.description}</p>
      {props.internalLink && (
        <Link to={props.internalLink ?? ''}>
          <Button inverted>{props.buttonText}</Button>
        </Link>
      )}
      {props.externalLink && (
        <Link to={{ pathname: props.externalLink }} target="_blank">
          <Button inverted>{props.buttonText}</Button>
        </Link>
      )}
    </LayoutContainer>
  )
}
