import React, { Fragment, useMemo } from 'react'
import { RouteLabels } from '../routes'
import { useLocation, NavLink } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { FeatherIcon } from './icons/FeatherIcon'
import { LayoutContainer } from './LayoutContainer'

interface Props {
  entries?: {
    routeUrl: string
    label: string
  }[]
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.kitColors.grey100,
  },
  content: {
    '& nav': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing.XS + theme.spacing.S,
      paddingBottom: theme.spacing.XS + theme.spacing.S,
      flexShrink: 0,
      '& > svg': {
        stroke: theme.colors.navy,
      },
      '& > svg:first-child': {
        marginRight: theme.spacing.S,
      },
      '& > svg:not(:first-child)': {
        marginRight: theme.spacing.M,
        marginLeft: theme.spacing.M,
      },
    },
  },
  navLink: {
    ...theme.typography.menuLink,
    color: theme.colors.navy,
    textDecoration: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  activeNavLink: {
    color: theme.colors.blue,
    fontWeight: 400,
  },
}))

export const Breadcrumbs: React.FC<Props> = ({ entries }) => {
  const { pathname } = useLocation()
  const classes = useStyles()

  const routeEntries = useMemo(() => {
    return (
      entries ??
      Object.entries(RouteLabels)
        .filter(([routeUrl]) => pathname.indexOf(routeUrl) === 0)
        .sort(([routeA], [routeB]) => (routeA.length > routeB.length ? 1 : -1))
        .map(([routeUrl, label]) => ({ routeUrl, label }))
    )
  }, [pathname, entries])

  return (
    <LayoutContainer
      containerClass={classes.container}
      contentClass={classes.content}
    >
      <nav role="region" aria-label="breadcrumbs">
        <FeatherIcon name={'home'} />
        {routeEntries.map(({ routeUrl, label }, index) => (
          <Fragment key={index}>
            <NavLink
              exact
              to={routeUrl}
              className={classes.navLink}
              activeClassName={classes.activeNavLink}
              isActive={() => index === routeEntries.length - 1}
            >
              {label}
            </NavLink>
            {index !== routeEntries.length - 1 && (
              <FeatherIcon name={'chevron-right'} />
            )}
          </Fragment>
        ))}
      </nav>
    </LayoutContainer>
  )
}
