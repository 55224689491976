import React, { useEffect } from 'react'
import { BodyContent } from '../../components/BodyContent'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { MediaHeader } from '../../components/MediaHeader'
import { TabNavigation } from '../../components/TabNavigation'
import {
  ScholarshipsPage as IScholarshipsPageQuery,
  ScholarshipsPage_header_scholarships_scholarshipsPageHeader_Entry as HeaderEntry,
  ScholarshipsPage_entries_scholarships_scholarship_Entry as ScholarshipEntry,
} from '../../api-types/ScholarshipsPage'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { scholarships } from '../../routes'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SidebarModule } from './SidebarModule'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { Loading } from '../../components/Loading'

interface ScholarshipsPageQuery extends IScholarshipsPageQuery {
  header: HeaderEntry | null
  entries: Array<ScholarshipEntry | null> | null
}

const useStyles = createUseStyles((theme: Theme) => ({
  bodyContent: {
    gridColumn: '1 / span 7',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 5',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
    },
  },
  sidebar: {
    gridColumn: '9 / span 4',
    [theme.breakpoints.down('md')]: {
      gridColumn: '6 / span 3',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / -1',
    },
  },
}))

export const ScholarshipsPage: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const history = useHistory()
  const {
    params: { slug },
  } = useRouteMatch<{ slug?: string }>()
  const { data, isLoading } = useQuery('scholarships', () =>
    fetchApiData<ScholarshipsPageQuery>('scholarships'),
  )

  useEffect(() => {
    if (!data || slug) return
    const newSlug = (data.entries ?? [])[0]?.slug
    if (!newSlug) return
    history.replace(scholarships.replace(':slug?', newSlug))
  }, [data, history, slug])

  const headerMedia = data?.header?.heroImage?.find((m) => m != null)
  const focusedEntry = slug
    ? data?.entries?.find((entry) => entry?.slug === slug)
    : (data?.entries ?? [])[0]

  if (isLoading) return <Loading />
  return (
    <>
      <Breadcrumbs
        entries={[
          { routeUrl: '/', label: 'Home' },
          { routeUrl: '/scholarships/', label: 'Scholarships' },
        ]}
      />
      <MediaHeader
        heading={data?.header?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
      />
      <TabNavigation
        routes={
          data?.entries?.map((item) => ({
            name: item?.title ?? 'Scholarship Type',
            path: scholarships.replace(':slug?', item?.slug ?? ''),
          })) ?? []
        }
      />
      <LayoutContainer
        containerClass={layoutClasses.paddedContainerLarge}
        contentClass={layoutClasses.gridLayout}
      >
        <BodyContent
          html={focusedEntry?.bodyContent ?? ''}
          className={classes.bodyContent}
        />
        <div className={classes.sidebar}>
          {focusedEntry && <SidebarModule entry={focusedEntry} />}
        </div>
      </LayoutContainer>
    </>
  )
}
