import React, { Fragment, useContext, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Link, useLocation } from 'react-router-dom'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { Button } from '../../components/Button'
import { classNames } from '../../utils'
import { DoingBusinessWithQPageContext } from './DoingBusinessWithQPage'
import { Media } from '../../components/Media'
import treadSrc from '../../assets/patterns/Tread-Raisin.svg'
import angleSrc from '../../assets/patterns/Angle-Orange.svg'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.treadBackground(treadSrc, {
      transform: 'scale(-1, -1)',
      right: -80,
      bottom: -328,
    }),
  },
  content: theme.traits.angleBackground(angleSrc, {
    transform: 'scale(-1, -1)',
    right: 0,
    bottom: 0,
  }),
  headerContainer: {
    marginBottom: theme.spacing.XXXL,
    gridColumn: '1 / span 8',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 6',
      marginBottom: theme.spacing.XXL,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
  },
  splitLayoutContainer: {
    gridColumn: 'span 12',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 8',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
  },
  h3: {
    ...theme.typography.h3,
    marginBottom: theme.spacing.M,
  },
  h5: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.M,
  },
  smallBody: {
    ...theme.typography.smallBody,
    color: theme.kitColors.grey700,
  },
  text: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
  },
  iconTextContainer: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    rowGap: theme.spacing.M,
    columnGap: theme.spacing.M,
    marginBottom: theme.spacing.XXL,
  },
  icon: {
    marginTop: theme.spacing.XS,
  },
  link: {
    ...theme.traits.clickable,
    ...theme.typography.menuLink,
    textDecoration: 'none',
    color: theme.colors.blue,
    marginLeft: theme.spacing.L,
  },
  linkRow: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface locationState {
  scrollTo: string
}

export const DoingBusinessWithQSupplierRequirementsSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(DoingBusinessWithQPageContext)

  const location = useLocation<locationState>()?.state
  let supplierRef: HTMLDivElement | null = null
  useEffect(() => {
    if (location?.scrollTo === 'supplier') {
      supplierRef?.scrollIntoView({ behavior: 'smooth' })
    }
  })

  const requirements =
    context?.entry?.supplierSectionRequirements?.split('\n') ?? []
  const media = context?.entry?.supplierSectionMedia?.find((img) => img != null)

  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })

  return (
    <>
      <div
        ref={(ref) => {
          supplierRef = ref
        }}
      />
      <LayoutContainer
        containerClass={[
          layoutClasses.paddedContainerLarge,
          isNotMobile ? classes.container : '',
        ].join(' ')}
        contentClass={[
          layoutClasses.gridLayout,
          isNotMobile ? classes.content : '',
        ].join(' ')}
      >
        <div className={classes.headerContainer}>
          <h2 className={classes.h3}>
            {context?.entry?.supplierSectionHeader}
          </h2>
          <p className={classes.text}>
            {context?.entry?.supplierSectionDescription}
          </p>
        </div>
        <div
          className={classNames(
            classes.splitLayoutContainer,
            layoutClasses.splitLayoutLeftContent,
          )}
        >
          <div>
            <h3 className={classes.h5}>
              {context?.entry?.supplierSectionSubheading}
            </h3>
            <div className={classes.iconTextContainer}>
              {requirements.map((text, index) => (
                <Fragment key={index}>
                  <FeatherIcon name={'check'} className={classes.icon} />
                  <p className={classes.smallBody}>{text}</p>
                </Fragment>
              ))}
            </div>
            <div className={classes.linkRow}>
              <Link
                to={{
                  pathname: context?.entry?.supplierSectionStartNowLink ?? '',
                }}
                target="_blank"
              >
                <Button>Suppliers Start Now</Button>
              </Link>
              <a href="/contact" className={classes.link}>
                Questions?
              </a>
            </div>
          </div>
          <Media
            src={media?.url}
            type={media?.mimeType}
            alt={media?.altText}
            credit={media?.credit}
          />
        </div>
      </LayoutContainer>
    </>
  )
}
