import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { ContactUsPageContext } from './ContactPage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.kitColors.grey100,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  h3: {
    ...theme.typography.h3,
  },
  contactsContainer: {
    columnGap: theme.spacing.XXXL,
    rowGap: theme.spacing.XXXL,
    marginTop: theme.spacing.XXL,
    marginLeft: (theme.spacing.XXXL - theme.spacing.L) / 2,
    marginRight: (theme.spacing.XXXL - theme.spacing.L) / 2,
    '& > *': {
      borderTop: [2, 'solid', theme.colors.orange],
      gridColumn: 'span 4',
    },
    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing.XXL,
      rowGap: theme.spacing.XXL,
    },
  },
  itemHeading: {
    ...theme.typography.h5,
    gridColumn: '1 / span 2',
    paddingTop: theme.spacing.M,
  },
  itemDescription: {
    ...theme.typography.menuLink,
    color: theme.colors.blue,
    display: 'flex',
    flexDirection: 'column',
    '& > a': {
      ...theme.typography.menuLink,
      textDecoration: 'none',
      color: theme.colors.blue,
    },
  },
  iconTextContainer: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    rowGap: theme.spacing.M,
    columnGap: theme.spacing.S,
    gridAutoRows: 'min-content',
  },
  detail: {
    paddingTop: theme.spacing.S,
    color: theme.kitColors.grey700,
  },
  icon: {
    marginTop: theme.spacing.XS / 2,
    stroke: theme.colors.blue,
  },
}))

export const ContactLocationSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(ContactUsPageContext)

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={classes.content}
    >
      <h2 className={classes.h3}>{context?.entry?.locationHeading}</h2>
      <div
        className={classNames(
          layoutClasses.gridLayout,
          classes.contactsContainer,
        )}
      >
        {context?.entry?.locations?.map((location) => {
          const address = location?.address?.split('\n') ?? []
          const details = location?.details?.split('\n') ?? []
          return (
            <article className={classes.iconTextContainer} key={location?.uid}>
              <h3 className={classes.itemHeading}>{location?.locationName}</h3>
              {location?.address && (
                <>
                  <FeatherIcon name={'map-pin'} className={classes.icon} />
                  <p className={classes.itemDescription}>
                    {address.map((addressLine, index) => (
                      <span key={index}>{addressLine}</span>
                    ))}
                  </p>
                </>
              )}

              {location?.phone && (
                <>
                  <a href={'tel:+' + (location?.phone).replace(/-/g, '') ?? ''}>
                    <FeatherIcon name={'phone'} className={classes.icon} />
                  </a>
                  <p className={classes.itemDescription}>
                    <a
                      href={'tel:+' + (location?.phone).replace(/-/g, '') ?? ''}
                    >
                      {location?.phone}
                    </a>
                    {details.map((detail, i) => (
                      <span className={classes.detail} key={i}>
                        {detail}
                      </span>
                    ))}
                  </p>
                </>
              )}

              {location?.email && (
                <>
                  <a href={'mailto:' + location?.email ?? ''}>
                    <FeatherIcon name={'mail'} className={classes.icon} />
                  </a>
                  <p className={classes.itemDescription}>
                    <a href={'mailto:' + location?.email ?? ''}>
                      {location?.email}
                    </a>
                  </p>
                </>
              )}
            </article>
          )
        })}
      </div>
    </LayoutContainer>
  )
}
