import React from 'react'
import { ScholarshipsPage_entries_scholarships_scholarship_Entry as ScholarshipEntry } from '../../api-types/ScholarshipsPage'
import {
  CommunityGrantPage_entry_communityGrant_communityGrant_Entry as CommunityGrantEntry,
  CommunityGrantPage_recipients,
} from '../../api-types/CommunityGrantPage'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { Link } from 'react-router-dom'
import { format, isAfter, isBefore, parseISO } from 'date-fns'
import { Button } from '../../components/Button'
import { communityGrantRecipient } from '../../routes'

export const SidebarModule: React.FC<{
  entry: ScholarshipEntry | CommunityGrantEntry
  grantRecipients?: Array<CommunityGrantPage_recipients | null>
}> = ({ entry, grantRecipients }) => {
  const classes = useStyles()

  let isOpen = false
  let formattedDateRange = ''
  let startDate = undefined

  // toggle to help hide page content, can be removed or set to false if we reopen the program
  const allAppsClosedOverride = true
  if (!allAppsClosedOverride) {
    if (entry.__typename === 'scholarships_scholarship_Entry') {
      startDate = entry?.applicationPeriodStart
        ? parseISO(entry.applicationPeriodStart)
        : undefined

      const endDate = entry?.applicationPeriodEnd
        ? parseISO(entry.applicationPeriodEnd)
        : undefined

      if (startDate && endDate) {
        isOpen = isBefore(startDate, new Date()) && isAfter(endDate, new Date())

        formattedDateRange = `${format(startDate, 'MMMM d')}-${format(
          endDate,
          'MMMM d, y',
        )}`
      }
    } else if (entry.__typename === 'communityGrant_communityGrant_Entry') {
      if (entry?.applicationLink && entry?.applicationCopy) {
        isOpen = true
      }
    }
  }

  return (
    <div className={classes.sidebarContent}>
      <div className={classes.sidebarSection}>
        <h2 className={classes.sidebarHeading}>Application</h2>
        {isOpen ? (
          <div>
            <p className={classes.sidebarParagraph}>{entry.applicationCopy}</p>
            {entry.applicationLink &&
              entry.applicationLink !== 'http://#' &&
              entry.applicationLink !== 'https://#' && (
                <a
                  href={entry.applicationLink ?? '#'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>Apply Now</Button>
                </a>
              )}
          </div>
        ) : (
          <div>
            <p className={classes.sidebarParagraph}>
              We are not accepting applications at this time.
              {startDate && ' Application period opens:'}
            </p>

            <p className={classes.dateRange}>{formattedDateRange}</p>
          </div>
        )}
      </div>
      <div className={classes.sidebarSection}>
        <h2 className={classes.sidebarHeading}>Questions?</h2>
        <p className={classes.sidebarParagraph}>
          {entry.applicationQuestionsCopy}
        </p>
        <a className={classes.link} href={`mailto:${entry.applicationContact}`}>
          {entry.applicationContact}
        </a>
      </div>
      {entry.__typename === 'communityGrant_communityGrant_Entry' &&
        grantRecipients && (
          <div className={classes.sidebarSection}>
            <h2 className={classes.sidebarHeading}>Past Grant Recipients</h2>
            <p className={classes.sidebarParagraph}>
              {entry.pastGrantRecipientsCopy}
            </p>
            <ul className={classes.linkList}>
              {grantRecipients.map((recipient) => (
                <li key={recipient?.uid} style={{ display: 'block' }}>
                  <Link
                    className={classes.link}
                    to={communityGrantRecipient.replace(
                      ':slug',
                      recipient?.slug ?? '',
                    )}
                  >
                    {recipient?.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  )
}

const useStyles = createUseStyles((theme: Theme) => ({
  sidebarContent: {
    backgroundColor: theme.kitColors.grey100,
    borderRadius: 4,
    padding: [
      theme.spacing.XXL,
      theme.spacing.L,
      theme.spacing.L,
      theme.spacing.L,
    ],
  },
  sidebarSection: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing.XXXL,
    },
  },
  sidebarHeading: {
    ...theme.typography.h4,
    marginBottom: theme.spacing.S,
  },
  sidebarParagraph: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    '&:not(:last-child)': {
      marginBottom: theme.spacing.L,
    },
  },
  dateRange: {
    ...theme.typography.bodyEm,
    color: theme.kitColors.grey700,
  },
  link: {
    ...theme.typography.menuLink,
    ...theme.traits.clickable,
    padding: [6, 0],
    color: theme.colors.blue,
    textDecoration: 'none',
  },
  linkList: {
    '& > li': {
      padding: [6, 0],
    },
  },
}))
