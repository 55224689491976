import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import * as Routes from '../routes'

type Media = {
  url: string | null
  mimeType: string | null
  credit: string | null
  altText: string | null
  srcset: string | null
}

export const Seo: React.FC<{
  pageTitle?: string | null
  image?: Media | null
  description?: string
}> = ({ pageTitle, image, description, children }) => {
  const { pathname } = useLocation()
  const title = pageTitle ?? Routes.RouteLabels[pathname]
  const metaTitle = `${title ? `${title} | ` : ''}Quality Bicycle Products`
  const pageUrl = `https://www.qbp.com${pathname}`

  return (
    <Helmet defaultTitle="Quality Bicycle Products">
      <title>{metaTitle}</title>
      <link rel="canonical" href={pageUrl} />
      <meta property="og:site_name" content="Quality Bicycle Products" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={pageUrl} />
      {image && (
        <meta
          property="og:image"
          content={image?.mimeType?.includes('image') ? image.url ?? '' : ''}
        />
      )}
      {description && <meta property="og:description" content={description} />}
      {children}
    </Helmet>
  )
}
