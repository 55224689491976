import React, { useEffect, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useQuery } from 'react-query'
import {
  BrandsDirectoryPage as BrandsDirectoryPageQuery,
  BrandsDirectoryPageVariables,
  BrandsDirectoryPage_entries_brandsDirectory_brandsDirectory_Entry as BrandsDirectoryEntry,
  BrandsDirectoryPage_header_brandsDirectory_brandsDirectoryHeader_Entry as BrandsDirectoryHeader,
} from '../../api-types/BrandsDirectoryPage'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Loading } from '../../components/Loading'
import { Pagination } from '../../components/Pagination'
import { Theme } from '../../theme'
import { classNames, fetchApiData } from '../../utils'
import { SubPageHeader } from '../common-sections/SubPageHeader'

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  none: {
    display: 'none',
  },
  fieldset: {
    border: 'none',
    [theme.breakpoints.down('md')]: {
      float: 'left',
      paddingLeft: '0',
    },
  },
  container: {
    backgroundColor: theme.kitColors.grey100,
  },
  header: {
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  heading: {
    ...theme.typography.h3,
  },
  filterLabel: {
    ...theme.typography.overline,
    color: theme.colors.blue,
    display: 'flex',
    alignItems: 'center',
  },
  filterSelect: {
    ...theme.typography.smallBody,
    marginLeft: theme.spacing.M,
    color: theme.colors.navy,
    height: 48,
    paddingLeft: theme.spacing.M,
    paddingRight: theme.spacing.M,
    borderStyle: 'none',
    borderRadius: 2,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  gridContainer: {
    gap: 4,
    marginBottom: theme.spacing.XXL,
    '& > span': {
      ...theme.typography.smallBodyEm,
      color: theme.colors.navy,
      padding: theme.spacing.M,
      gridColumn: 'span 3',
      [theme.breakpoints.down('md')]: {
        gridColumn: 'span 4',
      },
      backgroundColor: theme.kitColors.white,
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

interface BrandsDirectoryQuery extends BrandsDirectoryPageQuery {
  entries: BrandsDirectoryEntry[] | null
  header: BrandsDirectoryHeader | null
}

type BrandsDirectoryQueryVariables = Omit<
  BrandsDirectoryPageVariables,
  'category' | 'orderBy'
> & {
  categoryId?: string
  order?: string
}

const brandsPerPage = 20

export const BrandsDirectoryPage: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const theme = useTheme<Theme>()

  const [page, setPage] = useState(0)
  const [descOrder, setDescOrder] = useState(false)

  useEffect(() => {
    setPage(0)
  }, [])

  const { data, isLoading, isPreviousData } = useQuery(
    ['brands-directory', page, descOrder],
    () =>
      fetchApiData<BrandsDirectoryQuery, BrandsDirectoryQueryVariables>(
        'brands-directory',
        {
          query: {
            offset: page * brandsPerPage,
            limit: brandsPerPage,
            order: descOrder ? 'desc' : 'asc',
          },
        },
      ),
    { keepPreviousData: true },
  )

  const totalPages = Math.ceil((data?.total ?? 0) / brandsPerPage)

  if (isLoading || isPreviousData) return <Loading />
  return (
    <>
      <Breadcrumbs />
      <SubPageHeader
        heading={data?.header?.pageHeading}
        subheading={data?.header?.subheading}
        headingBackgroundColor={theme.colors.orange}
      />
      <LayoutContainer
        containerClass={classNames(
          layoutClasses.paddedContainerLarge,
          classes.container,
        )}
      >
        <div role="region" aria-label="filter">
          <div className={classes.header}>
            <h2 className={classes.heading}>Brands List</h2>
            <fieldset className={classes.fieldset}>
              <legend className={classes.srOnly}>Filter the Results:</legend>
              <label
                className={classes.filterLabel}
                htmlFor={classes.filterSelect}
              >
                Filter by:{' '}
                <select
                  className={classes.filterSelect}
                  id={classes.filterSelect}
                  onChange={(e) =>
                    setDescOrder(e.target.value === 'desc' ? true : false)
                  }
                >
                  <option value="asc">Alphabetical A-Z</option>
                  <option value="desc">Alphabetical Z-A</option>
                </select>
              </label>
              <div className={[classes.none, classes.srOnly].join(' ')}>
                Making a selection from this drop-down box will sort results
                alphabetically in ascending or descending order.
              </div>
            </fieldset>
          </div>
          <div
            className={classNames(
              layoutClasses.gridLayout,
              classes.gridContainer,
            )}
          >
            {data && (
              <>
                {data?.entries?.map((entry) => {
                  return <span key={entry.id}>{entry.title}</span>
                })}
              </>
            )}
          </div>
          <div
            className={classes.paginationContainer}
            role="region"
            aria-label="pagination links"
          >
            <Pagination
              currentIndex={page}
              totalPages={totalPages}
              onIndexChange={setPage}
            />
          </div>
        </div>
      </LayoutContainer>
    </>
  )
}
