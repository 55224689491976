import React from 'react'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { InnerPageContent } from '../common-sections/InnerPageContent'
import {
  BCorpPage as IBCorpPageQuery,
  BCorpPage_entry_bCorp_bCorp_Entry as BCorpPageEntry,
} from '../../api-types/BCorpPage'
import { BodyContent } from '../../components/BodyContent'
import { Loading } from '../../components/Loading'

interface BCorpPageQuery extends IBCorpPageQuery {
  entry: BCorpPageEntry | null
}

export const BCorpPage: React.FC = () => {
  const { data, isLoading } = useQuery('b-corp', () =>
    fetchApiData<BCorpPageQuery>('b-corp'),
  )

  const headerMedia = data?.entry?.heroImage?.find((item) => item != null)

  if (isLoading) return <Loading />
  return (
    <InnerPageContent
      headerProps={{
        heading: data?.entry?.pageHeading,
        backgroundSrc: headerMedia?.url,
        backgroundType: headerMedia?.mimeType,
      }}
    >
      {data?.entry?.bodyContent && (
        <BodyContent html={data.entry.bodyContent} />
      )}
    </InnerPageContent>
  )
}
