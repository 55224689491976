import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { BrandsPageContext } from './BrandsPage'
import treadSrc from '../../assets/patterns/Tread-Blue.svg'
import angleSrc from '../../assets/patterns/Angle-Orange.svg'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.kitColors.grey100,
    ...theme.traits.treadBackground(treadSrc, {
      backgroundColor: theme.kitColors.grey100,
      transform: 'scale(-1, -1)',
      right: 0,
      bottom: -293,
      zIndex: 0,
    }),
  },
  angle: theme.traits.angleBackground(angleSrc, {
    transform: 'scale(-1, -1)',
    bottom: 0,
    right: 0,
    zIndex: 0,
  }),
  heading: {
    ...theme.typography.h3,
    textAlign: 'center',
    marginBottom: theme.spacing.M,
    gridColumn: '1 / -1',
  },
  subheading: {
    ...theme.typography.body,
    textAlign: 'center',
    color: theme.kitColors.grey700,
    marginBottom: theme.spacing.XXXL,
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
  },
  listItem: {
    gridColumn: 'span 12',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gridColumn: 'span 8',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing.XXL,
    },
    '& > *': {
      zIndex: 1,
    },
  },
  cardBackground: {
    gridRow: 1,
    gridColumnStart: 3,
    gridColumnEnd: -1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileCardImage: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      width: '100%',
      height: 'auto',
      gridRow: 1,
      gridColumnStart: 1,
      gridColumnEnd: 12,
    },
  },
  cardContent: {
    gridRow: 1,
    gridColumnStart: 1,
    gridColumnEnd: 6,
    // TODO: should be 80 / XXXL?
    marginTop: 70,
    marginBottom: 70,
    backgroundColor: theme.kitColors.white,
    borderRadius: 4,
    padding: theme.spacing.XXL,
    [theme.breakpoints.down('sm')]: {
      gridRow: 2,
      gridColumnEnd: 12,
      marginTop: 0,
    },
  },
  brandLogo: {
    width: 197,
    maxWidth: '100%',
    marginBottom: theme.spacing.XXL,
  },
  cardTitle: {
    ...theme.typography.h5,
    marginBottom: theme.spacing.S,
  },
  cardDescription: {
    ...theme.typography.body,
    color: theme.kitColors.grey700,
    marginBottom: theme.spacing.XXL,
  },
}))

export const BrandsListSection: React.FC = () => {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const context = useContext(BrandsPageContext)

  return (
    <LayoutContainer
      ContentElement="div"
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
    >
      <h2 className={classes.heading}>{context?.entry?.brandListHeading}</h2>
      <p className={classes.subheading}>
        {context?.entry?.brandListSubheading}
      </p>
      <ul className={classNames(layoutClasses.gridLayout, classes.angle)}>
        {context?.entry?.brandList?.map((brand) => {
          if (!brand) return null
          const logo = brand.logo?.find((asset) => asset != null)
          const background = brand.background?.find((asset) => asset != null)
          return (
            <li
              key={brand.uid}
              className={classNames(layoutClasses.gridLayout, classes.listItem)}
              role="group"
              aria-label="article"
            >
              <div
                style={{
                  backgroundImage: `url(${background?.url ?? ''})`,
                }}
                className={classes.cardBackground}
              />
              <img
                className={classes.mobileCardImage}
                src={background?.url ?? ''}
                alt={background?.altText ?? ''}
              />
              <div className={classes.cardContent}>
                <img
                  className={classes.brandLogo}
                  src={logo?.url ?? undefined}
                  srcSet={logo?.srcset ?? undefined}
                  alt={logo?.altText ?? 'brand logo'}
                />
                <h3 className={classes.cardTitle}>{brand.heading}</h3>
                <p className={classes.cardDescription}>{brand.subheading}</p>
                {brand.website && (
                  <ArrowLink to={{ pathname: brand.website }} target="_blank">
                    Go to Website
                  </ArrowLink>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </LayoutContainer>
  )
}
