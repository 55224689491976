import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom'
import { fetchApiData } from '../utils'
import { InnerPageContent } from './common-sections/InnerPageContent'
import {
  InnerPage as IInnerPageQuery,
  InnerPage_entry_innerPages_innerPage_Entry as InnerPageEntry,
} from '../api-types/InnerPage'
import { BodyContent } from '../components/BodyContent'
import { Loading } from '../components/Loading'

interface InnerPageQuery extends IInnerPageQuery {
  entry: InnerPageEntry | null
}

export const InnerPage: React.FC = () => {
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>()
  const { pathname } = useLocation()
  const pathPrefix = useMemo(
    () => pathname.slice(1, pathname.lastIndexOf('/')),
    [pathname],
  )

  const { data, isFetched, isLoading } = useQuery(['inner', slug], () =>
    fetchApiData<InnerPageQuery>(`inner/${slug}`),
  )

  const headerMedia = useMemo(
    () => (data?.entry?.heroImage ?? [])[0] ?? undefined,
    [data],
  )

  if (isLoading) return <Loading />

  if (
    (isFetched && !data?.entry) ||
    (data?.entry?.pathPrefix && data.entry.pathPrefix !== pathPrefix)
  ) {
    console.warn(`invalid inner page prefix "${pathPrefix}"`)

    return <Redirect to={pathname.slice(0, pathname.lastIndexOf('/'))} />
  }

  return (
    <InnerPageContent
      headerProps={{
        heading: data?.entry?.heading,
        backgroundSrc: headerMedia?.url,
        backgroundType: headerMedia?.mimeType,
      }}
    >
      <BodyContent html={data?.entry?.bodyContent ?? ''} />
    </InnerPageContent>
  )
}
