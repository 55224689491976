import React, { createContext } from 'react'
import { HomeBCorpSection } from './HomeBCorpSection'
import { HomeProductSection } from './HomeProductSection'
// import { HomeUofQSection } from './HomeUofQSection'
import { StoriesAndNewsSection } from '../common-sections/StoriesAndNewsSection'
import { CTAFooterSection } from '../common-sections/CTAFooterSection'
import {
  HomePage as IHomePageQuery,
  HomePage_entry_home_home_Entry as HomePageEntry,
  HomePage_stories_stories_story_Entry as StoryPreviewEntry,
  HomePage_news_news_news_Entry as NewsPreviewEntry,
} from '../../api-types/HomePage'
import { useQuery } from 'react-query'
import { fetchApiData } from '../../utils'
import { MediaHeader } from '../../components/MediaHeader'
import { createUseStyles } from 'react-jss'
import { theme } from '../../theme'
import { Seo } from '../../components/Seo'
import { Loading } from '../../components/Loading'

interface HomePageQuery extends IHomePageQuery {
  entry: HomePageEntry | null
  stories: Array<StoryPreviewEntry | null> | null
  news: Array<NewsPreviewEntry | null> | null
}

export const HomePageContext = createContext<HomePageQuery | undefined>(
  undefined,
)

const useStyles = createUseStyles({
  header: {
    height: 720,
    [theme.breakpoints.down('sm')]: {
      height: 420,
    },
  },
})

export const HomePage: React.FC = () => {
  const classes = useStyles()
  const { data, isLoading } = useQuery('home', () =>
    fetchApiData<HomePageQuery>('home'),
  )
  const headerMedia = data?.entry?.heroImage?.find((img) => img != null)

  if (isLoading) return <Loading />
  return (
    <HomePageContext.Provider value={data}>
      <Seo pageTitle={''} />
      <MediaHeader
        heading={data?.entry?.pageHeading}
        backgroundSrc={headerMedia?.url}
        backgroundType={headerMedia?.mimeType}
        className={classes.header}
      />
      <HomeBCorpSection />
      <HomeProductSection />
      {/* TEMPORARILY DISABLED (UofQ)
      <HomeUofQSection />
      */}
      <StoriesAndNewsSection
        heading={data?.entry?.storiesAndNewsSectionHeading}
        stories={data?.stories}
        news={data?.news}
      />
      <CTAFooterSection
        heading={data?.entry?.ctaFooterHeading}
        description={data?.entry?.ctaFooterDescription}
        internalLink={data?.entry?.ctaFooterInternalLink}
        buttonText={data?.entry?.ctaFooterButtonText}
      />
    </HomePageContext.Provider>
  )
}
