import React from 'react'
import { useTheme, createUseStyles } from 'react-jss'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import {
  NewsDetailPage as INewsDetailPageQuery,
  NewsDetailPageVariables,
  NewsDetailPage_entry_news_news_Entry as NewsEntry,
  NewsDetailPage_moreNews_news_news_Entry as NewsPreviewEntry,
} from '../../api-types/NewsDetailPage'
import { BackButton } from '../../components/BackButton'
import { BodyContent } from '../../components/BodyContent'
import { Facebook } from '../../components/icons/Facebook'
import { LinkedIn } from '../../components/icons/LinkedIn'
import { Twitter } from '../../components/icons/Twitter'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames, fetchApiData, VariablesFix } from '../../utils'
import { NewsPreview } from '../stories/NewsPreview'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import { format, parseISO } from 'date-fns'
import { Seo } from '../../components/Seo'
import { Loading } from '../../components/Loading'
import { InnerPage } from '../InnerPage'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    ...theme.traits.treadBackground(treadSrc, {
      left: -189,
      top: -383,
    }),
  },
  header: {
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.XXL,
  },
  socialLinks: {
    '& ul': {
      float: 'left',
      listStyle: 'none',
      '& > *': {
        display: 'inline-block',
        marginLeft: theme.spacing.XXL,
      },
    },
  },
  categoryLabel: {
    ...theme.typography.overline,
    gridColumn: '1 / -1',
    textAlign: 'center',
    color: theme.colors.blue,
    marginBottom: theme.spacing.M,
    '& > span': {
      color: theme.kitColors.grey600,
      marginLeft: theme.spacing.S,
    },
  },
  title: {
    ...theme.typography.h1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  titleLayout: {
    gridColumn: '2 / -2',
    marginBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      marginBottom: theme.spacing.XXL,
    },
  },
  articleContainer: {
    gridColumn: '3 / -3',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
    },
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing.XXXL,
    marginRight: -theme.spacing.M,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing.XXXL,
    },
    '& > li': {
      ...theme.typography.boldCaption,
      color: theme.colors.blue,
      marginRight: theme.spacing.M,
      padding: `6px ${theme.spacing.M}px`,
      backgroundColor: theme.kitColors.grey200,
      borderRadius: 2,
    },
  },
  relatedContentHeading: {
    ...theme.typography.h3,
  },
  relatedContentHeadingLayout: {
    gridColumn: '1 / -1',
    marginTop: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing.XXL,
    },
    marginBottom: theme.spacing.XXL,
  },
  relatedContentItem: {
    gridColumn: 'span 4',
  },
  moreNews: {
    gridColumn: '1 / -1',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  moreNewsItem: {
    flex: 1,
    marginLeft: theme.spacing.XXL,
    marginRight: theme.spacing.XXL,
    '&:not(:last-child)': {
      marginBottom: 0,
    },
    '&:first-child': {
      marginLeft: theme.spacing.L,
    },
    '&:last-child': {
      marginRight: theme.spacing.L,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: [0, '!important'],
      marginRight: [0, '!important'],
      '&:not(:last-child)': {
        marginBottom: theme.spacing.L,
      },
    },
  },
}))

interface NewsDetailPageQuery extends INewsDetailPageQuery {
  entry: NewsEntry | null
  moreNews: Array<NewsPreviewEntry | null> | null
}

export const NewsDetailPage: React.FC = () => {
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>()

  const { data, isLoading } = useQuery(['news-item', slug], () =>
    fetchApiData<NewsDetailPageQuery, VariablesFix<NewsDetailPageVariables>>(
      `news/${slug}`,
    ),
  )

  const theme = useTheme<Theme>()
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()

  if (isLoading) return <Loading />

  if (!isLoading && !data?.entry) {
    return <InnerPage />
  }

  return (
    <LayoutContainer
      containerClass={classNames(
        layoutClasses.paddedContainerLarge,
        classes.container,
      )}
      contentClass={layoutClasses.gridLayout}
    >
      <Seo pageTitle={data?.entry?.heading} />
      <div className={classes.header}>
        <BackButton />
        <div className={classes.socialLinks}>
          <ul>
            <Facebook color={theme.colors.blue} />
            <Twitter color={theme.colors.blue} />
            <LinkedIn color={theme.colors.blue} />
          </ul>
        </div>
      </div>
      <p className={classes.categoryLabel}>
        {data?.entry?.newsCategory?.find((c) => c != null)?.title}
        {data?.entry?.postDate && (
          <span>{format(parseISO(data.entry.postDate), 'MMMM y')}</span>
        )}
      </p>
      <h1 className={classNames(classes.titleLayout, classes.title)}>
        {data?.entry?.heading}
      </h1>
      <div className={classes.articleContainer}>
        {data?.entry?.bodyContent && (
          <BodyContent html={data.entry.bodyContent} />
        )}
      </div>
      {(data?.moreNews?.length ?? 0) > 0 && (
        <h2
          className={classNames(
            classes.relatedContentHeadingLayout,
            classes.relatedContentHeading,
          )}
        >
          More News
        </h2>
      )}
      <div className={classes.moreNews}>
        {data?.moreNews?.map((item, index) => (
          <NewsPreview
            key={index}
            slug={item?.slug}
            headline={item?.heading}
            category={item?.newsCategory?.find((c) => c != null)?.title}
            date={item?.postDate}
            className={classes.moreNewsItem}
          />
        ))}
      </div>
    </LayoutContainer>
  )
}
