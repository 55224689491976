import React from 'react'
import { createUseStyles } from 'react-jss'
import { ArrowLink } from '../../components/ArrowLink'
import {
  LayoutContainer,
  useLayoutStyles,
} from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { Media } from '../../components/Media'
import treadSrc from '../../assets/patterns/Tread-White.svg'
import angleSrc from '../../assets/patterns/Angle-White.svg'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles((theme: Theme) => ({
  container: (props: Props) => {
    const backgroundColor = props.backgroundColor
      ? props.backgroundColor
      : props.darkMode
      ? theme.colors.navy
      : 'inherit'

    if (!props.darkMode) {
      return { backgroundColor }
    }

    const sideSpecificStyles =
      props.contentSide === 'left'
        ? {
            transform: 'scaleX(-1)',
            right: 0,
          }
        : {
            left: 0,
          }

    return {
      ...theme.traits.treadBackground(treadSrc, {
        backgroundColor,
        top: -284,
        opacity: 0.3,
        ...sideSpecificStyles,
      }),
    }
  },
  containerNoBG: (props: Props) => {
    const backgroundColor = props.backgroundColor
      ? props.backgroundColor
      : props.darkMode
      ? theme.colors.navy
      : 'inherit'

    if (!props.darkMode) {
      return { backgroundColor }
    }

    const sideSpecificStyles =
      props.contentSide === 'left'
        ? {
            transform: 'scaleX(-1)',
            right: 0,
          }
        : {
            left: 0,
          }

    return {
      backgroundColor,
      ...sideSpecificStyles,
    }
  },
  angle: (props: Props) => {
    const sideSpecificStyles =
      props.contentSide === 'left'
        ? {
            transform: 'scaleX(-1)',
            right: 0,
          }
        : {
            left: 0,
          }
    if (!props.darkMode) return {}
    return {
      ...theme.traits.angleBackground(angleSrc, {
        top: 0,
        ...sideSpecificStyles,
      }),
    }
  },
  angleNoBG: (props: Props) => {
    const sideSpecificStyles =
      props.contentSide === 'left'
        ? {
            transform: 'scaleX(-1)',
            right: 0,
          }
        : {
            left: 0,
          }
    if (!props.darkMode) return {}
    return {
      top: 0,
      ...sideSpecificStyles,
    }
  },
  heading: (props: Props) => ({
    ...theme.typography.h3,
    color: props.headingColor
      ? props.headingColor
      : props.darkMode
      ? theme.kitColors.white
      : theme.colors.navy,

    marginBottom: theme.spacing.M,
  }),
  paragraph: (props: Props) => ({
    ...theme.typography.body,
    color: props.darkMode ? theme.kitColors.grey200 : theme.kitColors.grey700,
  }),
  label: (props: Props) => ({
    ...theme.typography.overline,
    color: props.darkMode ? theme.kitColors.white : theme.colors.blue,
    marginBottom: theme.spacing.M,
  }),
  link: (props: Props) => ({
    color: props.darkMode ? theme.kitColors.white : 'auto',
    '& > svg': {
      stroke: props.darkMode ? theme.kitColors.white : 'auto',
    },
    marginTop: theme.spacing.XXL,
  }),
}))

type ContentWithMedia_media = {
  url: string | null
  mimeType: string | null
  credit: string | null
  altText: string | null
  srcset: string | null
}

type ContentWithMedia = {
  uid: string | null
  label: string | null
  heading: string | null
  body: string | null
  internalLink: string | null
  externalLink: string | null
  media: (ContentWithMedia_media | null)[] | null
}

interface Props {
  contentSide: 'left' | 'right'
  className?: string
  darkMode?: boolean
  backgroundColor?: string
  headingColor?: string
  contentWithMedia?: ContentWithMedia | null
}

export const SplitContentSection: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const layoutClasses = useLayoutStyles()
  const isNotMobile = useMediaQuery({
    query: '(min-width: 768px)',
  })
  const contentClass =
    props.contentSide === 'left'
      ? layoutClasses.splitLayoutLeftContent
      : layoutClasses.splitLayoutRightContent
  const media = props.contentWithMedia?.media?.find((item) => item != null)

  const renderContent = () => (
    <div>
      {props.contentWithMedia?.label && (
        <p className={classes.label}>{props?.contentWithMedia?.label}</p>
      )}
      <h2 className={classes.heading}>{props?.contentWithMedia?.heading}</h2>
      <p className={classes.paragraph}>{props?.contentWithMedia?.body}</p>
      {props.contentWithMedia?.internalLink && (
        <ArrowLink
          to={props.contentWithMedia?.internalLink}
          className={classes.link}
        >
          Learn More
        </ArrowLink>
      )}
      {!props.contentWithMedia?.internalLink &&
        props.contentWithMedia?.externalLink && (
          <ArrowLink
            to={{ pathname: props.contentWithMedia.externalLink }}
            target="_blank"
            className={classes.link}
          >
            Learn More
          </ArrowLink>
        )}
    </div>
  )

  return (
    <LayoutContainer
      containerClass={[
        isNotMobile ? classes.container : classes.containerNoBG,
        props.className,
        layoutClasses.paddedContainerLarge,
      ].join(' ')}
      contentClass={[
        contentClass,
        isNotMobile ? classes.angle : classes.angleNoBG,
      ].join(' ')}
    >
      {props.contentSide === 'left' && renderContent()}
      {media && (
        <Media
          src={media.url}
          type={media.mimeType}
          alt={media.altText}
          credit={media.credit}
          srcSet={media.srcset}
        />
      )}
      {props.contentSide === 'right' && renderContent()}
    </LayoutContainer>
  )
}
