import React, { useEffect, useCallback, useState, useRef } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { Theme } from '../theme'
import { LayoutContainer } from './LayoutContainer'
import { FeatherIcon } from './icons/FeatherIcon'
import { Button } from './Button'
import * as routes from '../routes'
import { MobileMenu } from './MobileMenu'
import logoSrc from '../assets/Logo.svg'
import textLogoSrc from '../assets/TextLogo.svg'

const headerBreakpoint = 1312

const useStyles = createUseStyles((theme: Theme) => ({
  srOnly: theme.ada.srOnly,
  srOnlyFocusable: theme.ada.srOnlyFocusable,
  container: {
    zIndex: theme.layers.header,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    height: theme.measurements.headerHeight,
    backgroundColor: theme.kitColors.white,
    boxShadow:
      '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1)',
  },
  content: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  headerNav: {
    width: '100%',
  },
  headerContent: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    '& > li:first-child': {
      marginRight: 'auto',
    },
    '& > li a': {
      ...theme.traits.clickable,
      ...theme.typography.menuLink,
      marginRight: theme.spacing.XL,
      color: 'inherit',
      textDecoration: 'none',
    },
    '& > li a:not([class^="logoLink-"])': {
      [theme.breakpoints.down(headerBreakpoint)]: {
        display: 'none',
      },
    },
    '& > li a[class^="logoLink-"]': {
      [theme.breakpoints.down(headerBreakpoint)]: {
        display: 'block',
      },
    },
    '& > li button': {
      '&:first-of-type': {
        marginRight: theme.spacing.S,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > li': {
      '&:last-of-type': {
        [theme.breakpoints.down(headerBreakpoint)]: {
          marginRight: 50,
        },
      },
    },
  },
  doBusiness: {
    '& > button:focus-visible, & > button:focus': {
      border: '2px dotted #ffffff',
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        border: '2px dotted #2526A9',
      },
    },
  },
  logIn: {
    '& > button:focus-visible, & > button:focus': {
      borderStyle: 'dotted',
      outline: 'none',
    },
  },
  logoLink: {
    marginRight: 'auto',
    display: 'block',
    width: 293,
    height: 48,
    backgroundImage: `url(${textLogoSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: 38,
      height: 48,
      backgroundImage: `url(${logoSrc})`,
    },
  },
  mobileMenuButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    marginTop: 10,
    right: 70,
    backgroundColor: 'transparent',
    borderStyle: 'none',
    [theme.breakpoints.up(headerBreakpoint)]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      right: 32,
      marginLeft: theme.spacing.XXXL,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 13,
    },
    '& span': {
      verticalAlign: 'super',
      fontSize: 16,
    },
    '&:focus': {
      outline: '2px dotted black',
    },
    '& svg': {
      textAlign: 'center',
    },
  },
}))

export const HeaderNav: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const history = useHistory()
  const { pathname } = useLocation()
  const buttonOpenRef = useRef<HTMLButtonElement>(null)
  const escapePressed = useCallback((event) => {
    if (event.keyCode === 27) {
      setMobileMenuOpen(false)
      buttonOpenRef.current?.focus()
    }
  }, [])

  const handleLogin = () =>
    window.open('https://www.qbp.com/qbponlinestorefront/login')

  const handleBusinessClick = () => history.push(routes.doingBusinessWithQ)

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  useEffect(() => {
    if (!mobileMenuOpen) {
      setMobileMenuOpen(false)
      buttonOpenRef.current?.focus()
    }
    document.addEventListener('keydown', escapePressed)
    return () => {
      document.removeEventListener('keydown', escapePressed)
    }
  }, [pathname, escapePressed, mobileMenuOpen])

  const renderContents = () => (
    <>
      <li>
        <a
          aria-label="QBP Home"
          href={routes.home}
          className={classes.logoLink}
        >
          &nbsp;
        </a>
      </li>
      <li>
        <NavLink to={routes.brands}>Brands</NavLink>
      </li>
      <li>
        <NavLink to={routes.company}>Company</NavLink>
      </li>
      {/* TEMPORARILY DISABLED (UofQ)
      <li>
        <NavLink to={routes.uOfQ}>U of Q</NavLink>
      </li>
      */}
      <li>
        <NavLink to={routes.stories}>Stories</NavLink>
      </li>
      <li>
        <NavLink to={routes.news}>News</NavLink>
      </li>
      <li>
        <NavLink to={routes.careers}>Careers</NavLink>
      </li>
      <li className={classes.doBusiness}>
        <Button onClick={handleBusinessClick}>Do Business With Us</Button>
      </li>
      <li className={classes.logIn}>
        <Button variant="outlined" onClick={handleLogin}>
          Log In
        </Button>
      </li>
    </>
  )

  return (
    <>
      <a
        href={`${pathname}#main`}
        className={[classes.srOnly, classes.srOnlyFocusable].join(' ')}
      >
        Skip to main content.
      </a>
      <LayoutContainer
        containerClass={classes.container}
        contentClass={classes.content}
        ContainerElement={'header'}
        ContentElement={'div'}
      >
        <nav className={classes.headerNav} aria-label="primary">
          <ul className={classes.headerContent}>{renderContents()}</ul>
          <button
            ref={buttonOpenRef}
            className={classes.mobileMenuButton}
            onClick={() => setMobileMenuOpen(true)}
          >
            <FeatherIcon name="menu" color={theme.kitColors.grey900} />
            &nbsp;
            <span className={classes.srOnly}>MENU</span>
          </button>
        </nav>
      </LayoutContainer>
      <MobileMenu
        isOpen={mobileMenuOpen}
        active
        onClose={() => setMobileMenuOpen(false)}
      >
        {renderContents()}
      </MobileMenu>
    </>
  )
}
