import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Switch, Route, NavLink } from 'react-router-dom'
import { FeatherIcon } from '../../components/icons/FeatherIcon'
import { useLayoutStyles } from '../../components/LayoutContainer'
import { Theme } from '../../theme'
import { classNames } from '../../utils'
import { SearchBar } from './SearchBar'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  nav: {
    gridColumn: '1 / span 8',
    paddingTop: 5,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 7',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
  },
  navList: {
    gridColumn: '1 / span 10',
    height: theme.spacing.XXL,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / span 7',
      display: (searchOpen) => `${searchOpen ? 'none' : ''}`,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: 'span 4',
    },
    '& > *:not(:last-child)': {
      marginRight: theme.spacing.XXL,
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing.M,
      },
    },
  },
  link: {
    ...theme.typography.menuLink,
    ...theme.traits.clickable,
    whiteSpace: 'nowrap',
    color: theme.colors.navy,
    textDecoration: 'none',
  },
  activeLink: {
    color: theme.colors.blue,
  },
  searchButton: {
    ...theme.traits.clickable,
    padding: 0,
    background: 'none',
    borderStyle: 'none',
    [theme.breakpoints.down('md')]: {
      gridColumn: '8',
      marginLeft: 'auto',
      display: (searchOpen) => `${searchOpen ? 'none' : ''}`,
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '5',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  searchBar: {
    height: theme.spacing.XXL,
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / -1',
      display: (searchOpen) => `${searchOpen ? '' : 'none'}`,
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: '9 / span 4',
      marginLeft: theme.spacing.XXXL - theme.spacing.L,
    },
  },
}))

export const StoriesCategoryNav: React.FC<{
  categories: Array<{
    path: string
    label: string
  }>
  className?: string
  onSearch: (searchQuery?: string) => void
  searchPlaceholder?: string
}> = ({ categories, className, onSearch, searchPlaceholder }) => {
  const [searchOpen, setSearchOpen] = useState(false)

  const classes = useStyles(searchOpen)
  const layoutClasses = useLayoutStyles()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const theme = useTheme<Theme>()

  useEffect(() => {
    if (searchOpen) {
      searchInputRef.current?.focus()
    }
  }, [searchOpen])

  const handleSearch = (query?: string) => {
    onSearch(query)
    if (!query) {
      setSearchOpen(false)
    }
  }

  const path_to_hide = '/news'

  return (
    <div className={classNames(layoutClasses.gridLayout, className)}>
      <Switch>
        <Route path={path_to_hide}>&nbsp;</Route>
        <Route>
          <nav className={classes.nav} aria-label="stories">
            <ul className={classes.navList}>
              {categories.map((category, i) => (
                <li>
                  <NavLink
                    exact
                    to={category.path}
                    className={classes.link}
                    activeClassName={classes.activeLink}
                    key={i}
                  >
                    {category.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </Route>
      </Switch>
      <SearchBar
        ref={searchInputRef}
        className={classes.searchBar}
        onSubmit={handleSearch}
        placeholder={searchPlaceholder}
      />
      <button
        className={classes.searchButton}
        onClick={() => setSearchOpen(true)}
      >
        <FeatherIcon name="search" color={theme.kitColors.grey900} />
      </button>
    </div>
  )
}
