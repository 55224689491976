import React, { Fragment, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'
import { useLayoutStyles } from './LayoutContainer'
import { Player } from '@lottiefiles/react-lottie-player'
import { BackButton } from './BackButton'

const useStyles = createUseStyles((theme: Theme) => ({
  container: ({ backgroundSrc }: Props) => ({
    backgroundImage: backgroundSrc ? `url(${backgroundSrc})` : undefined,
    backgroundSize: 'cover',
    backgroundColor: theme.colors.navy,
    minHeight: 480,
    position: 'relative',
  }),
  containerNoBG: {
    backgroundColor: theme.colors.navy,
    height: 480,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },
  content: {
    zIndex: theme.layers.mediaOverlay,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing.XXXL,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing.XXL,
    },
  },
  videoBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  heading: {
    ...theme.typography.h1,
    textTransform: 'uppercase',
  },
  headingLine: {
    display: 'inline-block',
    paddingLeft: 12,
    paddingRight: 12,
    '&:first-child > span': {
      paddingTop: theme.spacing.XS,
    },
    '&:last-child > span': {
      paddingBottom: theme.spacing.XS,
    },
  },
  headingWord: {
    display: 'inline-block',
    backgroundColor: theme.colors.orange,
    color: theme.kitColors.white,
    paddingRight: 10,
    paddingLeft: 10,
  },
  icon: {
    stroke: theme.kitColors.white,
    width: 40,
    height: 40,
    bottom: theme.spacing.L,
    right: theme.spacing.L,
    position: 'absolute',
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing.XXXL,
    marginBottom: theme.spacing.XXL,
    color: theme.kitColors.white,
    '& > svg': {
      stroke: theme.kitColors.white,
    },
  },
}))

interface Props {
  heading?: string | null
  backgroundType?: string | null
  backgroundSrc?: string | null
  className?: string
  showBackButton?: boolean
  // media?: {
  //   src?: string | null
  //   type?: string| null
  //   alt?: string | null
  //   srcSe
  // }
}

export const MediaHeader: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const layoutClasses = useLayoutStyles()

  const headingWordsByLine =
    props.heading?.split('\n').map((line) => line.split(' ')) ?? []

  const isVideoBg = props.backgroundType?.indexOf('video') === 0
  const isAnimation = props.backgroundType === 'application/json'

  const renderHeading = () => (
    <h1 className={classes.heading}>
      {headingWordsByLine.map((line, i, lines) => (
        <Fragment key={i}>
          <span className={classes.headingLine}>
            {line.map((word, i) => (
              <span key={i} className={classes.headingWord}>
                {word}
              </span>
            ))}
          </span>
          {i < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </h1>
  )

  const [videoActive, setVideoActive] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <div
      className={classNames(
        layoutClasses.layoutContainerOuter,
        props.backgroundSrc ? classes.container : classes.containerNoBG,
        props.className,
      )}
    >
      {isVideoBg && (
        <video
          ref={videoRef}
          className={classes.videoBackground}
          src={props.backgroundSrc ?? undefined}
          autoPlay
          muted
          loop
        />
      )}
      <div
        className={classNames(
          layoutClasses.layoutContainerInner,
          classes.content,
        )}
      >
        {props.showBackButton && <BackButton className={classes.backButton} />}
        {renderHeading()}
      </div>
      {isVideoBg && videoActive && (
        <button
          onClick={() => {
            videoRef.current?.pause()
            setVideoActive(false)
          }}
        >
          <FeatherIcon name="pause" className={classes.icon} />
        </button>
      )}
      {isVideoBg && !videoActive && (
        <button
          onClick={() => {
            videoRef.current?.play()
            setVideoActive(true)
          }}
        >
          <FeatherIcon name="play" className={classes.icon} />
        </button>
      )}
      {isAnimation && props.backgroundSrc && (
        <Player
          autoplay
          loop
          src={props.backgroundSrc}
          style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
        />
      )}
    </div>
  )
}
