import React from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { FeatherIcon } from './icons/FeatherIcon'
import { Theme } from '../theme'
import { classNames } from '../utils'
const useStyles = createUseStyles((theme: Theme) => ({
  backButton: {
    ...theme.typography.menuLink,
    ...theme.traits.clickable,
    padding: 0,
    borderStyle: 'none',
    background: 'none',
    display: 'flex',
    height: 40,
    alignItems: 'center',
    color: theme.colors.blue,
  },
  icon: {
    stroke: theme.colors.blue,
    marginRight: theme.spacing.S,
  },
}))

export const BackButton: React.FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleBack = () => history.goBack()

  return (
    <button
      className={classNames(classes.backButton, className)}
      onClick={handleBack}
    >
      <FeatherIcon name="arrow-left" className={classes.icon} /> Back
    </button>
  )
}
