import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils'
import { FeatherIcon } from './icons/FeatherIcon'
import { PlaceholderImage } from './placeholders/PlaceholderImage'
import { Player } from '@lottiefiles/react-lottie-player'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    paddingTop: ({ aspectRatio }: Props) =>
      aspectRatio !== undefined ? `${(1 / aspectRatio) * 100}%` : undefined,
  },
  content: ({ aspectRatio }: Props) => {
    if (aspectRatio !== undefined) {
      return {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }
    }

    return {
      width: '100%',
      height: '100%',
    }
  },
  video: {},
  image: {
    objectFit: 'contain',
  },
  playButton: {
    ...theme.traits.clickable,
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
    background: 'none',
    borderStyle: 'none',
    padding: 0,
    zIndex: 1,
  },
  credit: {
    ...theme.typography.boldCaption,
    fontWeight: 'normal',
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '4px 12px',
    color: theme.kitColors.white,
    backgroundColor: theme.colors.navy,
  },
}))

type Props = {
  src?: string | null
  srcSet?: string | null
  alt?: string | null
  type?: string | null
  credit?: string | null
  className?: string
  aspectRatio?: number
}

export const Media: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const [videoActive, setVideoActive] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (videoActive) {
      videoRef.current?.play()
    }
  }, [videoActive])

  if (!props.src || !props.type) {
    return <PlaceholderImage />
  }

  const isVideo = props.type?.indexOf('video') === 0
  const isAnimation = props.type === 'application/json'

  return (
    <div className={classNames(classes.container, props.className)}>
      {isVideo ? (
        <>
          <video
            ref={videoRef}
            className={classNames(classes.content, classes.video)}
            src={props.src}
            controls={videoActive}
          />
          {!videoActive && (
            <button
              className={classes.playButton}
              onClick={() => setVideoActive(true)}
            >
              <FeatherIcon name="play" color={'#ffffff'} size={48} />
            </button>
          )}
        </>
      ) : isAnimation ? (
        <Player autoplay loop src={props.src} className={classes.content} />
      ) : (
        <>
          <img
            className={classNames(classes.content, classes.image)}
            src={props.src}
            srcSet={props.srcSet ?? undefined}
            alt={props.alt ?? 'media'}
          />
          {props.credit && (
            <span className={classes.credit}>Image Credit: {props.credit}</span>
          )}
        </>
      )}
    </div>
  )
}
