const innerPage = (route: string) => `${route}/:slug`
export const home = '/'
export const brands = '/brands'
export const brandsDirectory = `${brands}/directory`
export const company = '/company'
export const companyAdvocacy = `${company}/advocacy`
export const companyAdvocacyInner = innerPage(companyAdvocacy)
export const companySustainability = `${company}/sustainability`
export const companySustainabilityInner = innerPage(companySustainability)
export const companyDiversityInclusion = `${company}/diversity-inclusion`
export const companyDiversityInclusionInner = innerPage(
  companyDiversityInclusion,
)
export const companyCulture = `${company}/culture`
export const companyCultureInner = innerPage(companyCulture)

/* TEMPORARILY DISABLED (UofQ)
export const uOfQ = '/u-of-q'
export const uOfQSearch = `${uOfQ}/search`
export const uOfQCourseDetail = `${uOfQ}/course/:courseTitle`
export const uOfQInstitute = `${uOfQ}/institute`
export const uOfQInstituteCourses = `${uOfQInstitute}/courses`
export const uOfQInstituteSchedule = `${uOfQInstitute}/schedule`
export const uOfQInstituteAbout = `${uOfQInstitute}/about`
export const uOfQInstituteAboutMission = `${uOfQInstituteAbout}/mission`
export const uOfQInstituteAboutInstructors = `${uOfQInstituteAbout}/instructors`
export const uOfQInstituteLocations = `${uOfQInstitute}/locations`
export const uOfQInstituteContact = `${uOfQInstitute}/contact`
export const uOfQTrainingLibraryHowItWorks = `${uOfQ}/how-it-works`
export const uOfQTrainingLibrary = `${uOfQ}/browse`
export const uOfQEvents = `${uOfQ}/events`
*/

export const stories = '/stories'
export const storyDetail = `${stories}/:slug`
export const storyCategory = `${stories}/category/:categorySlug`
export const news = '/news'
export const newsDetail = `${news}/:slug`
export const careers = '/careers'
export const doingBusinessWithQ = '/doing-business-with-q'
export const contact = '/contact'
export const bCorp = '/b-corp'
export const privacyPolicy = '/privacy-policy'
export const scholarships = '/scholarships/:slug?'
export const communityGrant = '/community-grant'
export const communityGrantFAQ = `${communityGrant}-faq`
export const communityGrantRecipient = `${communityGrant}/:slug`

export const RouteLabels = {
  [home]: 'Home',

  [brands]: 'Brands',
  [brandsDirectory]: 'Brands Directory',

  [company]: 'Company',
  [companyAdvocacy]: 'Advocacy',
  [companySustainability]: 'Sustainability',
  [companyDiversityInclusion]: 'Diversity & Inclusion',
  [companyCulture]: 'Culture',

  /* TEMPORARILY REMOVED (UofQ)
   [uOfQ]: 'U of Q',
   [uOfQSearch]: 'Search Results',
   [uOfQCourseDetail]: 'Course Detail',
   [uOfQInstitute]: 'Institute',
   [uOfQInstituteCourses]: 'Course Catalog',
   [uOfQInstituteSchedule]: 'Schedule',
   [uOfQInstituteAboutMission]: 'About Us',
   [uOfQInstituteAboutInstructors]: 'Instructors',
   [uOfQInstituteLocations]: 'Locations',
   [uOfQInstituteContact]: 'Contact',
   [uOfQTrainingLibraryHowItWorks]: 'How it Works',
   [uOfQTrainingLibrary]: 'Schools',
   [uOfQEvents]: 'Events',
  */

  [stories]: 'Stories',
  [storyDetail]: 'Story Details',
  [storyCategory]: 'Story Category',

  [news]: 'News',
  [newsDetail]: 'News Details',

  [careers]: 'Careers',
  [doingBusinessWithQ]: 'Doing Business With Us',
  [contact]: 'Contact Us',
  [bCorp]: 'B-Corp',
  [privacyPolicy]: 'Privacy Policy',

  [scholarships]: 'Scholarships',
  [communityGrant]: 'Community Grant',
  [communityGrantRecipient]: 'Community Grant Recipients',
}
